import React from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import LoginForm from './utils/login.js'

import CH2_1NN_Artitecture from './pages/ch2/ch2-2_nn_architecture.js'
import CH2_1LossFunction from './pages/ch2/ch2-1_loss_function.js'
import CH2_3NeuralNetworkFormula from './pages/ch2/ch2-3_neural_network_formula.js'

import Ch3_1ActivationFunction from './pages/ch3/ch3-1_activationfunction.js'
import CH3_2MomentumSection from './pages/ch3/ch3-2_optimizer_momentum.js'
import CH3_3RMSpropSection from './pages/ch3/ch3-3_optimizer_RMSprop.js'
import CH3_4GradientDescentSection from './pages/ch3/ch3-4_optimizer_gradient_desecent.js'

import CH4_1ConvolPracticeSection from './pages/ch4/ch4-1_convolution_practice.js'

import CH4_2PoolingSection from './pages/ch4/ch4-2_cnn_pooling.js'
import CH4_3FlattenSection from './pages/ch4/ch4-3_flatten.js'

import GenerateTensorflowCode from './pages/gen_code/generate_tensorflow_code.js'

// import PoolingPractice1 from './pooling_practice1'
// import PoolingPractice2 from './pooling_practice2'

/////////* Header Section */////////
function Home () {
  return <div>Home Page</div>
}


function WebsiteNavBar (props) {
  return (
    props.isLoggedIn ? (
      <header>
        {/* use a image as a logo */}
        <div className="logo">
          <img src={"Logo.jpg"} alt="logo" />
        </div>
        <div className="menuToggle">
          <nav>
            <ul className='Links'>
              <li>
                <Link to="/ch2-1_loss_function">Ch2.初探神經網路</Link>
                <ul>
                  <li><Link to="/ch2-1_loss_function">2-1 損失函數</Link></li>
                  <li><Link to="/ch2-2_nn_architecture">2-2 神經網路應用</Link></li>
                  <li><Link to="/ch2-3_neural_network_formula">2-3 基礎練習</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/ch3-1_activationfunction" >Ch3.神經網路訓練指南</Link>
                <ul>
                  <li><Link to="/ch3-1_activationfunction">3-1 激活函數</Link></li>
                  <li><Link to="/ch3-2_optimizer_momentum">3-2 Momentum</Link></li>
                  <li><Link to="/ch3-3_optimizer_RMSprop">3-3 RMSprop</Link></li>
                  <li><Link to="/ch3-4_optimizer_gradient_desecent">3-4 優化器練習</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/ch4-1_convolution_practice">Ch.4卷積神經網路</Link>
                <ul>
                  <li><Link to="/ch4-1_convolution_practice">4-1卷積</Link></li>
                  <li><Link to="/ch4-2_cnn_pooling">4-2池化</Link></li>
                  <li><Link to="/ch4-3_flatten">4-3Flatten</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/gen_code">生成程式碼</Link>
              </li>
              <li ><div><Link to="/login" className='sign-up-button' onClick={props.handleSignOut}>Sign out</Link></div></li>
            </ul>
          </nav>
        </div>
      </header>
    ) : (
      <header>
        <div className="logo">
          <img src={"Logo.jpg"} alt="logo" />
        </div>
        <div className="menuToggle">
          <nav>
            <ul className='Links'>
              <li ><div><Link to="/login" className='sign-up-button'>Sign In</Link></div></li>
            </ul>
          </nav>
        </div>
      </header>
    )
  )
}


// class WebsiteHeader extends React.Component {
//   render () {
//     return (
//       <header>
//         <div className="navbar">
//           <div className="logo">
//             <Link to="/">Neural Network</Link>
//           </div>
//           <ul className="links">
//             <li><Link to="/">Home</Link></li>
//             {/* <li><Link to="/ch2-1_activationfunction">Neural Network</Link></li> */}
//             <li><Link to="/convolution-ch2-1_activationfunction">Convolution Neural Network</Link></li>
//             <li><Link to="/ch2_optimizer_gradient_desecent">Gradient Descent</Link></li>
//             <li><Link to="/pooling">Pooling</Link></li>
//             <li><Link to="/flatten">Flatten</Link></li>
//           </ul>
//           <a href="#" className="action_btn">Get Started</a>
//           <div className="toggle_btn">
//             <i className="fa-solid fa-bars"></i>
//           </div>
//         </div>
//         <div className="dropdown_menu">
//           <ul>
//             <li><Link to="/">Home</Link></li>
//             {/* <li><Link to="/ch2-1_activationfunction">Neural Network</Link></li> */}
//             <li><Link to="/ch4-1_convolution_practice">Convolution Neural Network</Link></li>
//             <li><Link to="/ch2_optimizer_gradient_desecent">Gradient Descent</Link></li>
//             <li><Link to="/pooling">Pooling</Link></li>
//             <li><a href="#" className="action_btn">Get Started</a></li>
//           </ul>
//         </div>
//       </header>
//     )
//   }
// }



function App () {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)

  React.useEffect(() => {

    const userUUID = localStorage.getItem('userUUID')
    if (userUUID) {
      setIsLoggedIn(true)
    }
  }, [])

  const handleSignOut = () => {
    // 删除 localStorage 中的用户 UUID
    localStorage.removeItem('userUUID')
    setIsLoggedIn(false) // 设置登录状态为 false
  }

  return (
    <Router>
      <div className="App">
        <WebsiteNavBar
          isLoggedIn={isLoggedIn}
          handleSignOut={handleSignOut}
        // setIsLoggedIn={setIsLoggedIn}
        ></WebsiteNavBar>
        <main>
          <Routes>

            <Route path="/" element={
              <LoginForm
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />} />
            <Route path="/login" element={
              <LoginForm
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />} />

            <Route path="/ch2-1_loss_function" element={<CH2_1LossFunction />} />
            <Route path="/ch2-2_nn_architecture" element={<CH2_1NN_Artitecture />} />
            <Route path="/ch2-3_neural_network_formula" element={<CH2_3NeuralNetworkFormula />} />

            <Route path="/ch3-1_activationfunction" element={<Ch3_1ActivationFunction />} />
            <Route path="/ch3-2_optimizer_momentum" element={<CH3_2MomentumSection />} />
            <Route path="/ch3-3_optimizer_RMSprop" element={<CH3_3RMSpropSection />} />
            <Route path="/ch3-4_optimizer_gradient_desecent" element={<CH3_4GradientDescentSection />} />

            {/* <Route path="/ch2-1_activationfunction" element={<Ch3_1ActivationFunction />} /> */}
            <Route path="/ch4-1_convolution_practice" element={<CH4_1ConvolPracticeSection />} />

            <Route path="/ch4-2_cnn_pooling" element={<CH4_2PoolingSection />} />
            <Route path="/ch4-3_flatten" element={<CH4_3FlattenSection />} />

            <Route path="/gen_code" element={<GenerateTensorflowCode />} />

            <Route path="/login" element={<LoginForm />} />
          </Routes>
        </main>
      </div>
    </Router>
  )
}

export default App
