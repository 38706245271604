// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ch1-1-control-panel-section{

}


/* Loss Table */
.ch1-1-predict-input,
.ch1-1-loss-input,
.loss-table-title,
.loss-table-known{
  width: 80px;
  display: flex;
  justify-content: center;
  
}

.valid-input{
  border: 2px solid green;
}

.valid-input:focus{
  outline: none;
}

.invalid-input{
  border: 2px solid red;
}

.invalid-input:focus{
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ch2/loss_function.css"],"names":[],"mappings":"AAAA;;AAEA;;;AAGA,eAAe;AACf;;;;EAIE,WAAW;EACX,aAAa;EACb,uBAAuB;;AAEzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".ch1-1-control-panel-section{\n\n}\n\n\n/* Loss Table */\n.ch1-1-predict-input,\n.ch1-1-loss-input,\n.loss-table-title,\n.loss-table-known{\n  width: 80px;\n  display: flex;\n  justify-content: center;\n  \n}\n\n.valid-input{\n  border: 2px solid green;\n}\n\n.valid-input:focus{\n  outline: none;\n}\n\n.invalid-input{\n  border: 2px solid red;\n}\n\n.invalid-input:focus{\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
