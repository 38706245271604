import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import * as d3 from 'd3';
import { index } from 'd3'
import App from './App'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)



