// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IOcombinationSection{
  min-height: 200px;
  margin: 10px 2rem;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(128, 128, 128, 0.253);
}

.IOcontainer{
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
}

.IOInput,
.IoOutput{
  border: 1px solid black;
  border-radius: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/ch2/nn_architecture.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,eAAe;EACf,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;;EAEE,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".IOcombinationSection{\n  min-height: 200px;\n  margin: 10px 2rem;\n  display: flex;\n  flex-wrap: wrap;\n  background-color: rgba(128, 128, 128, 0.253);\n}\n\n.IOcontainer{\n  display: flex;\n  flex-wrap: wrap;\n  background-color: rgb(255, 255, 255);\n  border: 1px solid black;\n}\n\n.IOInput,\n.IoOutput{\n  border: 1px solid black;\n  border-radius: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
