import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import './nn_architecture.css'
import * as d3 from 'd3'
import { index } from 'd3'
import { recordAPIAction, SubmitAnswerAPI, recordAPI } from '../../utils/recordSubmitAPI'



function generatePythonCode (width, depth) {
  if (width < 1 || depth < 1) {
    return "Invalid input. Width and depth must be greater than or equal to 1."
  }

  let pythonCode = "import tensorflow as tf\n"
  pythonCode += "import numpy as np\n\n"

  pythonCode += "# Load Data...\n\n\n"

  pythonCode += "x_data = ...\n"
  pythonCode += "y_data = ...\n"
  pythonCode += "# Convert data to NumPy arrays\n"
  pythonCode += "x_data = np.array(x_data).astype(float)\n"
  pythonCode += "y_data = np.array(y_data).astype(float)\n\n"

  pythonCode += "epochs = ...\n"
  pythonCode += "model = tf.keras.Sequential([\n"
  pythonCode += "  tf.keras.layers.Input(shape=(1,)),\n"

  for (let i = 0; i < depth; i++) {
    pythonCode += `  tf.keras.layers.Dense(${width}, activation='relu'),\n`
  }

  pythonCode += "  tf.keras.layers.Dense(1)\n"
  pythonCode += "])\n\n"
  pythonCode += "model.compile(optimizer='adam', loss='mean_squared_error')\n\n"

  pythonCode += "model.fit(x=x_data, y=y_data, epochs=epochs)\n"

  return pythonCode
}

function getInputOutput () {

}


function ConditionControlPanel (props) {
  const [networkStructure, setNetworkStruture] = useState([
    {
      "AF": 1,
      "depth": 1,
      "width": 1,
      "epochs": 2
    }
  ])
  const [task_functionType, set_task_functionType] = useState([
    {
      "taskType": "regression",
      "functionType": 0
    }
  ])

  const optionContainerGenerator = (fieldName, optionList, networkOrTask, elementName) => {
    const handleSelectChange = (event) => {
      const index = event.target.value

      if (networkOrTask === "TF") {
        const newTaskFunctionType = [...task_functionType]
        newTaskFunctionType[0][elementName] = parseInt(index)
        // console.log(newTaskFunctionType);
        set_task_functionType(newTaskFunctionType)
        props.get_task_functionType(newTaskFunctionType)
      } else if (networkOrTask === "NS") {
        const newNetworkStructure = [...networkStructure]
        newNetworkStructure[0][elementName] = parseInt(index)
        // console.log(newNetworkStructure);
        setNetworkStruture(newNetworkStructure)
        props.getNetworkStruture(newNetworkStructure)
      }
    }

    return (
      <div className="option-container">
        <div className='field-name'>{fieldName}</div>
        <div className='select-option-container'>
          <select onChange={handleSelectChange} defaultValue={1} className='condition-selet-box'>
            {optionList.map((option, index) => (
              <option value={index} key={index}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }

  const handleExportCodeClick = () => {
    // 在这里生成 Python 代码，使用当前的 networkStructure 和 task_functionType

    const pythonCode = generatePythonCode(
      props.layers[1],
      props.layers.length - 2,
      networkStructure[0].epochs,
      task_functionType[0].functionType
    )

    // 创建 Python 文件 Blob 对象
    const blob = new Blob([pythonCode], { type: 'text/plain' })

    // 创建一个下载链接
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'generated_model.py'
    a.click()
    URL.revokeObjectURL(url)
  }
  return (
    <>
      <div className="condition-control-panel-container">
        {optionContainerGenerator("函式類型", ["線性", "非線性"], "TF", "functionType")}
        {optionContainerGenerator("激活函數", ["linear", "relu", "sigmoid"], "NS", "AF")}
        {optionContainerGenerator("隱藏層深度", ["1", "2", "3", "4"], "NS", "depth")}
        {optionContainerGenerator("隱藏層寬度", ["1", "2", "3", "4"], "NS", "width")}
        {optionContainerGenerator("Epochs", ["1", "2", "3", "5", "10", "50", "100", "500"], "NS", "epochs")}
        <button onClick={handleExportCodeClick}>匯出程式碼</button>
      </div>

    </>



  )
}


function NetworkControlPanel (props) {


  const [layers, setLayers] = useState([
    {
      id: uuid(),
      layerID: 1,
      numberOfNeural: 4,
      inputDisable: [false, false, false, false, true, true, true, true, true, true],
      weightList: [
        [-0.59799993, -0.37269622, 0.46365443, 0.08975877],
        [-0.4373828, 0.2784637, 0.10311716, 0.10455135],
        [0.7490775, -0.04052863, 0.33612952, -0.35342735],
        [1.5339521, -2.0837278, 0.7424698, 1.9593663],
        null, null, null, null, null, null],
      biasList: ["體重", "身高", "年齡", "性別", null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: true
    },
    {
      id: uuid(),
      layerID: 2,
      numberOfNeural: 4,
      inputDisable: [false, false, false, false, true, true, true, true, true, true],
      weightList: [
        [-0.59799993, -0.37269622, 0.46365443, 0.08975877],
        [-0.4373828, 0.2784637, 0.10311716, 0.10455135],
        [0.7490775, -0.04052863, 0.33612952, -0.35342735],
        [1.5339521, -2.0837278, 0.7424698, 1.9593663],
        null, null, null, null, null, null],
      biasList: ["體重", "身高", "年齡", "性別", null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 3,
      numberOfNeural: 4,
      inputDisable: [false, false, false, false, true, true, true, true, true, true],
      weightList: [
        [-0.59799993, -0.37269622, 0.46365443, 0.08975877],
        [-0.4373828, 0.2784637, 0.10311716, 0.10455135],
        [0.7490775, -0.04052863, 0.33612952, -0.35342735],
        [1.5339521, -2.0837278, 0.7424698, 1.9593663],
        null, null, null, null, null, null],
      biasList: [170.7275409, 70.3493278, 60, 0, null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 4,
      numberOfNeural: 4,
      inputDisable: [false, false, false, false, true, true, true, true, true, true],
      weightList: [
        [-0.59799993, -0.37269622, 0.46365443, 0.08975877],
        [-0.4373828, 0.2784637, 0.10311716, 0.10455135],
        [0.7490775, -0.04052863, 0.33612952, -0.35342735],
        [1.5339521, -2.0837278, 0.7424698, 1.9593663],
        null, null, null, null, null, null],
      biasList: [170.7275409, 70.3493278, 60, 0, null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 5,
      numberOfNeural: 4,
      inputDisable: [false, false, false, false, true, true, true, true, true, true],
      weightList: [
        [-0.96086866],
        [1.3353832],
        [-0.3317529],
        [-3.2085323],
        null, null, null, null, null, null, null],
      biasList: [-7.898557, 8.012515, -8.217629, -8.022755, null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 6,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [["體脂率"], null, null, null, null, null, null, null, null, null],
      biasList: [7.9377675, null, null, null, null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: true
    }
  ])

  const appendInputOutput2List = () => {

    if (props.IOList.length < 25) {
      //在Layers中找到第一層的biasList和最後一層的weightList(即輸入和輸出的值)
      let inputList = layers[0].biasList.filter(value => value !== null)


      let outputList = layers[layers.length - 1].weightList
        .filter(value => value !== null)
        .map(value => value[0])

      recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "2-2", "neural_network_appication", "ch2-2_append_IOList", [inputList, outputList], Date(), localStorage.getItem('userUUID'))

      // console.log("inputList", inputList)
      // console.log("outputList", outputList)

      // 更新 IOList 狀態
      props.setIOList(prevIOList => [
        ...prevIOList,
        {
          "input": inputList,
          "output": outputList
        }
      ])
    }


  }









  useEffect(() => {


    props.updateLayers(layers.map(layer => (layer.numberOfNeural)))


  }, [layers, props.networkStructure])



  const generateLayer = (item, prevId) => {

    const handleWeightInputChange = (id, neuralID, weightID, event) => {
      let newValue = event.target.value

      // if (newValue !== '' && (newValue < -10)) {
      //   newValue = -10;
      // }
      // else if (newValue > 10){
      //   newValue = 10
      // }

      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {

        extractedLayer.weightList = extractedLayer.weightList.map((nerual, index) => {

          if (index === neuralID) {
            return nerual.map((weight, wIndex) => wIndex === weightID ? newValue : weight)
          }
          else {
            return nerual
          }
        })

      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))


      setLayers(updatedLayers)

    }

    const handleBiasInputChange = (id, BiasID, event) => {
      let newValue = (event.target.value)

      // if (newValue !== '' && (newValue < -10)) {
      //   newValue = -10
      // }
      // else if (newValue > 10) {
      //   newValue = 10
      // }


      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {
        extractedLayer.biasList = extractedLayer.biasList.map((bias, index) =>
          index === BiasID ? newValue : bias)
      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))
      // updateFormula(updatedLayers)
      setLayers(updatedLayers)

    }




    // 生成每一個神經元所連接的權重和偏值
    const renderParaInputs = (item, prevId, layerID) => {
      const Inputs = []
      let nerualWeights = []
      let weightsContainer = []
      const currNumberOfNeural = parseInt(layers[layerID].numberOfNeural, 10)

      const currExtractedLayer = layers.find(layer => layer.id === item.id)
      const prevExtractedLayer = layers.find(layer => layer.id === prevId)

      // 處理點擊神經元時的事件(顯示公式)
      const handleNeuralOnClick = (neuralID, layerID) => {


        if (layerID === 0) {
          const clickedElement = document.querySelector(`.layer${layerID + 1}N.n_id${layerID + 1}${neuralID}`)
          clickedElement.classList.add('onclick-prev-neural')

          props.setSingleNeuralFormula(`<div class="layer${layerID + 1}N">N<sub>${layerID + 1}</sub><sub>${neuralID}</sub>= </div> <div>input1</div> `)

        }
        else {

          const prevLayerID = layerID - 1
          const prevNumberOfNeural = parseInt(layers[prevLayerID].numberOfNeural, 10)
          let onclickWeights = []
          let singleNeuralFormula = `<div class="layer${layerID + 1}N">N<sub>${layerID + 1}</sub><sub>${neuralID}</sub>= </div>  `

          for (let i = 0; i < prevNumberOfNeural - 1; i++) {
            singleNeuralFormula += `<div class="layer${layerID}N">N<sub>${prevLayerID + 1}${i + 1}</sub> * W<sub>${prevLayerID + 1}${i + 1}${neuralID}</sub> + <br></div>`
            onclickWeights.push(document.querySelector(`.weight${prevLayerID + 1}${i + 1}${neuralID}`))
          }

          singleNeuralFormula += `<div class="layer${layerID}N">N<sub>${prevLayerID + 1}${prevNumberOfNeural}</sub> * W<sub>${prevLayerID + 1}${prevNumberOfNeural}${neuralID}</sub> + <br>
                                </div><div class="layer${layerID + 1}N">B<sub>${prevLayerID + 2}${neuralID}</sub></div>`
          onclickWeights.push(document.querySelector(`.weight${prevLayerID + 1}${prevNumberOfNeural}${neuralID}`))



          const elements = document.querySelectorAll('.onclick-prev-neural')
          // Loop through all elements with the class "onclick-prev-neural"
          elements.forEach(element => {
            // Remove the class "onclick-prev-neural" from each element
            element.classList.remove('onclick-prev-neural')
          })

          // Add the class "onclick-prev-neural" to all elements with class `layer${prevLayerID + 1}N`
          const targetElements = document.querySelectorAll(`.layer${prevLayerID + 1}N`)
          const targetBias = document.querySelectorAll(`.layer${prevLayerID + 1}${neuralID}B`)

          targetBias.forEach(element => {
            element.classList.add('onclick-prev-neural')
          })
          targetElements.forEach(element => {
            element.classList.add('onclick-prev-neural')
          })


          onclickWeights.forEach(element => {
            element.classList.add('onclick-prev-neural')
          })


          const clickedElement = document.querySelector(`.layer${layerID + 1}N.n_id${layerID + 1}${neuralID}`)
          clickedElement.classList.add('onclick-prev-neural')



          props.setSingleNeuralFormula(singleNeuralFormula)
        }

      }


      for (let i = 1; i <= currNumberOfNeural; i++) {
        if (item.weightList[i - 1] != null) {
          if (item.layerID != 1) {
            nerualWeights.push(
              <>
                <div className={`text layer${layerID}${i}B`}>--B<sub>{layerID + 1}{i}</sub>--</div>
                {/* < input
                className='ch1-1-bias-input'
                type='number'
                min='-10'
                max='10'
                step='0.05'
                placeholder={`bias${i}`}
                value={currExtractedLayer.biasList[i - 1]}
                onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                disabled={false}
              />  */}
              </>
            )
          }
          else {
            nerualWeights.push(
              <>
                <div className={`text layer${layerID}${i}B`}>Input<sub>{i}</sub></div>
                < input
                  className='ch1-1-network-input'
                  type='text'
                  value={currExtractedLayer.biasList[i - 1]}
                  onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                  placeholder={`輸入${i}`}
                  disabled={false}
                />
              </>
            )
          }

          nerualWeights.push(
            <div
              className={`neural layer${layerID + 1}N n_id${layerID + 1}${i}`}
              onClick={() => handleNeuralOnClick(i, layerID)}
            >
              N
              <sub>
                {layerID + 1}{i}
              </sub>
            </div>
          )
          for (let index = 1; index <= item.weightList[i - 1].length; index++) {
            weightsContainer.push(
              <div className='weight-container'>
                {/* 是否是最後那一層 */}
                {/* {item.inOutputLayer && item.layerID > 1 ?
                <div className={`weight${layerID + 1}${i}${index}`}>--Output<sub>{layerID + 1}{i}{index}</sub>--</div> :
                <div className={`weight${layerID + 1}${i}${index}`}>--W<sub>{layerID + 1}{i}{index}</sub>--</div>
              } */}

                {
                  item.inOutputLayer && item.layerID > 1 ?
                    <>
                      <div className={`weight${layerID + 1}${i}${index}`}>--Output<sub>{i}</sub>--</div>
                      <input
                        className='ch1-1-weight-input'
                        type='text'
                        placeholder={`輸出${i}`}
                        value={item.weightList[i - 1][index - 1]}

                        // value={item.inOutputLayer && item.layerID > 1 ? props.formulaOutput : 
                        //   item.inputDisable[i - 1] ? `${i}` : item.weightList[i - 1][index - 1]}
                        onChange={(event) => handleWeightInputChange(item.id, i - 1, index - 1, event)}
                        disabled={item.inputDisable[i - 1]}
                      />
                    </>
                    :
                    <div className={`weight${layerID + 1}${i}${index}`}>--W<sub>{layerID + 1}{i}{index}</sub>--</div>

                }

              </div>
            )
          }
        }

        Inputs.push(
          <div className='ch1-1-control-input-container'>
            {nerualWeights}
            {
              <div className='weights-container'>
                {weightsContainer}
              </div>
            }
          </div>

        )
        nerualWeights = []
        weightsContainer = []
      }
      return Inputs
    }

    // 增加某層的神經元數量
    const handleNumberOfNeuralInputChange = (id, prevId, event) => {

      //因為要更新整個layer，所以先把所有的onclick-prev-neural class移除
      const elements = document.querySelectorAll('.onclick-prev-neural')

      elements.forEach(element => {
        // Remove the class "onclick-prev-neural" from each element
        element.classList.remove('onclick-prev-neural')
      })

      //同時把singleNeuralFormula清空
      props.setSingleNeuralFormula("")

      //檢查輸入值是否超過範圍(10以內)
      let newValue = event.target.value
      if (newValue !== '' && (newValue < 1)) {
        newValue = 1
      }
      else if (newValue > 10) {
        newValue = 10
      }

      const prevExtractedLayer = layers.find(layer => layer.id === prevId)
      const extractedLayer = layers.find(layer => layer.id === id)
      const nextLayerIndex = prevId ? prevExtractedLayer.layerID + 1 : 1

      // 對上一層每一個neural增加一個weight
      if (prevExtractedLayer) {
        prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
          if (weights !== null) {
            if (newValue < extractedLayer.numberOfNeural) {
              return weights.slice(0, -1)
            } else {
              return [...weights, 1]
            }
          } else {
            return weights
          }
        })
      }

      // 對該層新增一個weight
      if (extractedLayer) {
        extractedLayer.numberOfNeural = newValue
        extractedLayer.weightList = extractedLayer.weightList.map((weight, index) =>
          // 如果該層的神經元數目小於指定的數目則設定為null
          index >= newValue ? null :
            weight === null ?
              Array.from({// 如果原來的weight沒有值則新增
                // 第一層的weight數量為下一層的neural數量，而最後一層的ouput數量為1
                length: item.layerID === 1 ?
                  layers[nextLayerIndex].numberOfNeural : 1
              }, () => "")
              : weight)// 如果原來的weight有值則不變

        // 對該層新增一個bias
        extractedLayer.biasList = extractedLayer.biasList.map((bias, index) => index >= newValue ? null :
          index < newValue && bias === null ? 1 : bias
        )
        extractedLayer.inputDisable = Array(10).fill(true).map((value, index) => index > newValue - 1)
      }

      // 更新整個layer
      const updatedLayers = layers.map((layer, index) => {
        if (layer.id === id) {
          return extractedLayer
        } else if (index > 0 && layers[index - 1].id === prevId) {
          return prevExtractedLayer
        } else {
          return layer
        }
      })
      setLayers(updatedLayers)

    }

    return (
      <div key={item.id} className='ch1-1-layer-container'>
        <div className='layer-input-container'>
          <div>第 {item.layerID} 層: </div>
          <input
            type='number'
            className='weight-input-box'
            id={`layerInput${item.layerID}`}
            min='1'
            max='8'
            placeholder='number of neural'
            value={item.numberOfNeural}
            onChange={(event) => handleNumberOfNeuralInputChange(item.id, prevId, event)}
            disabled={item.inOutputLayer === true ? false : true}
          />


        </div>
        <div className='ch1-1-weights-bias-container'>
          {renderParaInputs(item, prevId, item.layerID - 1)}
        </div>


      </div>
    )
  }


  let previousItem = null
  return (

    <>
      {
        <>
          {/* <div>

            <input
              className='input'
              type='number'
              min='-10'
              max='10'
              step='0.05'
              placeholder={`input`}
              value="0"
            />
          </div> */}

          {layers.map((item, index, array) => (
            generateLayer(item, index > 0 ? array[index - 1].id : null)
          ))}

        </>
      }

      <button onClick={() => appendInputOutput2List()}>
        提交
      </button>
    </>
  )
}








function CH2_1NN_Artitecture () {
  const [formula, setFormula] = useState("Math.max(0, x + 1)")
  const [formulaOutput, setFormulaOutput] = useState(5)
  const [singleNeuralFormula, setSingleNeuralFormula] = useState("")
  const [IOList, setIOList] = useState([])
  const [layers, setLayers] = useState([1, 2, 2, 1])

  const [networkStructure, setNetworkStruture] = useState([
    {
      "AF": 1,
      "depth": 1,
      "width": 1,
      "epochs": 2
    }
  ])
  const [task_functionType, set_task_functionType] = useState([
    {
      "taskType": 0,
      "functionType": 0
    }
  ])


  const updateFormula = (f) => {
    setFormula(f)
  }

  const updateLayers = (f) => {

    setLayers(f)
  }

  const deleteIoById = (index) => {
    const newIOList = [...IOList]
    newIOList.splice(index, 1)
    setIOList(newIOList)

  }


  return (
    <>
      <div>
        <div className='title-container'>
          <div className='title'>神經網路應用</div>
          <div className='subtitle'>Build Neural Network</div>
        </div>
        <div className="bullet-point"> • 模擬練習</div>
        <div className='simulation-description'>小海狸目前正在思考神經網路還可以用在什麼地方，例如以預測體脂率為範例，他需要4組的輸入特徵，以及對應的1筆的輸出。(輸入:體重、身高、年齡、性別，輸出:預測的體脂率。)現在試著幫小海狸想盡量多的應用方法吧，請在下方輸入和輸出欄位中輸入你的答案，並提交。
          (※請注意，輸入或輸出的特徵都可以多於一個，)</div>
        <div className='ch1-1-c-container'>
          <div className="ch1-1-control-panel-section">
            <NetworkControlPanel
              networkStructure={networkStructure}
              task_functionType={task_functionType}
              updateFormula={updateFormula}
              updateLayers={updateLayers}
              setSingleNeuralFormula={setSingleNeuralFormula}
              formulaOutput={formulaOutput}
              setFormulaOutput={setFormulaOutput}
              IOList={IOList}
              setIOList={setIOList}
            ></NetworkControlPanel>
          </div>
          <div className="bullet-point"> • 上傳答案</div>
          <div className="IOcombinationSection d-flex">
            {
              IOList.map((item, index) => (
                <div className='IOcombination'>
                  <div className='IOcontainer d-flex  m-3'>
                    <div>
                      <div className='IOlabel m-2'>輸入</div>
                      <div className='IOinputs m-2'>
                        {
                          item.input.map((input, index) => (
                            <div className='IOInput' key={uuid()}>{input}</div>
                          ))
                        }
                      </div>
                    </div>
                    <div>

                      <div className='IOlabel m-2'>輸出</div>
                      <div className='IoOuputs m-2'>
                        {
                          item.output.map((output, index) => (
                            <div className='IoOutput' key={uuid()}>{output}</div>
                          ))
                        }

                      </div>
                    </div>
                    <button onClick={() => deleteIoById(index)}>移除答案</button>

                  </div>


                </div>

              ))
            }
            <button onClick={() => {
              if (window.confirm("確定要提交答案嗎？")) {
                SubmitAnswerAPI(
                  "http://localhost:8080/resource/api/v1/insertAnswer",
                  "2-2",
                  "neural_network_appication",
                  1,
                  IOList,
                  Date(),
                  localStorage.getItem('userUUID'),
                  () => {
                    alert("提交成功！")
                  }
                )
              }
            }}>
              提交答案
            </button>


          </div>
        </div>
        <div>

        </div>

      </div>
    </>

  )
}

export default CH2_1NN_Artitecture