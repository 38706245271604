import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import './neural_network_formula.css'
import * as d3 from 'd3'
import model1WeightJson from '../../model_data/ch1/ch1-neural_netwrok_model1.json'

import { recordAPIAction, SubmitAnswerAPI, recordAPI } from '../../utils/recordSubmitAPI'



function generatePythonCode (width, depth) {
  if (width < 1 || depth < 1) {
    return "Invalid input. Width and depth must be greater than or equal to 1."
  }

  let pythonCode = "import tensorflow as tf\n"
  pythonCode += "import numpy as np\n\n"

  pythonCode += "# Load Data...\n\n\n"

  pythonCode += "x_data = ...\n"
  pythonCode += "y_data = ...\n"
  pythonCode += "# Convert data to NumPy arrays\n"
  pythonCode += "x_data = np.array(x_data).astype(float)\n"
  pythonCode += "y_data = np.array(y_data).astype(float)\n\n"

  pythonCode += "epochs = ...\n"
  pythonCode += "model = tf.keras.Sequential([\n"
  pythonCode += "  tf.keras.layers.Input(shape=(1,)),\n"

  for (let i = 0; i < depth; i++) {
    pythonCode += `  tf.keras.layers.Dense(${width}, activation='relu'),\n`
  }

  pythonCode += "  tf.keras.layers.Dense(1)\n"
  pythonCode += "])\n\n"
  pythonCode += "model.compile(optimizer='adam', loss='mean_squared_error')\n\n"

  pythonCode += "model.fit(x=x_data, y=y_data, epochs=epochs)\n"

  return pythonCode
}




function NetworkControlPanel (props) {

  const [clickedNeural, setClickedNeural] = useState([])
  const [prevClickedNeural, setPrevClickedNeural] = useState([])
  const [clickedBiases, setClickedBiases] = useState([])
  const [clickedWeights, setClickedWeights] = useState([])

  //初始值
  const initLayers = [
    {
      id: uuid(),
      layerID: 1,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1, 2], null, null, null, null, null, null, null, null, null],
      biasList: [1, null, null, null, null, null, null, null, null, null],
      reluActive: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 2,
      numberOfNeural: 2,
      inputDisable: [false, false, true, true, true, true, true, true, true, true],
      weightList: [[3], [1], null, null, null, null, null, null, null, null, null],
      biasList: [1, 1, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 3,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1], null, null, null, null, null, null, null, null, null],
      biasList: [1, null, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 1, 0, 0, 0, 0, 0, 0],
      inOutputLayer: true
    }
  ]
  const getLayersFormJson = (data, layerLength) => {
    let newLayer = []
    const inputList = [170.73, 70.35, 60, 0]

    for (let layerNum = 0; layerNum < layerLength + 1; layerNum++) {
      let layer = data[`layer${layerNum}`]
      let prevLayer = data[`layer${layerNum - 1}`]

      let layerNeuralNumber = layer ? layer["weights"].length : 1

      let weightList = layerNum === layerLength ? [[1]] : layer["weights"]
      weightList = [...weightList, ...Array(10 - layerNeuralNumber).fill(null)]

      let biasList = layerNum === 0 ? inputList : prevLayer["bias"]
      biasList = [...biasList, ...Array(10 - layerNeuralNumber).fill(null)]


      newLayer.push({
        id: uuid(),
        layerID: layerNum + 1,
        numberOfNeural: layerNeuralNumber,
        inputDisable: Array(10).fill(true).map((value, index) => index > layerNeuralNumber - 1),
        weightList: weightList,
        biasList: biasList,
        reluActive: Array(10).fill(null).fill(1, 0, layerNeuralNumber),
        inOutputLayer: layerNum === layerLength ? true : false

      })

    }
    return newLayer

  }


  const [layers, setLayers] = useState(getLayersFormJson(model1WeightJson[`epoch_1`], 2))
  const [selectedModelID, setSelectedModelID] = useState(1)
  const [model1Process, setModel1Process] = useState(1)
  const [model2Process, setModel2Process] = useState(1)








  const modelSelectOnClick = (id) => {

    setSelectedModelID(id)
    if (id === 1) {
      setLayers(getLayersFormJson(model1WeightJson[`epoch_${model1Process}`], 2))
    }
    else if (id === 2) {
      setLayers(getLayersFormJson(model1WeightJson[`epoch_${model2Process}`], 2))

    }

  }



  const handleModelProcessChange = (value, item_id) => {
    // recordAPIAction("ModelOnchangeRecordAPI",  item_id, localStorage.getItem('userUUID'))
    recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "2-3", "Neural_Network_Practice", item_id, value, Date(), localStorage.getItem("userUUID"))
    setModel1Process(value)
    setLayers(getLayersFormJson(model1WeightJson[`epoch_${value}`], 2))
  }


  const Reset2InitLayer = () => {
    setLayers(initLayers)
    // if (index === 1) {
    //   setLayers(
    //     [
    //       {
    //         id: uuid(),
    //         layerID: 1,
    //         numberOfNeural: 4,
    //         inputDisable: [false, false, false, false, true, true, true, true, true, true],
    //         weightList: [
    //           [-0.59799993, -0.37269622, 0.46365443, 0.08975877],
    //           [-0.4373828, 0.2784637, 0.10311716, 0.10455135],
    //           [0.7490775, -0.04052863, 0.33612952, -0.35342735],
    //           [1.5339521, -2.0837278, 0.7424698, 1.9593663],
    //           null, null, null, null, null, null],
    //         biasList: [170.73, 70.35, 60, 0, null, null, null, null, null, null],
    //         reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    //         inOutputLayer: false
    //       },
    //       {
    //         id: uuid(),
    //         layerID: 2,
    //         numberOfNeural: 4,
    //         inputDisable: [false, false, false, false, true, true, true, true, true, true],
    //         weightList: [
    //           [-0.96086866],
    //           [1.3353832],
    //           [-0.3317529],
    //           [-3.2085323],
    //           null, null, null, null, null, null, null],
    //         biasList: [-7.898557, 8.012515, -8.217629, -8.022755, null, null, null, null, null, null],
    //         reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    //         inOutputLayer: false
    //       },
    //       {
    //         id: uuid(),
    //         layerID: 3,
    //         numberOfNeural: 1,
    //         inputDisable: [false, true, true, true, true, true, true, true, true, true],
    //         weightList: [[1], null, null, null, null, null, null, null, null, null],
    //         biasList: [7.9377675, null, null, null, null, null, null, null, null, null],
    //         reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    //         inOutputLayer: true
    //       }
    //     ]
    //   )
    // }
    // else if (index === 2) {
    //   //random set layers to random weights with 3 layers

    //   setLayers(
    //     [
    //       {
    //         id: uuid(),
    //         layerID: 1,
    //         numberOfNeural: 1,
    //         inputDisable: [false, true, true, true, true, true, true, true, true, true],
    //         weightList: [[1, 2], null, null, null, null, null, null, null, null, null],
    //         biasList: [1, null, null, null, null, null, null, null, null, null],
    //         reluActive: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    //         inOutputLayer: false
    //       },
    //       {
    //         id: uuid(),
    //         layerID: 2,
    //         numberOfNeural: 2,
    //         inputDisable: [false, false, true, true, true, true, true, true, true, true],
    //         weightList: [[3], [1], null, null, null, null, null, null, null, null, null],
    //         biasList: [1, 1, null, null, null, null, null, null, null, null],
    //         reluActive: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
    //         inOutputLayer: false
    //       },
    //       {
    //         id: uuid(),
    //         layerID: 3,
    //         numberOfNeural: 1,
    //         inputDisable: [false, true, true, true, true, true, true, true, true, true],
    //         weightList: [[1], null, null, null, null, null, null, null, null, null],
    //         biasList: [1, null, null, null, null, null, null, null, null, null],
    //         reluActive: [1, 1, 0, 1, 0, 0, 0, 0, 0, 0],
    //         inOutputLayer: true
    //       }
    //     ]
    //   )

    // }

  }



  // const generateWeightList = (width, length) => {
  //   const resultArray = Array(length).fill(null)

  //   for (let i = 0; i < width; i++) {
  //     resultArray[i] = Array(width).fill(1)
  //   }

  //   return resultArray
  // }

  // const generateFormula = (inputSize, networkWidth, networkDepth) => {

  //   let inputValue = ['x1', 'x2']
  //   let prevValue = ['x1', 'x2']
  //   let tempValue = []
  //   let resultList = Array.from({ length: 10 }, () => Array.from({ length: 8 }, () => ""))

  //   for (let layer = 0; layer < networkDepth + 1; layer++) {
  //     const currNeuralNumber = layer === networkDepth ? 1 : networkWidth
  //     for (let n_index = 0; n_index < currNeuralNumber; n_index++) {
  //       if (layer === 0) {
  //         for (let prev_n_index = 0; prev_n_index < networkWidth; prev_n_index++) { // 2 is the number of input
  //           resultList[layer + 1][n_index] += `(w${layer + 1}${prev_n_index + 1}${n_index + 1}) * x${prev_n_index} +  `
  //         }
  //         resultList[layer + 1][n_index] += `(w${layer + 1}${networkWidth}${n_index + 1}) * x${networkWidth} + bias${layer}${n_index + 1}`
  //       }
  //       else {
  //         const numOfneuralOflayer = networkWidth - 1
  //         for (let prev_n_index = 0; prev_n_index < numOfneuralOflayer; prev_n_index++) {
  //           // resultList[layer + 1][n_index] += `(${weightList[layer][prev_n_index][n_index]}) * (${resultList[layer][prev_n_index]}) + `
  //           resultList[layer + 1][n_index] += `(w${layer + 1}${prev_n_index + 1}${n_index + 1}) * (${resultList[layer][prev_n_index]})+ `
  //         }
  //         resultList[layer + 1][n_index] += `(w${layer + 1}${numOfneuralOflayer + 1}${n_index + 1}) * (${resultList[layer][numOfneuralOflayer]}) + bias${layer}${n_index + 1}`
  //         // resultList[layer + 1][n_index] += `(${weightList[layer][numOfneuralOflayer][n_index]}) * (${resultList[layer][numOfneuralOflayer]}) + (${biasList[layer][n_index]})`

  //       }
  //     }
  //   }

  //   // console.log("resultList", resultList)
  //   const math = require('mathjs')


  // }



  // useEffect(() => {
  //   let newLayer = []
  //   const neuralNumber = 10
  //   const networkWidth = props.networkStructure[0]["width"] + 1
  //   const networkDepth = props.networkStructure[0]["depth"] + 1
  //   // let epochList = [1, 2, 3, 5, 10, 50, 100, 500]
  //   // let activationFunctionList = ["linear", "relu", "sigmoid"]
  //   // let dataTypeList = ["linear", "non-linear"]
  //   // const epochs = epochList[parseInt(props.networkStructure[0]["epochs"], 10)]

  //   // const activationFunction = activationFunctionList[props.networkStructure[0]["AF"]]
  //   // const dataType = dataTypeList[props.task_functionType[0]["functionType"]]
  //   // let weigths = <weightJson dataType="" networkDepth="" networkWidth="" activationFunction="" epochs=""></weightJson>


  //   newLayer.push({
  //     id: uuid(),
  //     layerID: 1,
  //     numberOfNeural: 1,
  //     inputDisable: Array(neuralNumber).fill(true).fill(false, 0, 1),
  //     weightList: Array(neuralNumber).fill(null).map((el, i) => (i === 0 ? Array(networkWidth).fill(1) : el)),
  //     // weightList: generateWeightList(networkWidth, neuralNumber),
  //     biasList: Array(neuralNumber).fill(null).fill(1, 0, 1),
  //     reluActive: Array(neuralNumber).fill(null).fill(1, 0, 1),
  //     inOutputLayer: false
  //   })


  //   //Hidden Layer
  //   for (let i = 1; i < networkDepth; i++) {

  //     newLayer.push({
  //       id: uuid(),
  //       layerID: i + 1,
  //       numberOfNeural: networkWidth,
  //       inputDisable: Array(neuralNumber).fill(true).fill(false, 0, networkWidth),
  //       weightList: generateWeightList(networkWidth, neuralNumber),
  //       // weightList:fillWeightAndBiasToList(networkWidth, 10, weigths[i]["weight"]),
  //       biasList: Array(neuralNumber).fill(null).fill(1, 0, networkWidth),
  //       // biasList:fillWeightAndBiasToList(networkWidth, 10, weigths[i]["bias"]),
  //       reluActive: Array(neuralNumber).fill(null).fill(1, 0, networkWidth),
  //       inOutputLayer: false
  //     })
  //   }

  //   newLayer.push({
  //     id: uuid(),
  //     layerID: networkDepth + 1,
  //     numberOfNeural: networkWidth,
  //     inputDisable: Array(neuralNumber).fill(true).fill(false, 0, networkWidth),
  //     weightList: Array(neuralNumber).fill(null).fill([1], 0, networkWidth),
  //     // weightList:fillWeightAndBiasToList(networkWidth, 10, weigths[i]["weight"]),
  //     biasList: Array(neuralNumber).fill(null).fill(1, 0, networkWidth),
  //     // biasList:fillWeightAndBiasToList(networkWidth, 10, weigths[i]["bias"]),
  //     reluActive: Array(neuralNumber).fill(null).fill(1, 0, networkWidth),
  //     inOutputLayer: false
  //   })


  //   //output layer
  //   newLayer.push({
  //     id: uuid(),
  //     layerID: networkDepth + 2,
  //     numberOfNeural: 1,
  //     inputDisable: [false, true, true, true, true, true, true, true, true, true],
  //     weightList: [[1], null, null, null, null, null, null, null, null, null], //output
  //     biasList: [1, null, null, null, null, null, null, null, null, null],
  //     reluActive: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  //     inOutputLayer: true
  //   })

  //   setLayers(newLayer)

  // }, [])

  const updateFormula = (newLayer) => {
    const weightList = newLayer.map(item => (item.weightList))
    const biasList = newLayer.map(item => (item.biasList))

    const numberOfNeuralList = newLayer.map(item => (item.numberOfNeural))
    const listLength = weightList.length

    // console.log(numberOfNeuralList)

    let resultList = Array.from({ length: 10 }, () => Array.from({ length: 8 }, () => ""))


    for (let layer = 0; layer < listLength - 1; layer++) {
      for (let n_index = 0; n_index < numberOfNeuralList[layer + 1]; n_index++) {
        if (layer === 0) {
          for (let prev_n_index = 0; prev_n_index < numberOfNeuralList[layer]; prev_n_index++) {
            resultList[layer + 1][n_index] += `(${weightList[layer][prev_n_index][n_index]}) * x${prev_n_index} + `
          }
          resultList[layer + 1][n_index] += `${biasList[layer + 1][n_index]}`
        }
        else {
          const numOfneuralOflayer = numberOfNeuralList[layer] - 1
          for (let prev_n_index = 0; prev_n_index < numOfneuralOflayer; prev_n_index++) {
            resultList[layer + 1][n_index] += `(${weightList[layer][prev_n_index][n_index]}) * (${resultList[layer][prev_n_index]}) + `
          }
          resultList[layer + 1][n_index] += `(${weightList[layer][numOfneuralOflayer][n_index]}) * (${resultList[layer][numOfneuralOflayer]}) + (${biasList[layer + 1][n_index]})`

        }
      }
    }
    console.log(resultList)
    const math = require('mathjs')
    const result = math.simplify(resultList[listLength - 1][0],
      {
        x0: parseFloat(layers[0].biasList[0]),
        x1: parseFloat(layers[0].biasList[1]),
        x2: parseFloat(layers[0].biasList[2]),
        x3: parseFloat(layers[0].biasList[3]),
        x4: parseFloat(layers[0].biasList[4]),
        x5: parseFloat(layers[0].biasList[5]),
        x6: parseFloat(layers[0].biasList[6]),
        x7: parseFloat(layers[0].biasList[7]),
        x8: parseFloat(layers[0].biasList[8]),
        x9: parseFloat(layers[0].biasList[9]),
        x10: parseFloat(layers[0].biasList[10]),
        x11: parseFloat(layers[0].biasList[11]),
        x12: parseFloat(layers[0].biasList[12]),

      })
    // console.log("result", result.toString())
    props.setFormulaOutput(parseFloat(eval(result.toString())))
    // props.updateFormula(resultList[listLength - 1][0])

  }

  useEffect(() => {

    updateFormula(layers)
    props.updateLayers(layers.map(layer => (layer.numberOfNeural)))


  }, [layers, props.networkStructure])




  const deleteLayer = (id) => {
    recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "2-3",
      "Neural_Network_Practice", "deleteLayer", null, Date(), localStorage.getItem("userUUID"))
    let removeId
    const updatedLayers = layers.filter((item, index) => {
      if (item.id === id) {
        removeId = index
        return false
      }
      return true
    })
    const prevExtractedLayer = layers[removeId - 1]
    const diff = layers[removeId + 1].numberOfNeural - layers[removeId].numberOfNeural

    if (prevExtractedLayer) {
      if (diff < 0) {
        prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
          if (weights !== null) {
            return weights.slice(0, diff)
          }
          return null
        })
      }
      else {
        prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
          if (weights !== null) {
            return [...weights, ...Array(diff).fill(1)]
          }
          return null
        })
      }
    }


    const updatedLayersWithIDs = updatedLayers.map((item, index) => ({
      ...item,
      layerID: index + 1
    }))

    setLayers(updatedLayersWithIDs)
  }


  const addLayer = () => {
    recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "2-3", "Neural_Network_Practice", "addLayer", null, Date(), localStorage.getItem("userUUID"))
    const newID = layers.length
    if (newID >= 5) {
      return
    }
    const newLayer = {
      id: uuid(),
      layerID: newID,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1], null, null, null, null, null, null, null, null, null],
      biasList: [1, null, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    }


    const lastLayer = layers[layers.length - 1]
    if (lastLayer) {
      lastLayer.layerID = layers.length + 1
    }


    setLayers((prevLayer => {
      const newLayers = [...layers.slice(0, layers.length - 1), newLayer, lastLayer]
      return newLayers
    }))

  }

  const generateLayer = (item, prevId) => {



    const handleWeightInputChange = (id, neuralID, weightID, event) => {
      let newValue = event.target.value

      if (newValue !== '' && (newValue < -10)) {
        newValue = -10
      }
      else if (newValue > 10) {
        newValue = 10
      }

      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {

        extractedLayer.weightList = extractedLayer.weightList.map((nerual, index) => {

          if (index === neuralID) {
            return nerual.map((weight, wIndex) => wIndex === weightID ? newValue : weight)
          }
          else {
            return nerual
          }
        })

      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))


      setLayers(updatedLayers)

    }

    const handleBiasInputChange = (id, BiasID, event) => {

      let newValue = (event.target.value)

      // 限制在0～200
      if (newValue !== '' && (newValue < 0)) {
        newValue = 0
      }
      else if (newValue > 200) {
        newValue = 200
      }

      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {
        extractedLayer.biasList = extractedLayer.biasList.map((bias, index) =>
          index === BiasID ? newValue : bias)
      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))
      setLayers(updatedLayers)

    }


    // 處理點擊神經元時的事件(顯示公式)
    const handleNeuralOnClick = (neuralID, layerID, item_id) => {



      recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "2-3", "Loss_Function", item_id, null, Date(), localStorage.getItem("userUUID"))



      if (layerID === 0) {
        const clickedElement = document.querySelector(`.layer${layerID + 1}N.n_id${layerID + 1}${neuralID}`)
        clickedElement.classList.add('onclick-prev-neural')

        props.setSingleNeuralFormula(`<div class="layer${layerID + 1}N">N<sub>${layerID + 1}</sub><sub>${neuralID}</sub>= </div> <div>input1</div> `)

      }
      else {

        const prevLayerID = layerID - 1
        const prevNumberOfNeural = parseInt(layers[prevLayerID].numberOfNeural, 10)
        let onclickWeights = []
        let singleNeuralFormula = `<div class="layer${layerID + 1}N">N<sub>${layerID + 1}</sub><sub>${neuralID}</sub>= </div>  `

        for (let i = 0; i < prevNumberOfNeural - 1; i++) {
          singleNeuralFormula += `<div class="layer${layerID}N">N<sub>${prevLayerID + 1}${i + 1}</sub> * W<sub>${prevLayerID + 1}${i + 1}${neuralID}</sub> + <br></div>`
          onclickWeights.push(document.querySelector(`.weight${prevLayerID + 1}${i + 1}${neuralID}`))
        }

        singleNeuralFormula += `<div class="layer${layerID}N">N<sub>${prevLayerID + 1}${prevNumberOfNeural}</sub> * W<sub>${prevLayerID + 1}${prevNumberOfNeural}${neuralID}</sub> + <br>
                              </div><div class="layer${layerID + 1}N">B<sub>${prevLayerID + 2}${neuralID}</sub></div>`
        onclickWeights.push(document.querySelector(`.weight${prevLayerID + 1}${prevNumberOfNeural}${neuralID}`))



        // // Remove the class "onclick-prev-neural" from all elements
        // const elements = document.querySelectorAll('.onclick-prev-neural')
        // elements.forEach(element => {
        //   element.classList.remove('onclick-prev-neural')
        // })

        // // Add the class "onclick-prev-neural" to the clicked element
        // const clickedElementT = document.querySelector(`.layer${layerID + 1}N.n_id${layerID + 1}${neuralID}`)
        // clickedElementT.classList.add('onclick-prev-neural')

        // // Add the class "onclick-prev-neural" to all elements with class `layer${prevLayerID + 1}N`
        // const targetElements = document.querySelectorAll(`.layer${prevLayerID + 1}N`)
        // const targetBias = document.querySelectorAll(`.layer${prevLayerID + 1}${neuralID}B`)

        // targetBias.forEach(element => {
        //   if (!element.classList.contains('onclick-prev-neural')) {
        //     element.classList.add('onclick-prev-neural')
        //   }
        // })
        // targetElements.forEach(element => {
        //   if (!element.classList.contains('onclick-prev-neural')) {
        //     element.classList.add('onclick-prev-neural')
        //   }
        // })

        // onclickWeights.forEach(element => {
        //   if (!element.classList.contains('onclick-prev-neural')) {
        //     element.classList.add('onclick-prev-neural')
        //   }
        // })

        const newPrevClickedNeural = [`.layer${prevLayerID + 1}N`]
        const newClickedBiases = [`.layer${prevLayerID + 1}${neuralID}B`]
        setClickedBiases(newClickedBiases)
        setPrevClickedNeural(newPrevClickedNeural)

        const newClickedWeights = []
        for (let i = 0; i < prevNumberOfNeural + 1; i++) {
          newClickedWeights.push(`.weight${prevLayerID + 1}${i}${neuralID}`)
        }
        setClickedWeights(newClickedWeights)


        setClickedNeural({ layerID: layerID + 1, neuralID })
        props.setSingleNeuralFormula(singleNeuralFormula)
      }

    }

    // 生成每一個神經元所連接的權重和偏值
    const renderParaInputs = (item, prevId, layerID) => {
      const Inputs = []
      let nerualWeights = []
      let weightsContainer = []
      const currNumberOfNeural = parseInt(layers[layerID].numberOfNeural, 10)

      const currExtractedLayer = layers.find(layer => layer.id === item.id)



      for (let i = 1; i <= currNumberOfNeural; i++) {
        if (item.weightList[i - 1] != null) {
          if (item.layerID != 1) {
            nerualWeights.push(
              <>
                <div className={`text layer${layerID}${i}B ${clickedBiases.includes(`.layer${layerID}${i}B`) ? 'onclick-prev-neural' : ''}`}>B<sub>{layerID + 1}{i}</sub>:</div>
                < input
                  className='ch1-1-bias-input'
                  type='number'
                  min='0'
                  max='200'
                  step='0.5'
                  placeholder={`bias${i}`}
                  value={(currExtractedLayer.biasList[i - 1])}
                  onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                  disabled={true}
                />

              </>
            )
          }
          else {
            // Input Layer
            nerualWeights.push(
              <div>
                <div className={`text layer${layerID}${i}B`}>
                  {/* {i === 1 && "身高:"}
                  {i === 2 && "體重:"}
                  {i === 3 && "年齡:"}
                  {i === 4 && "性別:"} */}
                  input{i}
                </div>
                {/*性別只能選擇1 或 0 */}
                {(i === 4) ? (
                  <select
                    className='ch1-1-network-input'
                    // id={`ch2-3_network-input${i}`}
                    value={currExtractedLayer.biasList[i - 1]}
                    onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                    onMouseUp={(event) => recordAPI(
                      "http://localhost:8080/resource/api/v1/insertRecord",
                      "2-3",
                      "Loss_Function",
                      event.target.id,
                      null,
                      Date(),
                      localStorage.getItem("userUUID"))
                    }
                    disabled={false}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </select>
                ) :
                  // 非性別選項可以填入數字
                  (
                    <input
                      className='ch1-1-network-input'
                      id={`ch2-3_network-input${i}`}
                      type='number'
                      min='0'
                      max='200'
                      step='0.05'
                      value={currExtractedLayer.biasList[i - 1]}
                      onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                      onMouseUp={(event) => recordAPI(
                        "http://localhost:8080/resource/api/v1/insertRecord",
                        "2-3",
                        "Loss_Function",
                        event.target.id,
                        null,
                        new Date(),
                        localStorage.getItem("userUUID"))
                      }
                      placeholder={`input ${i}`}
                      disabled={false}
                    />
                  )}

              </div>
            )
          }

          nerualWeights.push(
            <div
              className={`neural layer${layerID + 1}N n_id${layerID + 1}${i} 
                ${prevClickedNeural.includes(`.layer${layerID + 1}N`) ? 'onclick-prev-neural' : ''}
                ${clickedNeural && clickedNeural.layerID === layerID + 1 && clickedNeural.neuralID === i ? 'onclick-prev-neural' : ''}`}
              onClick={(event) => handleNeuralOnClick(i, layerID, event.target.id)}
              id={`ch2-3_neural${layerID + 1}${i}`}
            >
              N
              <sub>
                {layerID + 1}{i}
              </sub>
            </div>
          )
          for (let index = 1; index <= item.weightList[i - 1].length; index++) {
            weightsContainer.push(

              <div className='weight-container' >
                {
                  // 如果是output layer，則顯示--Output
                  item.inOutputLayer && item.layerID > 1 ?
                    <>
                      <div className={`weight${layerID + 1}${i}${index}`}>--Output<sub>{i}</sub>--</div>
                    </>
                    :
                    <div className={`weight${layerID + 1}${i}${index}
                    ${clickedWeights.includes(`.weight${layerID + 1}${i}${index}`) ? 'onclick-prev-neural' : ''}
                    `}>W<sub>{layerID + 1}{i}{index}</sub>:</div>
                }

                <input
                  className='ch1-1-weight-input'
                  type='number'
                  min='-100'
                  max='100'
                  step='0.05'
                  placeholder={`neural${i}`}
                  value={(item.inOutputLayer && item.layerID > 1 ? (props.formulaOutput.toFixed(1)) :
                    item.inputDisable[i - 1] ? `${i}` : (item.weightList[i - 1][index - 1]).toFixed(1))}
                  onChange={(event) => handleWeightInputChange(item.id, i - 1, index - 1, event)}
                  disabled={true}
                />

              </div>
            )
          }
        }

        Inputs.push(
          <div className='ch1-1-control-input-container' key={`ch1-1-control-input-container${i}`}>
            {nerualWeights.map((element) => (
              <div key={element.id}>{element}</div>
            ))}
            <div className='weights-container' key={`weights-container${i}`}>
              {weightsContainer.map((element) => (
                <div key={element.id}>{element}</div>
              ))}
            </div>
          </div>
        )

        nerualWeights = []
        weightsContainer = []
      }
      return Inputs
    }
    
    const handleNumberOfNeuralInputChange = (id, prevId, event) => {

      //因為要更新整個layer，所以先把所有的onclick-prev-neural class移除
      const elements = document.querySelectorAll('.onclick-prev-neural')

      elements.forEach(element => {
        // Remove the class "onclick-prev-neural" from each element
        element.classList.remove('onclick-prev-neural')
      })

      //同時把singleNeuralFormula清空
      props.setSingleNeuralFormula("")
      
      let newValue = parseInt(event.target.value);
  
      if (isNaN(newValue) || newValue < 1) {
        newValue = 1;
      } else if (newValue >= 6) {
        newValue = 6;
        return

      }

      

      
      const prevExtractedLayer = layers.find(layer => layer.id === prevId)
      const extractedLayer = layers.find(layer => layer.id === id)
      const nextLayerIndex = prevId ? prevExtractedLayer.layerID + 1 : 1
      // let N_weights = Math.abs(newValue - prevExtractedLayer.numberOfNeural);

      
      // 對上一層每一個neural增加N個weight

      if (prevExtractedLayer) {
        
        prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
          
          if (weights !== null) {
            if (newValue < extractedLayer.numberOfNeural) {
              return weights.slice(0, newValue);
            } else {
              return [...Array(newValue).fill(1)];
            }
          } else {
            return weights;
          }
        });
        
      }

      // 對該層新增一個weight
      
      if (extractedLayer) {
        
        
        extractedLayer.numberOfNeural = newValue
        
        // 對該層新增N個weight
        extractedLayer.weightList = extractedLayer.weightList.map((weight, index) => index < newValue && weight === null ?
          Array.from({
            length: layers[nextLayerIndex].numberOfNeural
          }, () => 1)
          : weight)

        // 對該層新增一個bias
        
        extractedLayer.biasList = extractedLayer.biasList.map((bias, index) => index < newValue && bias === null ? 1 : bias)
        
        
        extractedLayer.inputDisable = Array(10).fill(true).map((value, index) => index > newValue - 1)
        
      }

      // 更新整個layer
      const updatedLayers = layers.map((layer, index) => {
    
        if (layer.id === id) {
          return extractedLayer
        } else if (index > 0 && layers[index - 1].id === prevId) {
          return prevExtractedLayer
        } else {
          return layer
        }
      })
      setLayers(updatedLayers)
      console.log("updatedLayers", updatedLayers)

    }




    return (
      <div key={item.id} className='ch1-1-layer-container'>
        <div className='layer-input-container'>
          <div>第 {item.layerID} 層: </div>
          <input
            type='number'
            className='weight-input-box'
            id={`layerInput${item.layerID}`}
            min='1'
            max='10'
            placeholder='number of neural'
            value={parseInt(item.numberOfNeural)}
            onChange={(event) => handleNumberOfNeuralInputChange(item.id, prevId, event)}
            
          //Block all input
            // disabled={true}
            disabled={item.inOutputLayer}
          />


        </div>
        <div className='ch1-1-weights-bias-container'>
          {renderParaInputs(item, prevId, item.layerID - 1)}
        </div>

      </div>
    )
  }



  return (
    <>
      <div className="model-select-container bg-white">
        <div className='font-weight-bold p-2 d-flex justify-content-center m-2 rounded border border-5 p-2'>
          <div className=
            {selectedModelID === 1 ?
              'model-selected m-2 p-2' :
              'm-2 p-2'
            }
          >
            <div className='ch1-model1'
              onClick={() => modelSelectOnClick(1)}
            >
              模型
            </div>
            <select
              className="input-box"
              id='2-3_model-epochs-select'
              disabled={selectedModelID !== 1}
              onChange={(event) => handleModelProcessChange(event.target.value, event.target.id)}
            >
              <option value="1">1 epoch</option>
              <option value="50">50 epochs</option>
              <option value="100">100 epochs</option>
              <option value="150">150 epochs</option>
              <option value="200">200 epochs</option>
              <option value="250">250 epochs</option>
            </select>

          </div>
          <button className="m-2 rounded p-2 border-0" onClick={() => Reset2InitLayer()}>自訂模型</button>
        </div>
      </div>
      <div className='ch1-1-control-panel-section'>
        {
          layers.map((item, index, array) => (
            generateLayer(item, index > 0 ? array[index - 1].id : null)
          ))
        }
        <button onClick={addLayer}>新增一層</button>
      </div>

    </>

  )
}




function NetworkVisualization (props) {

  const svgRef = useRef(null)



  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const width = +svg.attr('width')
    const height = +svg.attr('height')
    let layerSizes = [...props.layers]
    // console.log("layerSizes:", layerSizes)


    // 每层的神经元数量

    const neuronRadius = 15 // 神经元半径（调整大小）
    const layerGap = 60 // 层之间的间距
    const neuronGap = 50 // 神经元之间的间距

    // 创建一个容器组，并将容器置中
    svg.selectAll('*').remove() // 清空SVG内容
    const container = svg.append('g')
      .attr('transform', `translate(${(width / 2 - (layerSizes.length * neuronGap) - 120)}, ${height / 2 + 25})`)


    // 计算每层的水平位置
    const layerXPositions = []
    // let xPosition = -((layerSizes.length - 1) * layerGap) / 2;
    let xPosition = 0

    layerSizes.forEach((size) => {
      layerXPositions.push(xPosition)
      xPosition += size * (neuronRadius * 1 + neuronGap / 2) + layerGap
    })


    // 绘制神经元和连接线
    for (let i = 0; i < layerSizes.length; i++) {
      const layerSize = layerSizes[i]
      const x = layerXPositions[i]
      const yStart = -(layerSize / 2) * (neuronRadius * 2 + neuronGap)




      // 绘制每一层的神经元

      for (let j = 0; j < layerSize; j++) {
        const y = yStart + j * (neuronRadius * 2 + neuronGap)
        // 绘制神经元圆
        if (j === 0) {
          container
            .append('text')
            .attr('x', x)
            .attr('y', y - 20) // 调整标签的垂直位置
            .attr('text-anchor', 'middle') // 文本居中对齐
            .attr('fill', 'black') // 可选，设置文本颜色
            .text(() => {
              if (i === 0) {
                return '輸入層'
              } else if (i === layerSizes.length - 1) {
                return '輸出層'
              } else {
                return '隱藏層'
              }
            })


        }

        container
          .append('circle')
          .attr('cx', x)
          .attr('cy', y)
          .attr('r', neuronRadius)
          .attr('fill', 'lightblue')

        if ((j != 0 || j !== layerSize - 1) && props.networkStructure[0]["AF"] == 1) {
          container
            .append('line')
            .attr('x1', x)
            .attr('y1', y + 3)
            .attr('x2', x - 10)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
          container
            .append('line')
            .attr('x1', x + 5)
            .attr('y1', y - 8 + 3)
            .attr('x2', x)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
        }
        else if ((j != 0 || j !== layerSize - 1) && props.networkStructure[0]["AF"] == 2) {
          // const sPath = "M-5,0 C-5,10 5,10 5,0 Q5,-10 -5,-10 Q-5, -5 -5,0 Z"

          // 定义 S 形状的路径
          const sPath = (x, y) => `M${x - 10} ${y + 10} C${x - 6} ${y + 10}, ${x - 4} ${y + 10}, ${x} ${y} S${x + 5} ${y - 10}, ${x + 10} ${y - 10}`

          // 添加 S 形状的路径
          container
            .append("path")
            .attr("d", sPath(x, y))
            .attr("stroke", 'black')
            .attr("fill", "none")


        }

      }



      if (i < layerSizes.length - 1) {
        const nextLayerSize = layerSizes[i + 1]
        const nextX = layerXPositions[i + 1]
        for (let j = 0; j < layerSize; j++) {
          const startY = yStart + j * (neuronRadius * 2 + neuronGap)
          for (let k = 0; k < nextLayerSize; k++) {

            const endY = yStart + k * (neuronRadius * 2 + neuronGap) - (nextLayerSize - layerSize) * (neuronGap / 2 + neuronRadius)
            // 绘制连接线
            container
              .append('line')
              .attr('x1', x + neuronRadius)
              .attr('y1', startY)
              .attr('x2', nextX - neuronRadius)
              .attr('y2', endY)
              .attr('stroke', 'gray')
              .attr('stroke-width', 2)
          }
        }
      }
    }
    // console.log("graphChange")
  }, [props.layers, props.networkStructure])

  return (
    <svg
      className='network-graph'
      ref={svgRef}
      width="1000"
      height="500"
      style={{ background: 'rgba(128, 128, 128, 0.1)' }}
    ></svg>

  )


}


function FormulaArea (props) {


  return (
    <div className="formula-container">
      <div className="formula" dangerouslySetInnerHTML={{ __html: props.singleNeuralFormula }}></div>
    </div>
  )
}


function CH2_3NeuralNetworkFormula () {
  // const [formula, setFormula] = useState("Math.max(0, x + 1)")
  const [formulaOutput, setFormulaOutput] = useState(5)
  const [layers, setLayers] = useState([1, 2, 2, 1])
  const [singleNeuralFormula, setSingleNeuralFormula] = useState("")
  const [networkStructure, setNetworkStruture] = useState([
    {
      "AF": 1,
      "depth": 1,
      "width": 1,
      "epochs": 2
    }
  ])

  const [q1Ans, setq1Ans] = useState({
    "1": "",
    "2": ""
  })
  const [q2Ans, setq2Ans] = useState({
    "1": "",
    "2": "",
    "3": "",
    "4": "",
  })


  return (
    <>
      <div>
        <div className='title-container'>
          <div className='title'>基礎練習</div>
          <div className='subtitle'>Neural Network Practice</div>
        </div>
        <div className="bullet-point"> • 模擬說明</div>
        <div className='simulation-description'>
          小海狸目前已經訓練完好一個訓練模型，這些模型用於預測體脂率。為了更深入暸解神經網路的構造和原理，他打算觀察不同階段的Epochs的模型有什麼區別。請嘗試調整不同的訓練階段，觀察模型的變化，並回答相關的練習問題。除此之外，您還可以選擇自訂模型觀察模型整體的變化。
        </div>

        <div className='ch1-1-c-container'>

          <div className="">
            <NetworkControlPanel
              // networkStructure={networkStructure}
              // task_functionType={task_functionType}
              // updateFormula={setFormula}
              updateLayers={setLayers}
              setSingleNeuralFormula={setSingleNeuralFormula}
              formulaOutput={formulaOutput}
              setFormulaOutput={setFormulaOutput}
            ></NetworkControlPanel>
          </div>
          <div className='ch1-1-visulization-container'>
            <div>
              <div className='sub-bullet-point'> • 神經網路架構</div>
              <NetworkVisualization
                layers={layers}
                networkStructure={networkStructure}
              ></NetworkVisualization>
            </div>
            <div className='formula-section'>
              <div className='sub-bullet-point'> • 神經元函式</div>
              <FormulaArea
                singleNeuralFormula={singleNeuralFormula}
              ></FormulaArea>
            </div>
          </div>
          <div className='question-section'>
            <div className='sub-bullet-point'> • 練習問題</div>
            <div className='question－container'>
              <div className='question-title'>1.  小海狸為了暸解模型中的神經元，他打算把神經元N33進行展開，請根據下圖，幫助他填入空缺的位置。</div>
              <img src="/ch1-1-q1.png" alt="" />
              <div className='full-question-container'>
                <div>
                  (N11 *
                </div>
                <input type="text"
                  value={q1Ans["1"]}
                  onChange={(event) => setq1Ans({ ...q1Ans, "1": event.target.value })}
                />
                <div>) * W213 + (</div>
                <input type="text"
                  value={q1Ans["2"]}
                  onChange={(event) => setq1Ans({ ...q1Ans, "2": event.target.value })}
                />
                <div>+ B22) * W223 + B33</div>
                <button onClick={() => {
                  if (window.confirm("確定要提交嗎？")) {
                    SubmitAnswerAPI(
                      "http://localhost:8080/resource/api/v1/insertAnswer",
                      "2-3",
                      "Neural_Network_Practice",
                      "1",
                      q1Ans,
                      Date(),
                      localStorage.getItem('userUUID'),
                      () => {
                        alert("提交成功！")
                      }
                    )
                  }
                }}>
                  提交
                </button>

              </div>
              <div className='question-title'>2.
                小海狸為了選出較為合適的模型，打算使用下方表格的數據對模型進行驗證，請比對模型Epoch為1、100、250的損失值（MAE），以確定哪個Epoch數的模型較為優良。以下是提供的表格。
              </div>
              <table border="1">
                <thead>
                  <tr>
                    <th>input1</th>
                    <th>input2</th>
                    <th>input3</th>
                    <th>input4</th>
                    <th>正確體脂率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>189.6</td>
                    <td>116.1</td>
                    <td>30.0</td>
                    <td>1.0</td>
                    <td>29.0</td>
                  </tr>
                  <tr>
                    <td>176.9</td>
                    <td>62.3</td>
                    <td>24.0</td>
                    <td>1.0</td>
                    <td>15.2</td>
                  </tr>
                  <tr>
                    <td>172.1</td>
                    <td>119.2</td>
                    <td>38.0</td>
                    <td>1.0</td>
                    <td>39.2</td>
                  </tr>
                  {/* <tr>
                    <td>165.1</td>
                    <td>85.7</td>
                    <td>26.0</td>
                    <td>1.0</td>
                    <td>27.0</td>
                  </tr>
                  <tr>
                    <td>182.0</td>
                    <td>88.2</td>
                    <td>42.0</td>
                    <td>1.0</td>
                    <td>24.7</td>
                  </tr> */}
                </tbody>
              </table>

              <div>(1)模型-1epochs損失值:</div>
              <input type="text"
                value={q2Ans["1"]}
                onChange={(event) => setq2Ans({ ...q2Ans, "1": event.target.value })}
              />
              <div>(2)模型-100epochs損失值:</div>
              <input type="text"
                value={q2Ans["2"]}
                onChange={(event) => setq2Ans({ ...q2Ans, "2": event.target.value })}
              />
              <div>(3)模型-250epochs損失值:</div>
              <input type="text"
                value={q2Ans["3"]}
                onChange={(event) => setq2Ans({ ...q2Ans, "3": event.target.value })}
              />
              <div>(4)根據上方表格的驗證資料，你認為模型在那個階段（epoch）比較好？請簡短說明原因。</div>
              {/* <input className="w-75" type="text"
                value={q2Ans["4"]}
                onChange={(event) => setq2Ans({ ...q2Ans, "4": event.target.value })}
              /> */}
              <textarea
                className='input-ans-area'
                value={q2Ans["4"]}
                onChange={(event) => setq2Ans({ ...q2Ans, "4": event.target.value })}
              />

              <button onClick={() => {
                if (window.confirm("確定要提交嗎？")) {
                  SubmitAnswerAPI(
                    "http://localhost:8080/resource/api/v1/insertAnswer",
                    "2-3",
                    "Neural_Network_Practice",
                    "2",
                    q2Ans,
                    Date(),
                    localStorage.getItem('userUUID'),
                    () => {
                      alert("提交成功！")
                    }
                  )
                }
              }}>
                提交
              </button>


            </div>


          </div>
        </div>
      </div>
    </>

  )
}

export default CH2_3NeuralNetworkFormula