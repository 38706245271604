
export async function recordAPIAction(actionName, id, uuid) {
  try {
    console.log(actionName, id);
    console.log("localStorage.getItem('userUUID')", uuid);

    const response = await sendRequest(id, uuid);
    console.log("Response:", response);
  } catch (error) {
    console.error("Error:", error);
  }
}

// export async function recordAPI (apiUrl, ChapterID, ChapterName, ElementID, Value, Time, uuid) {

//   try {
//     console.log(ChapterID, ChapterName, ElementID, Value, Time, uuid)
//     const response = await sendRequest([ChapterID, ChapterName, ElementID, Value, Time, uuid]);
//     console.log("Response:", response)
//   } catch (error) {
//     alert("Record Failed. Please contact the administrator.")
//     console.error("Error:", error)
    
//   }

// }

export async function recordAPI(apiUrl, ChapterID, ChapterName, ElementID, Value, Time, uuid) {
  const requestData = {
    chapterID: ChapterID,
    chapterName: ChapterName,
    elementID: ElementID,
    value: JSON.stringify(Value),
    time: Time,
    uuid: uuid
  };
  try {
    const response = await fetch("https://mlapi.teliclab.info/resource/api/v1/insertRecord", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

  } catch (error) {
    console.error('API error:', error);

    // alert("Record Failed. Please contact the administrator.")
  }
}



// export async function SubmitAnswerAPI(apiUrl, ChapterID, ChapterName, QuestionID, Answer, Time, uuid) {
//   try {
//     console.log(ChapterID, ChapterName, QuestionID, Answer, Time, uuid)
//     const response = await sendRequest([ChapterID, ChapterName, QuestionID, Answer, Time, uuid]);
//     console.log("Response:", response);
//     alert("Submit Answer Success")
//   } catch (error) {
//     console.error("Error:", error);
//   }
// }

export async function SubmitAnswerAPI(apiUrl, ChapterID, ChapterName, QuestionID, Answer, Time, uuid) {
  const requestData = {
    chapterID: ChapterID,
    chapterName: ChapterName,
    questionID: QuestionID,
    answer: JSON.stringify(Answer),
    time: Time,
    uuid: uuid
  };

  try {
    const response = await fetch("https://mlapi.teliclab.info/resource/api/v1/insertAnswer", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    alert("Submit Answer Success")

  } catch (error) {
    alert("Submit Answer Failed. Please contact the administrator.")
    console.error('API error:', error);


  }
}


async function sendRequest(data) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ message: "Request successful" });
    }, 1000);
  });
}
