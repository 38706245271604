import React, { useRef, useEffect } from 'react'
import { useState } from 'react'
import './flatten.css'

import {
  recordAPIAction,
  SubmitAnswerAPI,
  sendRequest,
  recordAPI,
} from "../../utils/recordSubmitAPI"


const transferNum2Color = (num) => {
  let mainColor
  let alpha = 1

  if (num >= 129 && num <= 255) {
    // Blue range
    mainColor = "blue"
    alpha = (num - 129) / (255 - 129) // Transparency increases as value approaches 255
  } else {
    // Green range
    mainColor = "green"
    alpha = 1 - num / 127 // Transparency increases as value approaches 0
  }

  return mainColor === "blue" ? `rgba(0, 176, 255 , ${alpha})` : `rgba(1, 237, 1, ${alpha})`
}

function InputImageFlattenVis (props) {
  const canvasOriginalRef = useRef()
  const [flattenResult, setFlattenResult] = useState(null)

  const handleInputFileChange = (event) => {
    const file = event.target.files[0]
    props.setInputFile(file)
  }

  const generateMatrixFromImage = (image, newSize) => {
    const canvas = document.createElement('canvas')
    canvas.width = newSize
    canvas.height = newSize
    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, newSize, newSize)

    const matrix = []
    for (let y = 0; y < newSize; y++) {
      const row = []
      for (let x = 0; x < newSize; x++) {
        const pixelData = ctx.getImageData(x, y, 1, 1).data
        // 將原始像素值存入矩陣，包含紅色、綠色和藍色分量
        row.push([pixelData[0], pixelData[1], pixelData[2]])
      }
      matrix.push(row)
    }
    return matrix
  }

  const drawMatrix = (matrix, ctx, scale) => {
    const rectSize = 5 // Size of each rectangle in the drawn matrix
    ctx.clearRect(0, 0, matrix[0].length * scale, matrix.length * scale)

    matrix.forEach((row, rowIndex) => {
      row.forEach((value, colIndex) => {
        const [r, g, b] = value // 解構 RGB 值
        ctx.fillStyle = `rgb(${r}, ${g}, ${b})` // 使用 RGB 值設定填充顏色
        ctx.fillRect(colIndex * scale, rowIndex * scale, rectSize * scale, rectSize * scale)
      })
    })
  }


  const handleImageUpload = (inputFile) => {
    const file = inputFile
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const image = new Image()
        image.src = e.target.result
        image.onload = () => {
          const newSize = 18 // Set your desired size
          const resizedMatrix = generateMatrixFromImage(image, newSize)

          const canvasOriginal = canvasOriginalRef.current
          const ctxOriginal = canvasOriginal.getContext('2d')
          const scale = 5 // Scale factor for enlarging the matrix
          drawMatrix(resizedMatrix, ctxOriginal, scale)

          // 將壓平後的結果存入狀態
          setFlattenResult(resizedMatrix)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    handleImageUpload(props.inputFile)
  }, [props.inputFile])

  const handleMouseEnter = (event, rowIndex, colIndex) => {
    // Access the target element from the event object
    const element = event.target
    // Update the border color of the element
    element.style.borderColor = 'red'

    const flattenHoveredArea = document.querySelector('.flatten-hovered-area')
    flattenHoveredArea.style.display = 'block'
    flattenHoveredArea.style.top = `${rowIndex * 5}px`
    flattenHoveredArea.style.left = `${colIndex * 5}px`

  }

  const handleMouseLeave = (event) => {
    // Access the target element from the event object
    const element = event.target
    // Update the border color of the element
    element.style.borderColor = 'black'
  }




  return (
    <div className='input-image-flatten-container'>

      <input onChange={(event) => handleInputFileChange(event)} type='file' accept='image/*' />
      <div class='flatten-classes-for-relate'>
        <div className="flatten-hovered-area"></div>
        <canvas className='' ref={canvasOriginalRef} width={18 * 5} height={18 * 5} />
      </div>

      <div className='input-flatten-result'>
        {/* <div className="flatten-result-hovered-area"></div> */}
        {flattenResult && (
          <div
            className='grid-container'
          // onMouseMove={(event) => handleMouseMove(event)}
          // onMouseLeave={(event) => handleMouseLeave(event)}
          >
            {flattenResult.map((row, rowIndex) => (
              <div key={rowIndex} className='grid-row'>
                {row.map((pixel, colIndex) => (
                  <div
                    key={colIndex}
                    onMouseEnter={(event) => handleMouseEnter(event, rowIndex, colIndex)}
                    onMouseLeave={(event) => handleMouseLeave(event)}
                    className='grid-cell'
                    style={{
                      backgroundColor: `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`,
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

function RandomImageFlattenVis () {
  const [randomRegion, setRandomRegion] = useState([])
  const [hoveredPixel, setHoveredPixel] = useState(null) // 用于记录当前悬停的像素坐标

  const generateRandomRegion = (event) => {

    // recordAPIAction("flatten-random-img-simulation", event.target.id, localStorage.getItem('userUUID'))
    recordAPI("apiLink", "4-3", "Flatten", "4-3_random-img-simulation", null, Date(), localStorage.getItem('userUUID'))
    const getRandomPixelValue = () => Math.floor(Math.random() * 256) // 生成随机的 RGB 值 (0-255)

    // 生成一个 4x4 的随机 RGB 像素网格
    const region = Array.from({ length: 4 }, () =>
      Array.from({ length: 4 }, () => [getRandomPixelValue(), getRandomPixelValue(), getRandomPixelValue()])
    )

    setRandomRegion(region)
  }

  // 处理鼠标悬停事件，记录当前悬停的像素坐标
  const handlePixelHover = (rowIndex, colIndex) => {
    setHoveredPixel({ rowIndex, colIndex })
  }

  return (
    <div className='random-image-flatten-container d-flex flex-column justify-content-center align-items-center'>
      <h2>隨機生成4x4的圖片</h2>
      <button id={"flatten-gen-button"} onClick={generateRandomRegion}>生成</button>
      <div className='random-image d-flex flex-column justify-content-center align-items-center'>
        {randomRegion.map((row, rowIndex) => (
          <div key={rowIndex} className='ori-grid-row'>
            {row.map((pixel, colIndex) => (
              <div
                key={colIndex}
                className={`grid-cell ${hoveredPixel && hoveredPixel.rowIndex === rowIndex && hoveredPixel.colIndex === colIndex ? 'hovered' : ''}`}
                style={{
                  backgroundColor: `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`,
                  width: '20px', // 调整宽度和高度
                  height: '20px',
                }}
                onMouseEnter={() => handlePixelHover(rowIndex, colIndex)}
                onMouseLeave={() => setHoveredPixel(null)}
              />
            ))}
          </div>
        ))}
        <div className='w-100'>被 Flatten 的圖片</div>
      </div>
      <div className='grid-container'>
        <div className='w-100'>Flatten 的結果:</div>
        {randomRegion.map((row, rowIndex) => (
          <div key={rowIndex} className='grid-row'>
            {row.map((pixel, colIndex) => (
              <div
                key={colIndex}
                className='grid-cell'
                style={{
                  backgroundColor: `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`,
                  width: '20px', // 调整宽度和高度
                  height: '20px',
                  border: `${hoveredPixel && hoveredPixel.rowIndex === rowIndex && hoveredPixel.colIndex === colIndex ? '2px solid red' : '2px solid transparent'
                    }`, // 当前悬停的像素添加红色边框
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}


function PoolingAvgPractice (props) {
  const [flattenResult, setFlattenResult] = useState(Array.from({ length: 16 }, () => null))

  const colorArray = [
    143, 58, 208, 192,
    96, 223, 11, 173,
    189, 70, 97, 134,
    2, 174, 221, 45
  ]


  useEffect(() => {
    props.setFlattenResult(flattenResult)
  }, [flattenResult])

  // Drag and Drop function
  function dragStart (e) {
    console.log('dragStart')
    e.dataTransfer.setData('text/plain', e.target.id)
  }


  function dropped (e) {
    // recordAPIAction(
    //   "flatten-dropped",
    //   e.target.id,
    //   localStorage.getItem("userUUID")
    // )
    recordAPI("apiLink", "4-2", "Flatten", e.target.id, null, Date(), localStorage.getItem('userUUID'))

    cancelDefault(e)

    let id = e.dataTransfer.getData('text/plain').trim() // 移除可能的空白
    let draggedElement = document.getElementById(id)
    if (!draggedElement) {
      return
    }
    let targetContainer = e.target

    // 获取目标容器的索引
    let targetContainerIndex = targetContainer.id.substring(22)

    // 查找拖拽元素对应的容器索引
    let draggedElementIndex = draggedElement.id.substring(16)

    // 如果目标容器的索引和拖拽元素的索引相同，或者目标容器是 avg-pooling-process-pixels-container
    // 则将拖拽元素放置在目标容器中
    if (targetContainerIndex === draggedElementIndex || targetContainer.className.includes("avg-pooling-process-pixels-container")) {
      if (!targetContainer.contains(draggedElement)) {
        targetContainer.appendChild(draggedElement)
        console.log("targetContainerIndex", targetContainerIndex)
        console.log("draggedElementIndex", draggedElementIndex)
        setFlattenResult(prev => {
          const newArray = prev.map(id => {
            return id === draggedElementIndex ? null : id
          })
          newArray[targetContainerIndex] = draggedElementIndex // 将目标容器的索引设置为拖拽元素的索引
          return newArray // 返回更新后的数组
        })
      }
    } else {
      // 查找对应索引的容器
      let originalContainer = document.getElementById(`avgPoolingCIndex${draggedElementIndex}`)
      if (originalContainer) {
        if (!originalContainer.contains(draggedElement)) {
          originalContainer.appendChild(draggedElement)
          setFlattenResult(prev => {
            return prev.map(value => {
              return value === draggedElementIndex ? null : value
            })
          })
        }
      } else {
        console.log("Cannot find original container.")
      }
    }
  }

  function cancelDefault (e) {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const buildAvgPoolingImg = (ImgWidth, ImgHeight) => {
    let img = []
    let imgRow = []
    let index = 0
    for (let i = 0; i < ImgHeight; i++) {
      imgRow = []
      for (let j = 0; j < ImgWidth; j++) {
        index = i * ImgHeight + j
        const uniqueId = `avgPoolingPIndex${index}` // Use a consistent ID for the same element
        imgRow.push(
          <div
            className="avg-pooling-pixels-container"
            key={uniqueId}
            id={`avgPoolingCIndex${index}`}
            onDrop={dropped}
            onDragEnter={cancelDefault}
            onDragOver={cancelDefault}

            draggable="false"
          >
            <div
              id={uniqueId}
              className="avg-pooling-pixels"
              style={{ backgroundColor: transferNum2Color(colorArray[index]) }}
              draggable="true"
              onDragStart={dragStart}
            >
              {/* {index} */}
            </div>
          </div>
        )
      }
      img.push(
        <div key={`avgPooing-img-row-container-${i}`} className="avgPooing-img-row-container">
          {imgRow}
        </div>
      )
    }
    return img
  }

  const buildAvgPoolingProcess = (poolingSize, PoolingRows) => {
    let processArea = []
    for (let i = 0; i < PoolingRows; i++) {
      let processRow = []
      for (let j = 0; j < poolingSize; j++) {
        const index = i * PoolingRows + j
        const uniqueId = `avgPoolingProcessIndex${index}` // Use a consistent ID for the same element
        processRow.push(
          <div className='d-flex  align-items-center'>
            <div
              className={flattenResult[index] === `${index}` ?
                "avg-pooling-process-pixels-container p-valid-ans" : "avg-pooling-process-pixels-container p-invalid-ans"}
              key={uniqueId}
              id={uniqueId}
              onDrop={dropped}
              onDragEnter={cancelDefault}
              onDragOver={cancelDefault}
            />

          </div>
        )
      }
      processArea.push(
        <div key={`avgPooing-process-row-container-${i}`} className='d-flex align-items-center justify-content-center'>
          <div className={`avgPooing-process-row-container process-${i} d-flex`}>
            {processRow}
          </div>

        </div>
      )
    }
    return processArea
  }





  return (
    <div className='pooling-practice-container '>
      <div className='avgPooling-img m-3 d-flex flex-column justify-content-center  align-items-center'>
        {buildAvgPoolingImg(4, 4)}
        <div className='w-100'>被Flatten圖片</div>
      </div>
      <div className='avgPooling-process-area m-3 d-flex flex-column justify-content-center align-items-center'>
        <div className='d-flex'>

          {buildAvgPoolingProcess(4, 4)}
        </div>
        <div className='w-100'>Flatten結果</div>
      </div>

    </div>
  )
}


function CH4_3FlattenSection () {

  const [flattenResult, setFlattenResult] = useState(null)


  return (
    <>
      <div className='title-container'>
        <div className='title'>Flatten Layer</div>
      </div>
      <div className="bullet-point"> • 模擬說明</div>
      {/* <InputImageFlattenVis
        inputFile={inputFile}
        setInputFile={setInputFile}
        onFlattenResult={handleFlattenResult} /> */}
      <div className="w-100 d-flex justify-content-center align-items-center">

        <RandomImageFlattenVis>

        </RandomImageFlattenVis>
      </div>

      <div className='question-section'>
        <div className='sub-bullet-point'> • 練習問題</div>
        <div className='question-container'>
          <div className='question-container'>
            <div className='question-title'>請根據 flatten 的原理，將下方的圖片像素格子拖拉到合適的位置。Flatten 操作將原始圖片的像素格子重新排列成一维向量，以便進行後續的處理。
              <button

                onClick={() => SubmitAnswerAPI(
                  "apiLink",
                  "4-3",
                  "Flatten",
                  "1",
                  flattenResult,
                  Date(),
                  localStorage.getItem('userUUID'))}
              >提交</button>
            </div>
            <PoolingAvgPractice
              flattenResult={flattenResult}
              setFlattenResult={setFlattenResult}
            ></PoolingAvgPractice>
          </div>
        </div>
      </div>
    </>
  )
}



export default CH4_3FlattenSection
