// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-valid-ans{
  border: 1px solid green;
}
.p-invalid-ans{
  border: 1px solid red;
}

/* Input Image Flatten Visulization */
.input-image-flatten-container{
  width: 100%;
  height: 200px;
  background-color: white;
}
.input-flatten-result{
  height: 5px;
}

.grid-container{
  display: flex;
}
.grid-row{
  display: flex;
}
.grid-cell{
  width: 5px;
  height: 5px;
  /* border: 1px solid black; */
}
.flatten-classes-for-relate{
  position: relative;
}


.flatten-hovered-area,
.flatten-result-hovered-area{
  position: absolute;
  width: 5px;
  height: 5px;
  border: 0.5px solid red;
}

/* Random Flatten Visualization */

.random-image-flatten-container{
  width: 100%;
  height: 200px;
  background-color: white;
}

.ori-grid-row{
  display: flex;
}
.grid-cell{
  /* margin: 1px; */
}

.grid-cell:hover {
  border: 2px solid red; /* 鼠标悬停时边框变红 */
}



`, "",{"version":3,"sources":["webpack://./src/pages/ch4/flatten.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,qBAAqB;AACvB;;AAEA,qCAAqC;AACrC;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;AAC/B;AACA;EACE,kBAAkB;AACpB;;;AAGA;;EAEE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,uBAAuB;AACzB;;AAEA,iCAAiC;;AAEjC;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB,EAAE,cAAc;AACvC","sourcesContent":[".p-valid-ans{\n  border: 1px solid green;\n}\n.p-invalid-ans{\n  border: 1px solid red;\n}\n\n/* Input Image Flatten Visulization */\n.input-image-flatten-container{\n  width: 100%;\n  height: 200px;\n  background-color: white;\n}\n.input-flatten-result{\n  height: 5px;\n}\n\n.grid-container{\n  display: flex;\n}\n.grid-row{\n  display: flex;\n}\n.grid-cell{\n  width: 5px;\n  height: 5px;\n  /* border: 1px solid black; */\n}\n.flatten-classes-for-relate{\n  position: relative;\n}\n\n\n.flatten-hovered-area,\n.flatten-result-hovered-area{\n  position: absolute;\n  width: 5px;\n  height: 5px;\n  border: 0.5px solid red;\n}\n\n/* Random Flatten Visualization */\n\n.random-image-flatten-container{\n  width: 100%;\n  height: 200px;\n  background-color: white;\n}\n\n.ori-grid-row{\n  display: flex;\n}\n.grid-cell{\n  /* margin: 1px; */\n}\n\n.grid-cell:hover {\n  border: 2px solid red; /* 鼠标悬停时边框变红 */\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
