import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import './NeuralNetwork.css'
import * as d3 from 'd3'
import { debounce } from 'lodash';

import linear_data from './training data for vis/regression_linear_data.json'
import non_linear_data from './training data for vis/regression_non-linear_data.json'

import ch2_linearTask_linearAF from '../../model_data/ch2/ch2_linearTask_linearAF.json'
import ch2_linearTask_reluAF from '../../model_data/ch2/ch2_linearTask_reluAF.json'

import ch2_nonLinearTask_linearAF from '../../model_data/ch2/ch2_nonLinearTask_linearAF.json'
import ch2_nonLinearTask_reluAF from '../../model_data/ch2/ch2_nonLinearTask_reluAF.json'

import { recordAPIAction, SubmitAnswerAPI, recordAPI } from '../../utils/recordSubmitAPI'

function generatePythonCode (width, depth) {
  if (width < 1 || depth < 1) {
    return "Invalid input. Width and depth must be greater than or equal to 1."
  }

  let pythonCode = "import tensorflow as tf\n"
  pythonCode += "import numpy as np\n\n"

  pythonCode += "# Load Data...\n\n\n"

  pythonCode += "x_data = ...\n"
  pythonCode += "y_data = ...\n"
  pythonCode += "# Convert data to NumPy arrays\n"
  pythonCode += "x_data = np.array(x_data).astype(float)\n"
  pythonCode += "y_data = np.array(y_data).astype(float)\n\n"

  pythonCode += "epochs = ...\n"
  pythonCode += "model = tf.keras.Sequential([\n"
  pythonCode += "  tf.keras.layers.Input(shape=(1,)),\n"

  for (let i = 0; i < depth; i++) {
    pythonCode += `  tf.keras.layers.Dense(${width}, activation='relu'),\n`
  }

  pythonCode += "  tf.keras.layers.Dense(1)\n"
  pythonCode += "])\n\n"
  pythonCode += "model.compile(optimizer='adam', loss='mean_squared_error')\n\n"

  pythonCode += "model.fit(x=x_data, y=y_data, epochs=epochs)\n"

  return pythonCode
}




// async function sendRequest (id, uuid) {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve({ message: "Request successful" })
//     }, 1000)
//   })
// }


function ConditionControlPanel (props) {
  const [networkStructure, setNetworkStruture] = useState([
    {
      "AF": 0,
      "depth": 2,
      "width": 2,
      "epochs": 1
    }
  ])
  const [task_functionType, set_task_functionType] = useState([
    {
      "taskType": 0,
      "functionType": "0"
    }
  ])

  const optionContainerGenerator = (fieldName, optionList, networkOrTask, elementName) => {
    const handleSelectChange = (event) => {
      
      let newValue = parseInt(event.target.value)

      //Exception of epochs control
      if (elementName === "epochs") {  
        //1, 10, 20...
        if (newValue === 0) {
          newValue = 1
        }
        else if(newValue === 11){
          newValue = 10
        }
        //1-5000 RANGE >5000 TO 5000
        if (task_functionType[0]["functionType"] === 0) {

          if (newValue >= 5000) {
            newValue = 5000
          }
        }
        
      }
      else {
        // recordAPIAction("SelectionOnChangeRecord", elementName, localStorage.getItem('userUUID'))
        recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "3-1", "ActivationFunction", elementName, newValue, Date(), localStorage.getItem('userUUID'))
      }

      if (networkOrTask === "TF") { //task＆function類型
        const newTaskFunctionType = [...task_functionType]
        newTaskFunctionType[0][elementName] = parseInt(newValue)
        set_task_functionType(newTaskFunctionType)
        props.get_task_functionType(newTaskFunctionType)
      }
      else if (networkOrTask === "NS") { // networkStructure的類型
        const newNetworkStructure = [...networkStructure]
        newNetworkStructure[0][elementName] = parseInt(newValue)
        setNetworkStruture(newNetworkStructure)
        props.getNetworkStruture(newNetworkStructure)
      }
    }

    return (
      <div className="option-container">
        {/* 欄位名稱 */}
        <div className='field-name'>
          {
            elementName !== "epochs" ?
              fieldName : `${fieldName} : ${networkStructure[0]["epochs"]}`
          }
        </div>
        <div className='select-option-container'>
          {
            // 非Epocsh選項
            elementName !== "epochs" ?
              <select
                onChange={handleSelectChange}
                defaultValue={0}
                className='condition-selet-box'
                // onMouseUp={(event)=>recordAPIAction("SelectionOnChangeRecord", elementName, localStorage.getItem('userUUID'))}
              >
              {
                optionList.map((option, index) => (
                  <option value={index} key={index}>
                    {option}
                  </option>
                ))
              }
              </select> : 
              // Epochs選項
              <input
                type="range"
                min="0"
                max={task_functionType[0]["functionType"] === "0" ? "5000" : "10000"}
                step="10"
                defaultValue={0}
                onChange={handleSelectChange}
                onMouseUp={(event)=> recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "3-1", "ActivationFunction", elementName, event.target.value, Date(), localStorage.getItem('userUUID'))}
                // onMouseUp={(event) => recordAPIAction("SelectionOnChangeRecord", elementName, localStorage.getItem('userUUID'))}

                className='condition-selet-box'
              />
          }
        </div>
      </div>
    )
  }

  // const handleExportCodeClick = () => {
  //   // 在这里生成 Python 代码，使用当前的 networkStructure 和 task_functionType

  //   const pythonCode = generatePythonCode(
  //     props.layers[1],
  //     props.layers.length - 2,
  //     networkStructure[0].epochs,
  //     task_functionType[0].functionType
  //   )

  //   // 创建 Python 文件 Blob 对象
  //   const blob = new Blob([pythonCode], { type: 'text/plain' })

  //   // 创建一个下载链接
  //   const url = URL.createObjectURL(blob)
  //   const a = document.createElement('a')
  //   a.href = url
  //   a.download = 'generated_model.py'
  //   a.click()
  //   URL.revokeObjectURL(url)
  // }
  return (
    <>
      <div className="condition-control-panel-container d-flex justify-content-center">
        {optionContainerGenerator("任務", ["任務1(線性)", "任務2(非線性)"], "TF", "functionType")}
        {optionContainerGenerator("激活函數", ["linear", "relu"], "NS", "AF")}
        {/* {optionContainerGenerator("隱藏層深度", ["1", "2", "3", "4"], "NS", "depth")}
          {optionContainerGenerator("隱藏層寬度", ["1", "2", "3", "4"], "NS", "width")} */}
        {optionContainerGenerator("Epochs", ["1", "2", "3", "5", "10", "50", "100", "5000"], "NS", "epochs")}
        {/* <button onClick={handleExportCodeClick}>匯出程式碼</button> */}
      </div>

    </>



  )
}


function NetworkControlPanel(props) {
  const initLayers = [
    {
      id: uuid(),
      layerID: 1,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1, 2], null, null, null, null, null, null, null, null, null],
      biasList: [1, null, null, null, null, null, null, null, null, null],
      reluActive: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: true
    },
    {
      id: uuid(),
      layerID: 2,
      numberOfNeural: 2,
      inputDisable: [false, false, true, true, true, true, true, true, true, true],
      weightList: [[3], [1], null, null, null, null, null, null, null, null, null],
      biasList: [1, 2, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 3,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1], null, null, null, null, null, null, null, null, null],
      biasList: [1, 1, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 1, 0, 0, 0, 0, 0, 0],
      inOutputLayer: true
    }
  ]

  const [layers, setLayers] = useState(initLayers)
  useEffect(() => {
    let weights
    let activationFunctionList = ["linear", "relu"]
    let dataTypeList = ["linear", "non-linear"]

    const epochs = props.networkStructure[0]["epochs"]
    const activationFunction = activationFunctionList[props.networkStructure[0]["AF"]]
    const dataType = dataTypeList[props.task_functionType[0]["functionType"]]

    if (dataType === "linear") {
      if (activationFunction === "linear") {
        weights = ch2_linearTask_linearAF
      }
      else if (activationFunction === "relu") {
        weights = ch2_linearTask_reluAF
      }
      setLayers(getLayersFormJson(weights[`epoch_${epochs}`], 4))
    }
    else if (dataType === "non-linear") {
      if (activationFunction === "relu") {
        weights = ch2_nonLinearTask_reluAF
      }
      else if (activationFunction === "linear") {

        weights = ch2_nonLinearTask_linearAF
      }
      setLayers(getLayersFormJson(weights[`epoch_${epochs}`], activationFunction === "linear" ? 4 : 4))
    }
  }, [props.networkStructure, props.task_functionType])

  useEffect(() => {

    updateFormula(layers, props.networkStructure[0]["AF"])
    props.updateLayers(layers.map(layer => (layer.numberOfNeural)))
  }, [layers, props.networkStructure])

  const getLayersFormJson = (data, layerLength) => {
    let newLayer = []
    const inputList = [0]

    for (let layerNum = 0; layerNum < layerLength + 1; layerNum++) {
      let layer = data[`layer${layerNum}`]
      let prevLayer = data[`layer${layerNum - 1}`]

      let layerNeuralNumber = layer ? layer["weights"].length : 1

      let weightList = layerNum === layerLength ? [[1]] : layer["weights"]
      weightList = [...weightList, ...Array(10 - layerNeuralNumber).fill(null)]

      let biasList = layerNum === 0 ? inputList : prevLayer["bias"]
      biasList = [...biasList, ...Array(10 - layerNeuralNumber).fill(null)]


      newLayer.push({
        id: uuid(),
        layerID: layerNum + 1,
        numberOfNeural: layerNeuralNumber,
        inputDisable: Array(10).fill(true).map((value, index) => index > layerNeuralNumber - 1),
        weightList: weightList,
        biasList: biasList,
        reluActive: Array(10).fill(null).fill(1, 0, layerNeuralNumber),
        inOutputLayer: layerNum === layerLength ? true : false

      })

    }
    // console.log("newLayer", newLayer)
    return newLayer

  }


  const updateFormula = (newLayer, AF) => {
    const weightList = newLayer.map(item => (item.weightList))
    const biasList = newLayer.map(item => (item.biasList))
    const numberOfNeuralList = newLayer.map(item => (item.numberOfNeural))
    
    const listLength = weightList.length
    let resultList = Array.from({ length: 10 }, () => Array.from({ length: 8 }, () => ""))


    for (let layer = 0; layer < listLength - 1; layer++) {
      for (let n_index = 0; n_index < numberOfNeuralList[layer + 1]; n_index++) {
        if (layer === 0) {
          for (let prev_n_index = 0; prev_n_index < numberOfNeuralList[layer]; prev_n_index++) {
            resultList[layer + 1][n_index] += `(${weightList[layer][prev_n_index][n_index]}) * x + `
          }
          resultList[layer + 1][n_index] += `${biasList[layer + 1][n_index]}`
          if (AF == 1) {
            resultList[layer + 1][n_index] = `(Math.max(0, ${resultList[layer + 1][n_index]}))`

          }
          else if (AF == 2) {
            resultList[layer + 1][n_index] = `1 / (1 + Math.exp(-${resultList[layer + 1][n_index]}))`
          }
        }
        else {
          const numOfneuralOflayer = numberOfNeuralList[layer] - 1
          for (let prev_n_index = 0; prev_n_index < numOfneuralOflayer; prev_n_index++) {
            resultList[layer + 1][n_index] += `(${weightList[layer][prev_n_index][n_index]}) * (${resultList[layer][prev_n_index]}) + `
          }
          resultList[layer + 1][n_index] += `(${weightList[layer][numOfneuralOflayer][n_index]}) * (${resultList[layer][numOfneuralOflayer]}) + (${biasList[layer + 1][n_index]})`
          if (AF == 1) {
            resultList[layer + 1][n_index] = `(Math.max(0, ${resultList[layer + 1][n_index]}))`
          }
          else if (AF == 2) {
            resultList[layer + 1][n_index] = `1 / (1 + Math.exp(-${resultList[layer + 1][n_index]}))`
          }
        }
      }
    }

    props.updateFormula(resultList[listLength - 1][0])

  }

  // const deleteLayer = (id) => {
  //   let removeId
  //   let tempW
  //   const updatedLayers = layers.filter((item, index) => {
  //     if (item.id === id) {
  //       removeId = index
  //       return false
  //     }
  //     return true
  //   })
  //   const prevExtractedLayer = layers[removeId - 1]
  //   const diff = layers[removeId + 1].numberOfNeural - layers[removeId].numberOfNeural

  //   if (prevExtractedLayer) {
  //     if (diff < 0) {
  //       prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
  //         if (weights !== null) {
  //           return weights.slice(0, diff)
  //         }
  //         return null
  //       })
  //     }
  //     else {
  //       prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
  //         if (weights !== null) {
  //           return [...weights, ...Array(diff).fill(1)]
  //         }
  //         return null
  //       })
  //     }
  //   }


  //   const updatedLayersWithIDs = updatedLayers.map((item, index) => ({
  //     ...item,
  //     layerID: index + 1
  //   }))

  //   setLayers(updatedLayersWithIDs)
  // }


  // const addLayer = () => {
  //   const newID = layers.length
  //   const newLayer = {
  //     id: uuid(),
  //     layerID: newID,
  //     numberOfNeural: 1,
  //     inputDisable: [false, true, true, true, true, true, true, true, true, true],
  //     weightList: [[1], null, null, null, null, null, null, null, null, null],
  //     biasList: [1, 1, null, null, null, null, null, null, null, null],
  //     reluActive: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
  //     inOutputLayer: false
  //   }


  //   const lastLayer = layers[layers.length - 1]
  //   if (lastLayer) {
  //     lastLayer.layerID = layers.length + 1
  //   }


  //   setLayers((prevLayer => {
  //     const newLayers = [...layers.slice(0, layers.length - 1), newLayer, lastLayer]
  //     return newLayers
  //   }))

  // }

  const generateLayer = (item, prevId) => {

    const handleWeightInputChange = (id, neuralID, weightID, event) => {
      let newValue = event.target.value

      if (newValue !== '' && (newValue < -10)) {
        newValue = -10
      }
      else if (newValue > 10) {
        newValue = 10
      }
      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {

        extractedLayer.weightList = extractedLayer.weightList.map((nerual, index) => {

          if (index === neuralID) {
            return nerual.map((weight, wIndex) => wIndex === weightID ? newValue : weight)
          }
          else {
            return nerual
          }
        })

      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))


      setLayers(updatedLayers)

    }

    const handleBiasInputChange = (id, BiasID, event) => {
      let newValue = (event.target.value)

      if (newValue !== '' && (newValue < -10)) {
        newValue = -10
      }
      else if (newValue > 10) {
        newValue = 10
      }

      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {
        extractedLayer.biasList = extractedLayer.biasList.map((bias, index) =>
          index === BiasID ? newValue : bias)
      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))
      // updateFormula(updatedLayers)
      setLayers(updatedLayers)

    }

    // const handleActivationEvent = (id, actID, event) => {
    //   const value = parseInt(event.target.value)

    //   const extractedLayer = layers.find((layer) => layer.id === id)
    //   if (extractedLayer) {
    //     extractedLayer.reluActive = extractedLayer.reluActive.map((act, index) =>
    //       index === actID ? value : act
    //     )
    //   }

    //   const updatedLayers = layers.map((layer) => (layer.id === id ? extractedLayer : layer))
    //   // updateFormula(updatedLayers);
    //   setLayers(updatedLayers)
    // }



    const renderParaInputs = (item, prevId, layerID) => {
      const Inputs = []
      let nerualWeights = []

      const currExtractedLayer = layers.find(layer => layer.id === item.id)
      const prevExtractedLayer = layers.find(layer => layer.id === prevId)


      for (let i = 1; i <= 8; i++) {

        if (item.weightList[i - 1] != null) {
          if (item.layerID != 1) {
            nerualWeights.push(
              <>
                <div className='d-flex'>

                  <div className={`text layer${layerID}${i}B`}>B<sub>{layerID + 1}{i}</sub>:</div>
                  < input
                    className='bias-input'
                    type='number'
                    min='-10'
                    max='10'
                    step='0.05'
                    placeholder={`bias${i}`}
                    value={(currExtractedLayer.biasList[i - 1])}

                    onChange={(event) => handleBiasInputChange(prevExtractedLayer.id, i - 1, event)}
                    disabled={true}
                  />
                </div>
              </>
            )
          }
          nerualWeights.push(
            <div
              className={`neural layer${layerID + 1}N n_id${layerID + 1}${i}`}
            >
              N
              <sub>
                {layerID + 1}{i}
              </sub>
            </div>
          )
          for (let index = 1; index <= item.weightList[i - 1].length; index++) {
            nerualWeights.push(
              <div className='weight-container'>
                <div className={`weight${layerID + 1}${i}${index}`}>W<sub>{layerID + 1}{i}{index}</sub>:</div>
                <input
                  className='weight-input'
                  type='number'
                  min='-10'
                  max='10'
                  step='0.05'
                  placeholder={`nerual${i}`}
                  value={item.inputDisable[i - 1] ? `${i}` : item.weightList[i - 1][index - 1].toFixed(4)}
                  onChange={(event) => handleWeightInputChange(item.id, i - 1, index - 1, event)}
                  // disabled={item.inputDisable[i - 1]}
                  disabled={true}
                />
              </div>
            )
          }
        }

        Inputs.push(
          <div className='control-input-container'>
            {nerualWeights}
          </div>

        )
        nerualWeights = []
      }
      return Inputs
    }

    // const handleNumberOfNeuralInputChange = (id, prevId, event) => {


    //   let newValue = event.target.value
    //   if (newValue !== '' && (newValue < 1)) {
    //     newValue = 1
    //   }
    //   else if (newValue > 10) {
    //     newValue = 10
    //   }

    //   const prevExtractedLayer = layers.find(layer => layer.id === prevId)
    //   const extractedLayer = layers.find(layer => layer.id === id)
    //   const nextLayerIndex = prevExtractedLayer.layerID + 1

    //   // 對上一層每一個neural增加一個weight
    //   // console.log("prevExtractedLayer", prevExtractedLayer)
    //   if (prevExtractedLayer) {
    //     prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {


    //       if (weights !== null) {
    //         if (newValue < extractedLayer.numberOfNeural) {
    //           return weights.slice(0, -1)
    //         } else {
    //           return [...weights, 1]
    //         }
    //       } else {
    //         return weights
    //       }
    //     })
    //   }

    //   // 對該層新增一個weight

    //   if (extractedLayer) {
    //     extractedLayer.numberOfNeural = newValue
    //     extractedLayer.weightList = extractedLayer.weightList.map((weight, index) => index < newValue && weight === null ?
    //       Array.from({
    //         length: layers[nextLayerIndex].numberOfNeural
    //       }, () => 1)
    //       : weight)
    //     extractedLayer.biasList = extractedLayer.biasList.map((bias, index) => index < newValue && bias === null ? 1 : bias)
    //     extractedLayer.inputDisable = Array(10).fill(true).map((value, index) => index > newValue - 1)
    //   }
    //   // const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))

    //   // 更新整個layer
    //   const updatedLayers = layers.map((layer, index) => {
    //     if (layer.id === id) {
    //       return extractedLayer
    //     } else if (index > 0 && layers[index - 1].id === prevId) {
    //       return prevExtractedLayer
    //     } else {
    //       return layer
    //     }
    //   })

    //   setLayers(updatedLayers)


    // }

    return (
      <div key={item.id} className='layer-container'>
        <div className='layer-input-container'>
          <div>第 {item.layerID} 層: </div>
          <input
            type='number'
            className='weight-input-box'
            id={`layerInput${item.layerID}`}
            min='1'
            max='10'
            placeholder='number of neural'
            value={item.numberOfNeural}
            // onChange={(event) => handleNumberOfNeuralInputChange(item.id, prevId, event)}
            disabled={true}
  
          />


        </div>
        <div className='weights-bias-container'>
          {renderParaInputs(item, prevId, item.layerID - 1)}
        </div>
 

      </div>
    )
  }


  // let previousItem = null
  return (
    <div className='network-control-panel-container'>
      {
        <>
          {layers.map((item, index, array) => (
            generateLayer(item, index > 0 ? array[index - 1].id : null)
          ))}
        </>
      }

    </div>
  )
}





function MathGraph (props) {
  const svgRef = useRef(null)
  const debouncedRecordAPIActionRef = useRef(null);

  // Initialize zoom state and set it as a state variable
  const [zoomState, setZoomState] = useState(d3.zoomIdentity)


  const parseFormula = (formula) => {
    try {
      const parsedFunction = new Function("x", `return ${formula}`)
      return parsedFunction
    } catch (error) {
      console.error('Formula parsing error:', error)
      return (x) => 0
    }
  }
  // useEffect(() => {
  //   const debouncedRecordAPIAction = debounce(() => {

  //   }, 1000);
  //   debouncedRecordAPIActionRef.current = debouncedRecordAPIAction;
  // }, []);



  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const width = +svg.attr('width')
    const height = +svg.attr('height')
    const formula = parseFormula(props.formula)
    
    const debouncedRecordAPIAction = debouncedRecordAPIActionRef.current;

    const linearRange = {
      x: [17, 37],
      y: [40, 0]
    }
    const nonLinearRange = {
      x: [0, 60],
      y: [1000, -100]
    }
    let linear_points;
    let VisRange = {};

    if (props.task_functionType[0]["functionType"] === 0) {
      linear_points = linear_data["points"]
      VisRange = linearRange
    }
    else {
      linear_points = non_linear_data["points"]
      VisRange = nonLinearRange
    }
    // const linear_points = props.task_functionType[0]["functionType"] === 0 ? linear_data["points"] : non_linear_data["points"]
    // console.log("linear_points", linear_points)

    svg.selectAll('*').remove()
    // linear x: 17~37 y: -15~45


    const x = d3.scaleLinear()
      .domain(VisRange["x"]) // 将 x 范围从 -20 到 20
      .range([-1, width + 1])

    const y = d3.scaleLinear()
      .domain(VisRange["y"]) // 将 y 范围从 -10 到 10
      .range([-1, height + 1])

    const xAxis = d3.axisBottom(x)
      .ticks(((width + 2) / (height + 2)) * 10)
      .tickSize(height)
      .tickPadding(-15)

    const yAxis = d3.axisRight(y)
      .ticks(10)
      .tickSize(width)
      .tickPadding(8 - width)

    const lineGenerator = d3
      .line()
      .x(d => x(d.x))
      .y(d => y(d.y))

    const gX = svg.append("g")
      .attr("class", "axis axis--x")
      .call(xAxis)


    // 降低X轴格子线的透明度
    gX.selectAll("line")
      .style("opacity", 0.2) // 设置透明度，0.5表示50%不透明

    const gY = svg.append("g")
      .attr("class", "axis axis--y")
      .call(yAxis)



    // 降低Y轴格子线的透明度
    gY.selectAll("line")
      .style("opacity", 0.2) // 设置透明度，0.5表示50%不透明

    const pathF = svg.append("g")
      .append('path')
      .datum(d3.range(-1500, 1500).map((x) => ({ x, y: formula(x) })))
      .attr('fill', 'none')
      .attr('stroke', 'blue')
      .attr('stroke-width', 2)
      .attr('d', lineGenerator)
    // .style("opacity", 0)

    const points = svg.selectAll('circle')
      .data(linear_points.filter(d => d.y >= 0))
      .enter()
      .append('circle')
      .attr('cx', (d) => x(d["x"]))
      .attr('cy', (d) => y(d["y"]))
      .attr('r', 2); // 调整数据点的半径
  



    // const debouncedRecordAPIAction = debounce(() => {
      
    //   recordAPIAction("MathGraph","id", localStorage.getItem('userUUID'))
    // }, 1000);
    
    const zoom = d3.zoom()
      .scaleExtent([1, 5])
      .filter(filter)
      // .on("zoom", zoomed);

    // Restore the saved zoom state
    svg.call(zoom.transform, zoomState)

    function zoomed (event) {
      const { transform } = event;
      let zooming = true;
      gX.call(xAxis.scale(transform.rescaleX(x)));
      gY.call(yAxis.scale(transform.rescaleY(y)));
    
      // 保持格子线透明度不变
      gX.selectAll("line")
        .style("opacity", 0.2);
      gY.selectAll("line")
        .style("opacity", 0.2);
    
      // xLable.attr("transform", transform)
      pathF.attr("stroke-width", 2 / transform.k)
        .attr("transform", transform);
      points.attr("r", 2 / transform.k)
        .attr("transform", transform);
      // Save the current zoom state
      setZoomState(transform);
      
      debouncedRecordAPIAction();
    
    }


    function filter (event) {
      event.preventDefault()
      return (!event.ctrlKey || event.type === 'wheel') && !event.button
    }

    svg.call(zoom)
    
  }, [props.formula, props.task_functionType])

  return (
    <div>
      <div className='network-label-container'

      >
        <div className='y-label'>{props.task_functionType[0]["functionType"] === 0 ? "體脂率(%)" : "每月醫療費用"}</div>
        <svg
          className='math-network-graph' 
      
          ref={svgRef}
          onMouseUp={()=>console.log("fsad")}
          width={1000}
          height={500}
          style={{ border: '1px solid gray' }}
        ></svg>
      </div>
      <div className='x-label'>BMI</div>
    </div>
  )
}



function NetworkVisualization (props) {

  const svgRef = useRef(null)



  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const width = +svg.attr('width')
    const height = +svg.attr('height')
    let layerSizes = [...props.layers]


    // 每层的神经元数量

    const neuronRadius = 15 // 神经元半径（调整大小）
    const layerGap = 60 // 层之间的间距
    const neuronGap = 50 // 神经元之间的间距

    // 创建一个容器组，并将容器置中
    svg.selectAll('*').remove() // 清空SVG内容
    const container = svg.append('g')
      .attr('transform', `translate(${(width / 2 - (layerSizes.length * neuronGap))}, ${height / 2})`)


    // 计算每层的水平位置
    const layerXPositions = []
    // let xPosition = -((layerSizes.length - 1) * layerGap) / 2;
    let xPosition = 0

    layerSizes.forEach((size) => {
      layerXPositions.push(xPosition)
      xPosition += size * (neuronRadius * 1 + neuronGap / 2) + layerGap
    })


    // 绘制神经元和连接线
    for (let i = 0; i < layerSizes.length; i++) {
      const layerSize = layerSizes[i]
      const x = layerXPositions[i]
      const yStart = -(layerSize / 2) * (neuronRadius * 2 + neuronGap)




      // 绘制每一层的神经元

      for (let j = 0; j < layerSize; j++) {
        const y = yStart + j * (neuronRadius * 2 + neuronGap)
        // 绘制神经元圆
        if (j === 0) {
          container
            .append('text')
            .attr('x', x)
            .attr('y', y - 20) // 调整标签的垂直位置
            .attr('text-anchor', 'middle') // 文本居中对齐
            .attr('fill', 'black') // 可选，设置文本颜色
            .text(() => {
              if (i === 0) {
                return '輸入層'
              } else if (i === layerSizes.length - 1) {
                return '輸出層'
              } else {
                return '隱藏層'
              }
            })


        }

        container
          .append('circle')
          .attr('cx', x)
          .attr('cy', y)
          .attr('r', neuronRadius)
          .attr('fill', 'lightblue')

        if ((j != 0 || j !== layerSize - 1) && props.networkStructure[0]["AF"] == 1) {
          container
            .append('line')
            .attr('x1', x)
            .attr('y1', y + 3)
            .attr('x2', x - 10)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
          container
            .append('line')
            .attr('x1', x + 5)
            .attr('y1', y - 8 + 3)
            .attr('x2', x)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
        }
        else if ((j != 0 || j !== layerSize - 1) && props.networkStructure[0]["AF"] == 2) {
          // const sPath = "M-5,0 C-5,10 5,10 5,0 Q5,-10 -5,-10 Q-5, -5 -5,0 Z"

          // 定义 S 形状的路径
          const sPath = (x, y) => `M${x - 10} ${y + 10} C${x - 6} ${y + 10}, ${x - 4} ${y + 10}, ${x} ${y} S${x + 5} ${y - 10}, ${x + 10} ${y - 10}`

          // 添加 S 形状的路径
          container
            .append("path")
            .attr("d", sPath(x, y))
            .attr("stroke", 'black')
            .attr("fill", "none")


        }

      }



      if (i < layerSizes.length - 1) {
        const nextLayerSize = layerSizes[i + 1]
        const nextX = layerXPositions[i + 1]
        for (let j = 0; j < layerSize; j++) {
          const startY = yStart + j * (neuronRadius * 2 + neuronGap)
          for (let k = 0; k < nextLayerSize; k++) {

            const endY = yStart + k * (neuronRadius * 2 + neuronGap) - (nextLayerSize - layerSize) * (neuronGap / 2 + neuronRadius)
            // 绘制连接线
            container
              .append('line')
              .attr('x1', x + neuronRadius)
              .attr('y1', startY)
              .attr('x2', nextX - neuronRadius)
              .attr('y2', endY)
              .attr('stroke', 'gray')
              .attr('stroke-width', 2)
          }
        }
      }
    }
  }, [props.layers, props.networkStructure])

  return (
    <svg
      className='network-graph'
      ref={svgRef}
      width={1000}
      height={500}
      style={{ border: '1px solid gray' }}
    ></svg>
  )


}


function PracticeArea() {
  const [answers, setAnswers] = useState({
    question1: '',
    question2: '',
    question3: '',
    question4: ''
  });

  const handleOptionChange = (questionNumber, event) => {
    setAnswers({ ...answers, [questionNumber]: event.target.value });
  };

  const handleSubmitAPI = async (questionNumber) => {
    try {
      if (window.confirm("確定要提交嗎？")) {
        const response = await SubmitAnswerAPI(
          "http://localhost:8080/resource/api/v1/insertAnswer",
          "3-1",
          "activation function",
          "1",
          answers[questionNumber],
          Date(),
          localStorage.getItem('userUUID'))
        // const response = await SubmitAnswerAPI(localStorage.getItem("userUUID"), answers[questionNumber]);
        // 处理 API 响应
      }
    } catch (error) {
      console.error('Error:', error);
    }
    
  };

  return (
    <div className='question-section'>
      <div className='sub-bullet-point'> • 練習問題</div>
      <div className='question-container'>
        <div className='question-title'>1.在任務1中，哪個激活函數的表現更好？</div>
        <div className="optimization-options">
          <label>
            <input
              type="radio"
              name="question1"
              value="Linear"
              onChange={(event) => handleOptionChange('question1', event)}
            />
            Linear
          </label>
          <label>
            <input
              type="radio"
              name="question1"
              value="ReLU"
              onChange={(event) => handleOptionChange('question1', event)}
            />
            ReLU
          </label>
        </div>
        <button onClick={() => handleSubmitAPI('question1')}>提交</button>
      </div>
      <div className='question-container'>
        <div className='question-title'>2.在任務2中，哪個激活函數的表現更好？</div>
        <div className="optimization-options">
          <label>
            <input
              type="radio"
              name="question2"
              value="Linear"
              onChange={(event) => handleOptionChange('question2', event)}
            />
            Linear
          </label>
          <label>
            <input
              type="radio"
              name="question2"
              value="ReLU"
              onChange={(event) => handleOptionChange('question2', event)}
            />
            ReLU
          </label>
        </div>
        <button onClick={() => handleSubmitAPI('question2')}>提交</button>
      </div>
      <div className='question-container'>
        <div className='question-title'>3.在處理複雜且非線性的任務時，選擇哪個激活函數較為適合？</div>
        <div className="optimization-options">
          <label>
            <input
              type="radio"
              name="question3"
              value="Linear"
              onChange={(event) => handleOptionChange('question3', event)}
            />
            Linear
          </label>
          <label>
            <input
              type="radio"
              name="question3"
              value="ReLU"
              onChange={(event) => handleOptionChange('question3', event)}
            />
            ReLU
          </label>
        </div>
        <button onClick={() => handleSubmitAPI('question3')}>提交</button>
      </div>
      <div className='question-container'>
        <div className='question-title'>4. 承上題，請簡短解釋你認為該激活函數較為適合的原因。</div>
        <textarea
          className='input-ans-area'
          value={answers.question4}
          onChange={(event) => setAnswers({ ...answers, question4: event.target.value })}
        />
        <button onClick={() => handleSubmitAPI('question4')}>提交</button>
      </div>
    </div>    
  );
}

// export default PracticeArea;



function Ch3_1ActivationFunction () {
  const [formula, setFormula] = useState("Math.max(0, x + 1)")
  const [layers, setLayers] = useState([1, 2, 2, 1])
  const [networkStructure, setNetworkStruture] = useState([
    {
      "AF": 0,
      "depth": 2,
      "width": 2,
      "epochs": 1
    }
  ])
  const [task_functionType, set_task_functionType] = useState([
    {
      "taskType": 0,
      "functionType": 0
    }
  ])

  const updateFormula = (f) => {
    setFormula(f)
  }

  const updateLayers = (f) => {

    setLayers(f)
  }
  const get_task_functionType = (data) => {
    set_task_functionType(data)
  }

  const getNetworkStruture = (data) => {


    setNetworkStruture(data)

  }

  return (
    <>
      <div>
        <div className='title-container'>
          <div className='title'>激活函數</div>
          <div className='subtitle'>Activation Function</div>
        </div>
        <div className="bullet-point"> • 模擬說明</div>
        <div className='simulation-description'>小海狸為了處理兩個任務: (1)體脂率預測 (2)醫療費用預測，訓練了一些不同的參數的神經網路。
          小海狸在訓練神經網路時，發現不同的激活函數會影響神經網路的表現，試操作下方模擬看看到底對神經網路的表現有什麼影響吧！完成後請回答下方的練習問題。<br />
          任務1(體脂率預測): 利用BMI作為輸入預測體脂率 <br />
          任務2(醫療費用預測): 利用BMI作為輸入預測醫療費用
        </div>
        <ConditionControlPanel
          get_task_functionType={get_task_functionType}
          getNetworkStruture={getNetworkStruture}
          layers={layers}
        >
        </ConditionControlPanel>

        <div className='c-container'>

          <div className="control-panel-section">
            {/* <DescriptionArea></DescriptionArea> */}

            <NetworkControlPanel
              networkStructure={networkStructure}
              task_functionType={task_functionType}
              updateFormula={updateFormula}
              updateLayers={updateLayers}
            ></NetworkControlPanel>
          </div>
          <div className='graph-container'>
            <MathGraph
              formula={formula}
              task_functionType={task_functionType}
            ></MathGraph>
            <NetworkVisualization
              layers={layers}
              networkStructure={networkStructure}
            ></NetworkVisualization>
          </div>
        </div>
    
      </div>
      <PracticeArea></PracticeArea>
    </>

  )
}

export default Ch3_1ActivationFunction