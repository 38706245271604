import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import './neural_network_formula.css'
import * as d3 from 'd3'
import { zoom, select, event } from 'd3'
import model1WeightJson from '../../model_data/ch1/ch1-neural_netwrok_model1.json'

import { recordAPIAction, SubmitAnswerAPI, recordAPI } from '../../utils/recordSubmitAPI'

function generateDenseLayer (sizeList, activation, optimizer) {
  recordAPI("generate_code", "generate_code", "gen_code", "", Date(), localStorage.getItem('userUUID'))
  const inputShape = sizeList[0]
  const lowerCaseActivation = activation.toLowerCase()
  
  const layers = sizeList.map((size, index) => {
    if (index === 0) {
      return `tf.keras.layers.InputLayer(input_shape=(${inputShape},))`
    } else {
      return `tf.keras.layers.Dense(${size}, activation='${lowerCaseActivation}')`
    }
  })
  const modelCode = `#import tensorflow as tf\nmodel = tf.keras.Sequential([\n  ${layers.join(',\n  ')}\n])\n\n` +
    `model.compile(optimizer=tf.keras.optimizers.${optimizer}(), loss=tf.keras.losses.MeanSquaredError())\n\n` +
    `model.summary()`

  // Create a Blob object
  const blob = new Blob([modelCode], { type: 'application/json' })

  // Create a download link
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = 'model.py'

  // Append the link to the body
  document.body.appendChild(link)

  // Trigger the download
  link.click()

  // Remove the link from the body
  document.body.removeChild(link)
}


function generatePythonCode (width, depth) {
  if (width < 1 || depth < 1) {
    return "Invalid input. Width and depth must be greater than or equal to 1."
  }

  let pythonCode = "import tensorflow as tf\n"
  pythonCode += "import numpy as np\n\n"

  pythonCode += "# Load Data...\n\n\n"

  pythonCode += "x_data = ...\n"
  pythonCode += "y_data = ...\n"
  pythonCode += "# Convert data to NumPy arrays\n"
  pythonCode += "x_data = np.array(x_data).astype(float)\n"
  pythonCode += "y_data = np.array(y_data).astype(float)\n\n"

  pythonCode += "epochs = ...\n"
  pythonCode += "model = tf.keras.Sequential([\n"
  pythonCode += "  tf.keras.layers.Input(shape=(1,)),\n"

  for (let i = 0; i < depth; i++) {
    pythonCode += `  tf.keras.layers.Dense(${width}, activation='relu'),\n`
  }

  pythonCode += "  tf.keras.layers.Dense(1)\n"
  pythonCode += "])\n\n"
  pythonCode += "model.compile(optimizer='adam', loss='mean_squared_error')\n\n"

  pythonCode += "model.fit(x=x_data, y=y_data, epochs=epochs)\n"

  return pythonCode
}




function NetworkControlPanel (props) {

  const [clickedNeural, setClickedNeural] = useState([])
  const [prevClickedNeural, setPrevClickedNeural] = useState([])
  const [clickedBiases, setClickedBiases] = useState([])
  const [clickedWeights, setClickedWeights] = useState([])

  //初始值
  const initLayers = [
    {
      id: uuid(),
      layerID: 1,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1, 2], null, null, null, null, null, null, null, null, null],
      biasList: [1, null, null, null, null, null, null, null, null, null],
      reluActive: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 2,
      numberOfNeural: 2,
      inputDisable: [false, false, true, true, true, true, true, true, true, true],
      weightList: [[3], [1], null, null, null, null, null, null, null, null, null],
      biasList: [1, 1, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 3,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1], null, null, null, null, null, null, null, null, null],
      biasList: [1, null, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 1, 0, 0, 0, 0, 0, 0],
      inOutputLayer: true
    }
  ]
  const getLayersFormJson = (data, layerLength) => {
    let newLayer = []
    const inputList = [170.73, 70.35, 60, 0]

    for (let layerNum = 0; layerNum < layerLength + 1; layerNum++) {
      let layer = data[`layer${layerNum}`]
      let prevLayer = data[`layer${layerNum - 1}`]

      let layerNeuralNumber = layer ? layer["weights"].length : 1

      let weightList = layerNum === layerLength ? [[1]] : layer["weights"]
      weightList = [...weightList, ...Array(20 - layerNeuralNumber).fill(null)]

      let biasList = layerNum === 0 ? inputList : prevLayer["bias"]
      biasList = [...biasList, ...Array(20 - layerNeuralNumber).fill(null)]


      newLayer.push({
        id: uuid(),
        layerID: layerNum + 1,
        numberOfNeural: layerNeuralNumber,
        inputDisable: Array(20).fill(true).map((value, index) => index > layerNeuralNumber - 1),
        weightList: weightList,
        biasList: biasList,
        reluActive: Array(20).fill(null).fill(1, 0, layerNeuralNumber),
        inOutputLayer: layerNum === layerLength ? true : false

      })

    }
    return newLayer

  }


  const [layers, setLayers] = useState(getLayersFormJson(model1WeightJson[`epoch_1`], 2))
  const [selectedModelID, setSelectedModelID] = useState(1)
  const [model1Process, setModel1Process] = useState(1)
  const [model2Process, setModel2Process] = useState(1)








  const modelSelectOnClick = (id) => {

    setSelectedModelID(id)
    if (id === 1) {
      setLayers(getLayersFormJson(model1WeightJson[`epoch_${model1Process}`], 2))
    }
    else if (id === 2) {
      setLayers(getLayersFormJson(model1WeightJson[`epoch_${model2Process}`], 2))

    }

  }



  const handleModelProcessChange = (value, item_id) => {
    // recordAPIAction("ModelOnchangeRecordAPI",  item_id, localStorage.getItem('userUUID'))
    recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "2-3", "Neural_Network_Practice", item_id, value, Date(), localStorage.getItem("userUUID"))
    setModel1Process(value)
    setLayers(getLayersFormJson(model1WeightJson[`epoch_${value}`], 2))
  }










  useEffect(() => {

    // updateFormula(layers)
    props.updateLayers(layers.map(layer => (layer.numberOfNeural)))


  }, [layers, props.networkStructure])




  const deleteLayer = (id) => {
    recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "generate_code",
      "generate_code", "deleteLayer", null, Date(), localStorage.getItem("userUUID"))
    let removeId
    const updatedLayers = layers.filter((item, index) => {
      if (item.id === id) {
        removeId = index
        return false
      }
      return true
    })
    const prevExtractedLayer = layers[removeId - 1]
    const diff = layers[removeId + 1].numberOfNeural - layers[removeId].numberOfNeural

    if (prevExtractedLayer) {
      if (diff < 0) {
        prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
          if (weights !== null) {
            return weights.slice(0, diff)
          }
          return null
        })
      }
      else {
        prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {
          if (weights !== null) {
            return [...weights, ...Array(diff).fill(1)]
          }
          return null
        })
      }
    }


    const updatedLayersWithIDs = updatedLayers.map((item, index) => ({
      ...item,
      layerID: index + 1
    }))

    setLayers(updatedLayersWithIDs)
  }


  const addLayer = () => {
    recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "generate_code",
      "generate_code", "addLayer", null, Date(), localStorage.getItem("userUUID"))
    
    const newID = layers.length
    if (newID >= 8) {
      return
    }
    const newLayer = {
      id: uuid(),
      layerID: newID,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1], null, null, null, null, null, null, null, null, null],
      biasList: [1, null, null, null, null, null, null, null, null, null],
      reluActive: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    }


    const lastLayer = layers[layers.length - 1]
    if (lastLayer) {
      lastLayer.layerID = layers.length + 1
    }


    setLayers((prevLayer => {
      const newLayers = [...layers.slice(0, layers.length - 1), newLayer, lastLayer]
      return newLayers
    }))

  }

  const generateLayer = (item, prevId) => {






    // 生成每一個神經元所連接的權重和偏值
    const renderParaInputs = (item, prevId, layerID) => {
      const Inputs = []
      let nerualWeights = []
      let weightsContainer = []
      const currNumberOfNeural = parseInt(layers[layerID].numberOfNeural, 20)

      const currExtractedLayer = layers.find(layer => layer.id === item.id)



      for (let i = 1; i <= currNumberOfNeural; i++) {
        if (item.weightList[i - 1] != null) {
          if (item.layerID != 1) {
            nerualWeights.push(
              <>
                <div className={`text layer${layerID}${i}B ${clickedBiases.includes(`.layer${layerID}${i}B`) ? 'onclick-prev-neural' : ''}`}>--B<sub>{layerID + 1}{i}</sub>--</div>
                {/* < input
                  className='ch1-1-bias-input'
                  type='number'
                  min='0'
                  max='200'
                  step='0.5'
                  placeholder={`bias${i}`}
                  value={(currExtractedLayer.biasList[i - 1])}
                  onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                  disabled={true}
                /> */}

              </>
            )
          }
          else {
            // Input Layer
            nerualWeights.push(
              <div>
                <div className={`text layer${layerID}${i}B`}>
                  {/* {i === 1 && "身高:"}
                  {i === 2 && "體重:"}
                  {i === 3 && "年齡:"}
                  {i === 4 && "性別:"} */}
                  input{i}
                </div>
                {/*性別只能選擇1 或 0 */}
                {/* {(i === 4) ? (
                  <select
                    className='ch1-1-network-input'
                    // id={`ch2-3_network-input${i}`}
                    value={currExtractedLayer.biasList[i - 1]}
                    onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                    onMouseUp={(event) => recordAPI(
                      "http://localhost:8080/resource/api/v1/insertRecord",
                      "2-3",
                      "Loss_Function",
                      event.target.id,
                      null,
                      Date(),
                      localStorage.getItem("userUUID"))
                    }
                    disabled={false}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </select>
                ) :
                  // 非性別選項可以填入數字
                  (
                    <input
                      className='ch1-1-network-input'
                      id={`ch2-3_network-input${i}`}
                      type='number'
                      min='0'
                      max='200'
                      step='0.05'
                      value={currExtractedLayer.biasList[i - 1]}
                      onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                      onMouseUp={(event) => recordAPI(
                        "http://localhost:8080/resource/api/v1/insertRecord",
                        "2-3",
                        "Loss_Function",
                        event.target.id,
                        null,
                        new Date(),
                        localStorage.getItem("userUUID"))
                      }
                      placeholder={`input ${i}`}
                      disabled={false}
                    />
                  )} */}

              </div>
            )
          }

          nerualWeights.push(
            <div
              className={`neural layer${layerID + 1}N n_id${layerID + 1}${i} 
                ${prevClickedNeural.includes(`.layer${layerID + 1}N`) ? 'onclick-prev-neural' : ''}
                ${clickedNeural && clickedNeural.layerID === layerID + 1 && clickedNeural.neuralID === i ? 'onclick-prev-neural' : ''}`}
              // onClick={(event) => handleNeuralOnClick(i, layerID, event.target.id)}
              id={`ch2-3_neural${layerID + 1}${i}`}
            >
              N
              <sub>
                {layerID + 1}{i}
              </sub>
            </div>
          )
          for (let index = 1; index <= item.weightList[i - 1].length; index++) {
            weightsContainer.push(

              <div className='weight-container' >
                {
                  // 如果是output layer，則顯示--Output
                  item.inOutputLayer && item.layerID > 1 ?
                    <>
                      <div className={`weight${layerID + 1}${i}${index}`}>--Output<sub>{i}</sub>--</div>
                    </>
                    :
                    <div className={`weight${layerID + 1}${i}${index}
                    ${clickedWeights.includes(`.weight${layerID + 1}${i}${index}`) ? 'onclick-prev-neural' : ''}
                    `}>--W<sub>{layerID + 1}{i}{index}</sub>--</div>
                }

                {/* <input
                  className='ch1-1-weight-input'
                  type='number'
                  min='-100'
                  max='100'
                  step='0.05'
                  placeholder={`neural${i}`}
                  value={(item.inOutputLayer && item.layerID > 1 ? (props.formulaOutput.toFixed(1)) :
                    item.inputDisable[i - 1] ? `${i}` : (item.weightList[i - 1][index - 1]).toFixed(1))}
                  onChange={(event) => handleWeightInputChange(item.id, i - 1, index - 1, event)}
                  disabled={true}
                /> */}

              </div>
            )
          }
        }

        Inputs.push(
          <div className='ch1-1-control-input-container' key={`ch1-1-control-input-container${i}`}>
            {nerualWeights.map((element) => (
              <div key={element.id}>{element}</div>
            ))}
            <div className='weights-container' key={`weights-container${i}`}>
              {weightsContainer.map((element) => (
                <div key={element.id}>{element}</div>
              ))}
            </div>
          </div>
        )

        nerualWeights = []
        weightsContainer = []
      }
      return Inputs
    }

    const handleNumberOfNeuralInputChange = (id, prevId, event) => {

      //因為要更新整個layer，所以先把所有的onclick-prev-neural class移除
      recordAPI("http://localhost:8080/resource/api/v1/insertRecord",
        "generate_code",
        "generate_code",
        event.target.id,
        event.target.value,
        Date(),
        localStorage.getItem("userUUID"))
      const elements = document.querySelectorAll('.onclick-prev-neural')

      elements.forEach(element => {
        // Remove the class "onclick-prev-neural" from each element
        element.classList.remove('onclick-prev-neural')
      })

      //同時把singleNeuralFormula清空
      props.setSingleNeuralFormula("")

      let newValue = parseInt(event.target.value)

      if (isNaN(newValue) || newValue < 1) {
        newValue = 1
      } else if (newValue >= 21) {
        newValue = 21
        return

      }




      const prevExtractedLayer = layers.find(layer => layer.id === prevId)
      const extractedLayer = layers.find(layer => layer.id === id)
      const nextLayerIndex = prevId ? prevExtractedLayer.layerID + 1 : 1
      // let N_weights = Math.abs(newValue - prevExtractedLayer.numberOfNeural);


      // 對上一層每一個neural增加N個weight

      if (prevExtractedLayer) {

        prevExtractedLayer.weightList = prevExtractedLayer.weightList.map((weights, index) => {

          if (weights !== null) {
            if (newValue < extractedLayer.numberOfNeural) {
              return weights.slice(0, newValue)
            } else {
              return [...Array(newValue).fill(1)]
            }
          } else {
            return weights
          }
        })

      }

      // 對該層新增一個weight

      if (extractedLayer) {


        extractedLayer.numberOfNeural = newValue

        // 對該層新增N個weight
        extractedLayer.weightList = extractedLayer.weightList.map((weight, index) => index < newValue && weight === null ?
          Array.from({
            length: layers[nextLayerIndex].numberOfNeural
          }, () => 1)
          : weight)

        // 對該層新增一個bias

        extractedLayer.biasList = extractedLayer.biasList.map((bias, index) => index < newValue && bias === null ? 1 : bias)


        extractedLayer.inputDisable = Array(20).fill(true).map((value, index) => index > newValue - 1)

      }

      // 更新整個layer
      const updatedLayers = layers.map((layer, index) => {

        if (layer.id === id) {
          return extractedLayer
        } else if (index > 0 && layers[index - 1].id === prevId) {
          return prevExtractedLayer
        } else {
          return layer
        }
      })
      setLayers(updatedLayers)
      console.log("updatedLayers", updatedLayers)

    }





    return (
      <div key={item.id} className='ch1-1-layer-container'>
        <div className='layer-input-container'>
          <div>第 {item.layerID} 層: </div>
          <input
            type='number'
            className='weight-input-box'
            id={`layerInput${item.layerID}`}
            min='1'
            max='20'
            placeholder='number of neural'
            value={item.numberOfNeural}
            onChange={(event) => handleNumberOfNeuralInputChange(item.id, prevId, event)}
            //Block all input
            // disabled={true}
            disabled={item.inOutputLayer}
          />


        </div>
        <div className='ch1-1-weights-bias-container'>
          {renderParaInputs(item, prevId, item.layerID - 1)}
        </div>
        <button onClick={() => deleteLayer(
          item.id

        )}>刪除</button>

      </div>
    )
  }



  return (
    <>
      {/* <div className="model-select-container bg-white">
        <div className='font-weight-bold p-2 d-flex justify-content-center m-2 rounded border border-5 p-2'>
          <div className=
            {selectedModelID === 1 ?
              'model-selected m-2 p-2' :
              'm-2 p-2'
            }
          >
            <div className='ch1-model1'
              onClick={() => modelSelectOnClick(1)}
            >
              模型
            </div>
            <select
              className="input-box"
              id='2-3_model-epochs-select'
              disabled={selectedModelID !== 1}
              onChange={(event) => handleModelProcessChange(event.target.value, event.target.id)}
            >
              <option value="1">1 epoch</option>
              <option value="50">50 epochs</option>
              <option value="100">100 epochs</option>
              <option value="150">150 epochs</option>
              <option value="200">200 epochs</option>
              <option value="250">250 epochs</option>
            </select>

          </div>
          <button className="m-2 rounded p-2 border-0" onClick={() => Reset2InitLayer()}>自訂模型</button>
        </div>
      </div> */}

      <div className='ch1-1-control-panel-section'>
        <div>
          <div>激活函數:</div>
          <select value={props.activation} onChange={(event) => {
            props.setActivation(event.target.value)
            recordAPI("http://localhost:8080/resource/api/v1/insertRecord",
              "generate_code",
              "generate_code",
              "activation",
              event.target.value,
              Date(),
              localStorage.getItem("userUUID"))
          }}>
            <option value="Linear">Linear</option>
            <option value="ReLU">ReLU</option>
            <option value="Sigmoid">Sigmoid</option>
          </select>
          <div>
            <div>優化器:</div>
            <select value={props.optimizer} onChange={(event) => {
              props.setOptimizer(event.target.value)
              recordAPI("http://localhost:8080/resource/api/v1/insertRecord",
                "generate_code",
                "generate_code",
                "optimizer",
                event.target.value,
                Date(),
                localStorage.getItem("userUUID"))
            }}>
              <option value="SGD">SGD</option>
              <option value="Adam">Adam</option>
              <option value="RMSprop">RMSprop</option>
            </select>
          </div>
          
        </div>
        {
          layers.map((item, index, array) => (
            generateLayer(item, index > 0 ? array[index - 1].id : null)
          ))
        }
        <button onClick={addLayer}>新增一層</button>
      </div>

    </>

  )
}




function NetworkVisualization (props) {

  const svgRef = useRef(null)
  const [container, setContainer] = useState(null)

  useEffect(() => {
    const svg = select(svgRef.current)

    const zoomHandler = zoom()
      .scaleExtent([0.1, 10]) // Set zoom range
      .on('zoom', (event) => {
        container.attr('transform', event.transform)
      })

    svg.call(zoomHandler)

    return () => {
      svg.on('.zoom', null) // Remove event listener
    }
  }, [container])

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const width = +svg.attr('width')
    const height = +svg.attr('height')
    let layerSizes = [...props.layers]

    // 每层的神经元数量
    const neuronRadius = 15 // 神经元半径（调整大小） 
    const layerGap = 40 // 层之间的间距
    const neuronGap = 25 // 神经元之间的间距

    // 创建一个容器组，并将容器置中
    svg.selectAll('*').remove() // 清空SVG内容
    const container = svg.append('g')
      .attr('transform', `translate(${(width / 2 - (layerSizes.length * neuronGap)) - 300}, ${height / 2 + 50} )`)
    setContainer(container)


    // 计算每层的水平位置
    const layerXPositions = []
    // let xPosition = -((layerSizes.length - 1) * layerGap) / 2;
    let xPosition = 0

    layerSizes.forEach((size) => {
      layerXPositions.push(xPosition)
      xPosition += size * (neuronRadius * 1 + neuronGap / 2) + layerGap
    })


    // 绘制神经元和连接线
    for (let i = 0; i < layerSizes.length; i++) {
      const layerSize = layerSizes[i]
      const x = layerXPositions[i]
      const yStart = -(layerSize / 2) * (neuronRadius * 2 + neuronGap)




      // 绘制每一层的神经元

      for (let j = 0; j < layerSize; j++) {
        const y = yStart + j * (neuronRadius * 2 + neuronGap)
        // 绘制神经元圆
        if (j === 0) {
          container
            .append('text')
            .attr('x', x)
            .attr('y', y - 20) // 调整标签的垂直位置
            .attr('text-anchor', 'middle') // 文本居中对齐
            .attr('fill', 'black') // 可选，设置文本颜色
            .text(() => {
              if (i === 0) {
                return '輸入層'
              } else if (i === layerSizes.length - 1) {
                return '輸出層'
              } else {
                return '隱藏層'
              }
            })


        }

        container
          .append('circle')
          .attr('cx', x)
          .attr('cy', y)
          .attr('r', neuronRadius)
          .attr('fill', 'lightblue')
        console.log(props.activation)

        if (i != 0 && props.activation == "ReLU") {

          container
            .append('line')
            .attr('x1', x)
            .attr('y1', y + 3)
            .attr('x2', x - 10)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
          container
            .append('line')
            .attr('x1', x + 5)
            .attr('y1', y - 8 + 3)
            .attr('x2', x)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
        }
        else if ((i != 0) && props.activation === "Sigmoid") {
          // const sPath = "M-5,0 C-5,10 5,10 5,0 Q5,-10 -5,-10 Q-5, -5 -5,0 Z"

          // 定义 S 形状的路径
          const sPath = (x, y) => `M${x - 10} ${y + 10} C${x - 6} ${y + 10}, ${x - 4} ${y + 10}, ${x} ${y} S${x + 5} ${y - 10}, ${x + 10} ${y - 10}`

          // 添加 S 形状的路径
          container
            .append("path")
            .attr("d", sPath(x, y))
            .attr("stroke", 'black')
            .attr("fill", "none")


        }

      }



      if (i < layerSizes.length - 1) {
        const nextLayerSize = layerSizes[i + 1]
        const nextX = layerXPositions[i + 1]
        for (let j = 0; j < layerSize; j++) {
          const startY = yStart + j * (neuronRadius * 2 + neuronGap)
          for (let k = 0; k < nextLayerSize; k++) {

            const endY = yStart + k * (neuronRadius * 2 + neuronGap) - (nextLayerSize - layerSize) * (neuronGap / 2 + neuronRadius)
            // 绘制连接线
            container
              .append('line')
              .attr('x1', x + neuronRadius)
              .attr('y1', startY)
              .attr('x2', nextX - neuronRadius)
              .attr('y2', endY)
              .attr('stroke', 'gray')
              .attr('stroke-width', 2)
          }
        }
      }
    }
    // console.log("graphChange")
  }, [props.layers, props.networkStructure, props.activation])

  return (
    <svg
      className='gen-code-network-graph'
      ref={svgRef}
      width="1800"
      height="800"
      style={{ background: 'rgba(128, 128, 128, 0.1)' }}
    ></svg>

  )


}


function FormulaArea (props) {


  return (
    <div className="formula-container">
      <div className="formula" dangerouslySetInnerHTML={{ __html: props.singleNeuralFormula }}></div>
    </div>
  )
}


function GenerateTensorflowCode () {
  // const [formula, setFormula] = useState("Math.max(0, x + 1)")
  const [formulaOutput, setFormulaOutput] = useState(5)
  const [layers, setLayers] = useState([1, 2, 2, 1])
  const [activation, setActivation] = useState("ReLU")
  const [optimizer, setOptimizer] = useState("Adam")
  const [singleNeuralFormula, setSingleNeuralFormula] = useState("")
  const [networkStructure, setNetworkStruture] = useState([
    {
      "AF": 1,
      "depth": 1,
      "width": 1,
      "epochs": 2
    }
  ])

  const [q1Ans, setq1Ans] = useState({
    "1": "",
    "2": ""
  })
  const [q2Ans, setq2Ans] = useState({
    "1": "",
    "2": "",
    "3": "",
    "4": "",
  })


  return (
    <>
      <div>
        <div className='title-container'>
          <div className='title'>基礎練習</div>
          <div className='subtitle'>Neural Network Practice</div>
        </div>
        <div className="bullet-point"> • 模擬說明</div>
        <div className='simulation-description'>
          小海狸目前已經訓練完好一個訓練模型，這些模型用於預測體脂率。為了更深入暸解神經網路的構造和原理，他打算觀察不同階段的Epochs的模型有什麼區別。請嘗試調整不同的訓練階段，觀察模型的變化，並回答相關的練習問題。除此之外，您還可以選擇自訂模型觀察模型整體的變化。
        </div>

        <div className='ch1-1-c-container'>
          <div className='w-100 d-flex justify-content-center  align-items-center'>

            <button
              className='ch1-1-generate-code-button m-2 d-flex justify-content-center w-50 align-items-center'
              onClick={() => generateDenseLayer(layers, activation, optimizer)}>
              匯出程式碼
            </button>
          </div>

          <div className="">
            <NetworkControlPanel
              // networkStructure={networkStructure}
              // task_functionType={task_functionType}
              // updateFormula={setFormula}
              updateLayers={setLayers}
              setSingleNeuralFormula={setSingleNeuralFormula}
              formulaOutput={formulaOutput}
              activation={activation}
              setActivation={setActivation}
              setFormulaOutput={setFormulaOutput}
              optimizer={optimizer}
              setOptimizer={setOptimizer}
            ></NetworkControlPanel>
          </div>

        </div>
      </div>
      <div className='ch1-1-visulization-container'>
        <div>
          <div className='sub-bullet-point'> • 神經網路架構</div>
          <NetworkVisualization
            layers={layers}
            activation={activation}
            optimizer={optimizer} 
            networkStructure={networkStructure}
          ></NetworkVisualization>
        </div>
      </div>
    </>

  )
}

export default GenerateTensorflowCode