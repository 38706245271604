import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import './loss_function.css'
import * as d3 from 'd3'
import { useCallback } from 'react'
import { recordAPIAction, SubmitAnswerAPI, recordAPI } from '../../utils/recordSubmitAPI'



function NetworkControlPanel (props) {

  const [clickedNeural, setClickedNeural] = useState([])
  const [prevClickedNeural, setPrevClickedNeural] = useState([])
  const [clickedBiases, setClickedBiases] = useState([])
  const [clickedWeights, setClickedWeights] = useState([])
  const [layers, setLayers] = useState([
    {
      id: uuid(),
      layerID: 1,
      numberOfNeural: 4,
      inputDisable: [false, false, false, false, true, true, true, true, true, true],
      weightList: [
        [-0.59799993, -0.37269622, 0.46365443, 0.08975877],
        [-0.4373828, 0.2784637, 0.10311716, 0.10455135],
        [0.7490775, -0.04052863, 0.33612952, -0.35342735],
        [1.5339521, -2.0837278, 0.7424698, 1.9593663],

        null, null, null, null, null, null],
      biasList: [170.7, 70.4, 60, 0, null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 2,
      numberOfNeural: 4,
      inputDisable: [false, false, false, false, true, true, true, true, true, true],
      weightList: [
        [-0.96086866],
        [1.3353832],
        [-0.3317529],
        [-3.2085323],
        null, null, null, null, null, null, null],
      biasList: [-7.898557, 8.012515, -8.217629, -8.022755, null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: false
    },
    {
      id: uuid(),
      layerID: 3,
      numberOfNeural: 1,
      inputDisable: [false, true, true, true, true, true, true, true, true, true],
      weightList: [[1], null, null, null, null, null, null, null, null, null],
      biasList: [7.9377675, null, null, null, null, null, null, null, null, null],
      reluActive: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      inOutputLayer: true
    }
  ])




  useEffect(() => {

    updateFormula(layers)
    props.updateLayers(layers.map(layer => (layer.numberOfNeural)))


  }, [layers, props.networkStructure])


  const updateFormula = (newLayer) => {
    const weightList = newLayer.map(item => (item.weightList))
    const biasList = newLayer.map(item => (item.biasList))




    const numberOfNeuralList = newLayer.map(item => (item.numberOfNeural))
    const listLength = weightList.length



    let resultList = Array.from({ length: 10 }, () => Array.from({ length: 8 }, () => ""))


    for (let layer = 0; layer < listLength - 1; layer++) {
      for (let n_index = 0; n_index < numberOfNeuralList[layer + 1]; n_index++) {
        if (layer === 0) {
          for (let prev_n_index = 0; prev_n_index < numberOfNeuralList[layer]; prev_n_index++) {
            resultList[layer + 1][n_index] += `(${weightList[layer][prev_n_index][n_index]}) * x${prev_n_index} + `
          }
          resultList[layer + 1][n_index] += `(${biasList[layer + 1][n_index]})`
        }
        else {
          const numOfneuralOflayer = numberOfNeuralList[layer] - 1
          for (let prev_n_index = 0; prev_n_index < numOfneuralOflayer; prev_n_index++) {
            resultList[layer + 1][n_index] += `(${weightList[layer][prev_n_index][n_index]}) * (${resultList[layer][prev_n_index]}) + `
          }
          resultList[layer + 1][n_index] += `(${weightList[layer][numOfneuralOflayer][n_index]}) * (${resultList[layer][numOfneuralOflayer]}) + (${biasList[layer + 1][n_index]})`

        }
      }
    }

    const math = require('mathjs')
    const result = math.simplify(resultList[listLength - 1][0],
      {
        x0: parseFloat(layers[0].biasList[0]),
        x1: parseFloat(layers[0].biasList[1]),
        x2: parseFloat(layers[0].biasList[2]),
        x3: parseFloat(layers[0].biasList[3]),
        x4: parseFloat(layers[0].biasList[4]),
        x5: parseFloat(layers[0].biasList[5]),
        x6: parseFloat(layers[0].biasList[6]),
        x7: parseFloat(layers[0].biasList[7]),
        x8: parseFloat(layers[0].biasList[8]),
        x9: parseFloat(layers[0].biasList[9]),

      })

    props.setFormulaOutput(parseFloat(eval(result.toString())))
    // props.updateFormula(resultList[listLength - 1][0])
  }


  const generateLayer = (item, prevId) => {



    const handleWeightInputChange = (id, neuralID, weightID, event) => {
      let newValue = event.target.value

      if (newValue !== '' && (newValue < -10)) {
        newValue = -10
      }
      else if (newValue > 10) {
        newValue = 10
      }

      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {

        extractedLayer.weightList = extractedLayer.weightList.map((nerual, index) => {

          if (index === neuralID) {
            return nerual.map((weight, wIndex) => wIndex === weightID ? newValue : weight)
          }
          else {
            return nerual
          }
        })

      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))


      setLayers(updatedLayers)

    }

    const handleBiasInputChange = (id, BiasID, event) => {

      let newValue = (event.target.value)

      // 限制在0～200
      if (newValue !== '' && (newValue < 0)) {
        newValue = 0
      }
      else if (newValue > 200) {
        newValue = 200
      }

      const extractedLayer = layers.find(layer => layer.id === id)
      if (extractedLayer) {
        extractedLayer.biasList = extractedLayer.biasList.map((bias, index) =>
          index === BiasID ? newValue : bias)
      }
      const updatedLayers = layers.map(layer => (layer.id === id ? extractedLayer : layer))
      setLayers(updatedLayers)

    }


    // 處理點擊神經元時的事件(顯示公式)
    const handleNeuralOnClick = (neuralID, layerID, item_id) => {



      recordAPI("http://localhost:8080/resource/api/v1/insertRecord", "2-1", "Loss_Function", item_id, null, Date(), localStorage.getItem("userUUID"))



      if (layerID === 0) {
        const clickedElement = document.querySelector(`.layer${layerID + 1}N.n_id${layerID + 1}${neuralID}`)
        clickedElement.classList.add('onclick-prev-neural')

        props.setSingleNeuralFormula(`<div class="layer${layerID + 1}N">N<sub>${layerID + 1}</sub><sub>${neuralID}</sub>= </div> <div>input1</div> `)

      }
      else {

        const prevLayerID = layerID - 1
        const prevNumberOfNeural = parseInt(layers[prevLayerID].numberOfNeural, 10)
        let onclickWeights = []
        let singleNeuralFormula = `<div class="layer${layerID + 1}N">N<sub>${layerID + 1}</sub><sub>${neuralID}</sub>= </div>  `

        for (let i = 0; i < prevNumberOfNeural - 1; i++) {
          singleNeuralFormula += `<div class="layer${layerID}N">N<sub>${prevLayerID + 1}${i + 1}</sub> * W<sub>${prevLayerID + 1}${i + 1}${neuralID}</sub> + <br></div>`
          onclickWeights.push(document.querySelector(`.weight${prevLayerID + 1}${i + 1}${neuralID}`))
        }

        singleNeuralFormula += `<div class="layer${layerID}N">N<sub>${prevLayerID + 1}${prevNumberOfNeural}</sub> * W<sub>${prevLayerID + 1}${prevNumberOfNeural}${neuralID}</sub> + <br>
                              </div><div class="layer${layerID + 1}N">B<sub>${prevLayerID + 2}${neuralID}</sub></div>`
        onclickWeights.push(document.querySelector(`.weight${prevLayerID + 1}${prevNumberOfNeural}${neuralID}`))



        // // Remove the class "onclick-prev-neural" from all elements
        // const elements = document.querySelectorAll('.onclick-prev-neural')
        // elements.forEach(element => {
        //   element.classList.remove('onclick-prev-neural')
        // })

        // // Add the class "onclick-prev-neural" to the clicked element
        // const clickedElementT = document.querySelector(`.layer${layerID + 1}N.n_id${layerID + 1}${neuralID}`)
        // clickedElementT.classList.add('onclick-prev-neural')

        // // Add the class "onclick-prev-neural" to all elements with class `layer${prevLayerID + 1}N`
        // const targetElements = document.querySelectorAll(`.layer${prevLayerID + 1}N`)
        // const targetBias = document.querySelectorAll(`.layer${prevLayerID + 1}${neuralID}B`)

        // targetBias.forEach(element => {
        //   if (!element.classList.contains('onclick-prev-neural')) {
        //     element.classList.add('onclick-prev-neural')
        //   }
        // })
        // targetElements.forEach(element => {
        //   if (!element.classList.contains('onclick-prev-neural')) {
        //     element.classList.add('onclick-prev-neural')
        //   }
        // })

        // onclickWeights.forEach(element => {
        //   if (!element.classList.contains('onclick-prev-neural')) {
        //     element.classList.add('onclick-prev-neural')
        //   }
        // })

        const newPrevClickedNeural = [`.layer${prevLayerID + 1}N`]
        const newClickedBiases = [`.layer${prevLayerID + 1}${neuralID}B`]
        setClickedBiases(newClickedBiases)
        setPrevClickedNeural(newPrevClickedNeural)

        const newClickedWeights = []
        for (let i = 0; i < prevNumberOfNeural + 1; i++) {
          newClickedWeights.push(`.weight${prevLayerID + 1}${i}${neuralID}`)
        }
        setClickedWeights(newClickedWeights)


        setClickedNeural({ layerID: layerID + 1, neuralID })
        props.setSingleNeuralFormula(singleNeuralFormula)
      }

    }

    // 生成每一個神經元所連接的權重和偏值
    const renderParaInputs = (item, prevId, layerID) => {
      const Inputs = []
      let nerualWeights = []
      let weightsContainer = []
      const currNumberOfNeural = parseInt(layers[layerID].numberOfNeural, 10)

      const currExtractedLayer = layers.find(layer => layer.id === item.id)



      for (let i = 1; i <= currNumberOfNeural; i++) {
        if (item.weightList[i - 1] != null) {
          if (item.layerID != 1) {
            nerualWeights.push(
              <>
                <div className={`text layer${layerID}${i}B ${clickedBiases.includes(`.layer${layerID}${i}B`) ? 'onclick-prev-neural' : ''}`}>B<sub>{layerID + 1}{i}</sub>:</div>
                < input
                  className='ch1-1-bias-input'
                  type='number'
                  min='0'
                  max='200'
                  step='0.5'
                  placeholder={`bias${i}`}
                  value={(currExtractedLayer.biasList[i - 1])}
                  onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                  disabled={true}
                />

              </>
            )
          }
          else {
            // Input Layer
            nerualWeights.push(
              <div>
                <div className={`text layer${layerID}${i}B`}>
                  {i === 1 && "身高:"}
                  {i === 2 && "體重:"}
                  {i === 3 && "年齡:"}
                  {i === 4 && "性別:"}
                </div>
                {/*性別只能選擇1 或 0 */}
                {(i === 4) ? (
                  <select
                    className='ch1-1-network-input'
                    // id={`ch2-1_network-input${i}`}
                    value={currExtractedLayer.biasList[i - 1]}
                    onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                    onMouseUp={(event) => recordAPI(
                      "http://localhost:8080/resource/api/v1/insertRecord",
                      "2-1",
                      "Loss_Function",
                      event.target.id,
                      null,
                      Date(),
                      localStorage.getItem("userUUID"))
                    }
                    disabled={false}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </select>
                ) :
                  // 非性別選項可以填入數字
                  (
                    <input
                      className='ch1-1-network-input'
                      id={`ch2-1_network-input${i}`}
                      
                      type='number'
                      min='0'
                      max='200'
                      step='0.05'
                      value={currExtractedLayer.biasList[i - 1]}
                      onChange={(event) => handleBiasInputChange(currExtractedLayer.id, i - 1, event)}
                      onMouseUp={(event) => recordAPI(
                        "http://localhost:8080/resource/api/v1/insertRecord",
                        "2-1",
                        "Loss_Function",
                        event.target.id,
                        null,
                        new Date(),
                        localStorage.getItem("userUUID"))
                      }
                      placeholder={`input ${i}`}
                      disabled={false}
                    />
                  )}

              </div>
            )
          }

          nerualWeights.push(
            <div
              className={`neural layer${layerID + 1}N n_id${layerID + 1}${i} 
                ${prevClickedNeural.includes(`.layer${layerID + 1}N`) ? 'onclick-prev-neural' : ''}
                ${clickedNeural && clickedNeural.layerID === layerID + 1 && clickedNeural.neuralID === i ? 'onclick-prev-neural' : ''}`}
              onClick={(event) => handleNeuralOnClick(i, layerID, event.target.id)}
              id={`ch2-1_neural${layerID + 1}${i}`}
            >
              N
              <sub>
                {layerID + 1}{i}
              </sub>
            </div>
          )
          for (let index = 1; index <= item.weightList[i - 1].length; index++) {
            weightsContainer.push(

              <div className='weight-container' >
                {
                  // 如果是output layer，則顯示--Output
                  item.inOutputLayer && item.layerID > 1 ?
                    <>
                      <div className={`weight${layerID + 1}${i}${index}`}>--Output<sub>{i}</sub>--</div>
                    </>
                    :
                    <div className={`weight${layerID + 1}${i}${index}
                    ${clickedWeights.includes(`.weight${layerID + 1}${i}${index}`) ? 'onclick-prev-neural' : ''}
                    `}>W<sub>{layerID + 1}{i}{index}</sub>:</div>
                }

                <input
                  className='ch1-1-weight-input'
                  type='number'
                  min='-10'
                  max='10'
                  step='0.05'
                  placeholder={`neural${i}`}
                  value={(item.inOutputLayer && item.layerID > 1 ? (props.formulaOutput.toFixed(1)) :
                    item.inputDisable[i - 1] ? `${i}` : (item.weightList[i - 1][index - 1]).toFixed(1))}
                  onChange={(event) => handleWeightInputChange(item.id, i - 1, index - 1, event)}
                  disabled={true}
                />

              </div>
            )
          }
        }

        Inputs.push(
          <div className='ch1-1-control-input-container' >
            {nerualWeights.map((element) => (
              <div >{element}</div>
            ))}
            <div className='weights-container' >
              {weightsContainer.map((element) => (
                <div>{element}</div>
              ))}
            </div>
          </div>
        )
        nerualWeights = []
        weightsContainer = []
      }
      return Inputs
    }


    return (
      <div key={item.id} className='ch1-1-layer-container'>
        <div className='layer-input-container'>
          <div>第 {item.layerID} 層: </div>
          <input
            type='number'
            className='weight-input-box'
            id={`layerInput${item.layerID}`}
            min='1'
            max='10'
            placeholder='number of neural'
            value={item.numberOfNeural}
            // onChange={(event) => handleNumberOfNeuralInputChange(item.id, prevId, event)}
            //Block all input
            disabled={true}
          />


        </div>
        <div className='ch1-1-weights-bias-container'>
          {renderParaInputs(item, prevId, item.layerID - 1)}
        </div>

      </div>
    )
  }

  return (

    <>
      {
        <>
          {layers.map((item, index, array) => (
            generateLayer(item, index > 0 ? array[index - 1].id : null)
          ))}

        </>
      }

    </>
  )
}
const LossCalculation = (props) => {
  const [predictValues, setPredictValues] = useState(["", "", "", "", ""])
  const [lossValues, setLossValues] = useState(["", "", "", "", ""])
  const tableTtile = ["身高(cm)", "體重(kg)", "年齡", "性別", "預測值", "體脂率(%)", "損失值"]

  const tableValues = [
    [170.7, 70.4, 60, 0, 39.0, 37.1, 1.9],
    [157.8, 102.7, 69, 1, 47.8, 46.9, 0.9],
    [175.0, 119.2, 57, 0, 53.1, 55.4, 2.3],
    [150.0, 83.8, 24, 1, 32.5, 30.5, 2],
    [171.9, 89.6, 27, 1, 25.0, 25.7, 0.7],
  ]

  const handlePredictInputChange = useCallback((index, event) => {
    const newValue = event.target.value
    setPredictValues(prevValues => [
      ...prevValues.slice(0, index),
      newValue,
      ...prevValues.slice(index + 1)
    ])
  }, [])

  const handleLossInputChange = useCallback((index, event) => {
    const newValue = event.target.value
    setLossValues(prevValues => [
      ...prevValues.slice(0, index),
      newValue,
      ...prevValues.slice(index + 1)
    ])
  }, [])

  const buildTableContent = () => {
    const tableContent = []
    for (let i = 0; i < tableValues.length; i++) {
      tableContent.push(
        <div className="table-col d-flex" key={i}>
          {tableValues[i].slice(0, 4).map((item, index) => (
            <div className="loss-table-known m-2 border border-dark" key={index}>
              {item.toFixed(1)}
            </div>
          ))}
          <input
            type="number"
            className={Math.abs(parseFloat(predictValues[i]) - parseFloat(tableValues[i][5])) < 0.0001 ?
              'ch1-1-predict-input m-2 valid-input' : 'ch1-1-predict-input m-2 invalid-input'}
            id={`ch2-1_predict-ans${i}`}
            key={`ch2-1_predict-ans${i}`}
            onChange={(event) => handlePredictInputChange(i, event)}
            onMouseUp={(event) => recordAPI(
              "http://localhost:8080/resource/api/v1/insertRecord",
              "2-1",
              "Loss_Function",
              event.target.id,
              predictValues[i],
              Date(),
              localStorage.getItem("userUUID"))
            }
            value={predictValues[i]}
          />
          <div className="loss-table-known m-2 border border-dark">{tableValues[i][4].toFixed(1)}</div>
          <input
            type="number"
            className={Math.abs(parseFloat(lossValues[i]) - parseFloat(tableValues[i][6])) < 0.0001 ?
              'ch1-1-loss-input m-2 valid-input' : 'ch1-1-loss-input m-2 invalid-input'}
            id={`ch2-1_loss-ans${i}`}
            key={`ch2-1_loss-ans${i}`}
            onChange={(event) => handleLossInputChange(i, event)}
            onMouseUp={(event) => recordAPI(
              "http://localhost:8080/resource/api/v1/insertRecord",
              "2-1",
              "Loss_Function",
              event.target.id,
              lossValues[i],
              Date(),
              localStorage.getItem("userUUID"))
            }
            value={lossValues[i]}
          />
        </div>
      )
    }

    return tableContent
  }

  const lossValuesAsNumbers = lossValues.map(value => {
    const parsedValue = parseFloat(value)
    return isNaN(parsedValue) ? 0 : parsedValue
  })

  const sum = lossValuesAsNumbers.reduce((acc, num) => acc + num, 0)

  return (
    <div className="loss-calculation-container">
      {/* 建立表格標題 */}
      <div className="lostt-table-col d-flex ">
        {tableTtile.map((item, index) => (
          <div className="loss-table-title m-2 border border-dark d-flex justify-content-center align-items-center" key={index}>
            {index === 6 ?
              (
                <div className="fs-6">
                  _{item}_<br />
                  |y<sub>預測</sub> - y<sub>正確</sub>|
                </div>
              )
              :
              item
            }
          </div>
        ))}
      </div>
      {/* 建立表格內容 */}
      {buildTableContent()}
      <div>
        <div className="table-col d-flex">
          <div className="loss-table-title m-2  "></div>
          <div className="loss-table-title m-2  "></div>
          <div className="loss-table-title m-2  "></div>
          <div className="loss-table-title m-2 "></div>
          <div className="loss-table-title m-2 "></div>
          <div className="loss-table-title m-2"></div>
          <div className="loss-table-title m-2 ">總損失值 <br />__MAE__</div>
        </div>
        <div className="table-col d-flex">
          <div className="loss-table-title m-2 "></div>
          <div className="loss-table-title m-2 "></div>
          <div className="loss-table-title m-2"></div>
          <div className="loss-table-title m-2 "></div>
          <div className="loss-table-title m-2 "></div>
          <div className="loss-table-title m-2"></div>
          <div className="loss-table-title m-2">{sum / 5}</div>
        </div>
        <div className="d-flex flex-row-reverse w-100 ">
          <button className="w-25" onClick={() => {
            if (window.confirm("確定要提交嗎？")) {
              SubmitAnswerAPI(
                "http://localhost:8080/resource/api/v1/insertAnswer",
                "2-1",
                "Loss_Function",
                1,
                { predictValues, lossValues },
                Date(),
                localStorage.getItem('userUUID'),
                () => {
                  alert("提交成功！")
                }
              )
            }
          }}>
            提交
          </button>
        </div>
      </div>
    </div>
  )
}



function NetworkVisualization (props) {

  const svgRef = useRef(null)



  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const width = +svg.attr('width')
    const height = +svg.attr('height')
    let layerSizes = [...props.layers]


    // 每层的神经元数量

    const neuronRadius = 15 // 神经元半径（调整大小）
    const layerGap = 60 // 层之间的间距
    const neuronGap = 50 // 神经元之间的间距

    // 创建一个容器组，并将容器置中
    svg.selectAll('*').remove() // 清空SVG内容
    const container = svg.append('g')
      .attr('transform', `translate(${(width / 2 - (layerSizes.length * neuronGap))}, ${height / 2})`)


    // 计算每层的水平位置
    const layerXPositions = []
    // let xPosition = -((layerSizes.length - 1) * layerGap) / 2;
    let xPosition = 0

    layerSizes.forEach((size) => {
      layerXPositions.push(xPosition)
      xPosition += size * (neuronRadius * 1 + neuronGap / 2) + layerGap
    })


    // 绘制神经元和连接线
    for (let i = 0; i < layerSizes.length; i++) {
      const layerSize = layerSizes[i]
      const x = layerXPositions[i]
      const yStart = -(layerSize / 2) * (neuronRadius * 2 + neuronGap)




      // 绘制每一层的神经元

      for (let j = 0; j < layerSize; j++) {
        const y = yStart + j * (neuronRadius * 2 + neuronGap)
        // 绘制神经元圆
        if (j === 0) {
          container
            .append('text')
            .attr('x', x)
            .attr('y', y - 20) // 调整标签的垂直位置
            .attr('text-anchor', 'middle') // 文本居中对齐
            .attr('fill', 'black') // 可选，设置文本颜色
            .text(() => {
              if (i === 0) {
                return '輸入層'
              } else if (i === layerSizes.length - 1) {
                return '輸出層'
              } else {
                return '隱藏層'
              }
            })


        }

        container
          .append('circle')
          .attr('cx', x)
          .attr('cy', y)
          .attr('r', neuronRadius)
          .attr('fill', 'lightblue')

        if ((j != 0 || j !== layerSize - 1) && props.networkStructure[0]["AF"] == 1) {
          container
            .append('line')
            .attr('x1', x)
            .attr('y1', y + 3)
            .attr('x2', x - 10)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
          container
            .append('line')
            .attr('x1', x + 5)
            .attr('y1', y - 8 + 3)
            .attr('x2', x)
            .attr('y2', y + 3)
            .attr('stroke', 'gray')
            .attr('stroke-width', 2)
        }
        else if ((j != 0 || j !== layerSize - 1) && props.networkStructure[0]["AF"] == 2) {

          const sPath = (x, y) => `M${x - 10} ${y + 10} C${x - 6} ${y + 10}, ${x - 4} ${y + 10}, ${x} ${y} S${x + 5} ${y - 10}, ${x + 10} ${y - 10}`

          container
            .append("path")
            .attr("d", sPath(x, y))
            .attr("stroke", 'black')
            .attr("fill", "none")


        }

      }



      if (i < layerSizes.length - 1) {
        const nextLayerSize = layerSizes[i + 1]
        const nextX = layerXPositions[i + 1]
        for (let j = 0; j < layerSize; j++) {
          const startY = yStart + j * (neuronRadius * 2 + neuronGap)
          for (let k = 0; k < nextLayerSize; k++) {

            const endY = yStart + k * (neuronRadius * 2 + neuronGap) - (nextLayerSize - layerSize) * (neuronGap / 2 + neuronRadius)
            container
              .append('line')
              .attr('x1', x + neuronRadius)
              .attr('y1', startY)
              .attr('x2', nextX - neuronRadius)
              .attr('y2', endY)
              .attr('stroke', 'gray')
              .attr('stroke-width', 2)
          }
        }
      }
    }
  }, [props.layers, props.networkStructure])

  return (
    <svg
      className='network-graph'
      ref={svgRef}
      width="1000"
      height="500"
      style={{ background: 'rgba(128, 128, 128, 0.1)' }}
    ></svg>

  )


}




function FormulaArea (props) {


  return (
    <div className="formula-container">
      <div className="formula" dangerouslySetInnerHTML={{ __html: props.singleNeuralFormula }}></div>

    </div>
  )
}



function CH2_1LossFunction () {
  // const [formula, setFormula] = useState("Math.max(0, x + 1)")
  const [formulaOutput, setFormulaOutput] = useState(5)
  const [layers, setLayers] = useState([4, 4, 4, 1])
  const [singleNeuralFormula, setSingleNeuralFormula] = useState("")
  const [networkStructure, setNetworkStruture] = useState([
    {
      "AF": 1,
      "depth": 1,
      "width": 1,
      "epochs": 2
    }
  ])


  return (
    <>
      <div>
        <div className='title-container'>
          <div className='title'>損失函數與損失值</div>
          <div className='subtitle'>Loss Function</div>
        </div>
        <div className="bullet-point"> • 模擬說明</div>
        <div className='simulation-description'>小海狸已經訓練完一個模型，用於預測體脂率。他現在想要確認模型的損失值，請協助他完成表格吧！請在右側的模型輸入框中，填入表格中對應的輸入值，並觀察其預測值。然後再將這些預測值填入右側的表格，並自行計算損失值。 <br />
          <img src="/images/MAE_MSE.png" alt="" />
        </div>

        <div className='ch1-1-c-container'>

          <div className="ch1-1-control-panel-section">
            <NetworkControlPanel
              updateLayers={setLayers}
              setSingleNeuralFormula={setSingleNeuralFormula}
              formulaOutput={formulaOutput}
              setFormulaOutput={setFormulaOutput}
            ></NetworkControlPanel>
            <LossCalculation>
            </LossCalculation>
          </div>
          <div className='ch1-1-visulization-container'>
            <div>
              <div className='sub-bullet-point'> • 神經網路架構</div>
              <NetworkVisualization
                layers={layers}
                networkStructure={networkStructure}
              ></NetworkVisualization>
            </div>
            <div className='formula-section'>
              <div className='sub-bullet-point'> • 神經元函式</div>
              <FormulaArea
                singleNeuralFormula={singleNeuralFormula}
              ></FormulaArea>
            </div>
          </div>

        </div>
      </div>
    </>

  )
}

export default CH2_1LossFunction