import React from "react";
import { useState, useEffect } from "react";

import "./ConvolutionPractice.css";

import filterData from "./data/filter.json";
import jsonData from "./data/output_data.json";

import {
  recordAPIAction,
  SubmitAnswerAPI,
  recordAPI,
} from "../../utils/recordSubmitAPI";

///// General
function extract3x3Region(matrix, x, y) {
  const result = [];

  if (matrix.length >= 3 && matrix[0].length >= 3) {
    for (let i = y; i <= y + 2; i++) {
      for (let j = x; j <= x + 2; j++) {
        if (i >= 0 && i < matrix.length && j >= 0 && j < matrix[0].length) {
          result.push(matrix[i][j]);
        } else {
          result.push(0);
        }
      }
    }
  } else {
    console.error("Matrix is too small to extract a 3x3 region.");
  }

  return result;
}

function renderGridWithChunks(gridData, gridSize) {
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  return chunkArray(gridData, gridSize).map((row, rowIndex) => (
    <div className="p-graphic-row" key={`row-${rowIndex}`}>
      {row.map((item, index) => (
        <div className="p-graphic-cell" key={`cell-${rowIndex}-${index}`}>
          {gridData[rowIndex * gridSize + index]}
        </div>
      ))}
    </div>
  ));
}

/////////* Selection Section */////////

function SelectSection(props) {
  const handleImgClick = (imgName) => {
    props.sendOvImgSelected(imgName);
  };

  // const handleFilterClick = (event) => {
  //   const filterName = event.target.value;

  //   props.setState({
  //     ...props.state,
  //     filterSelected: filterName
  //   });
  //   // props.sendOvFilterSelected(filterName);

  //   // Change Width Of Result Section
  //   const selectedResultSection = document.querySelector('.selected-result-section');
  //   if (filterName === "sobel") {
  //     selectedResultSection.style.width = "600px";
  //   } else {
  //     selectedResultSection.style.width = "300px";
  //   }
  // };

  const handleFilterOnchange = (event) => {
    const filterName = event.target.value;

    props.setState({
      ...props.state,
      filterSelected: filterName,
    });

    // Change Width Of Result Section
    const selectedResultSection = document.querySelector(
      ".selected-result-section"
    );
    if (filterName === "sobel") {
      selectedResultSection.style.width = "600px";
    } else {
      selectedResultSection.style.width = "300px";
    }
  };

  const generateSection = (sectionName, size, filterOrImage) => {
    const sections = [];
    let Names = [];
    if (filterOrImage === "image") {
      Names = ["circle", "noisy_circle", "square", "lena"];
    } else if (filterOrImage === "filter") {
      Names = ["median", "laplacian", "sobel"];
    }
    for (let i = 0; i < size; i++) {
      let itemName = Names[i];
      sections.push(
        <div className="select-section-items" key={itemName}>
          <img
            src={
              filterOrImage === "image"
                ? `/images/${itemName}_original.png`
                : `/images/filters/${itemName}.png`
            }
            alt=""
            className="selecte-section-img"
            onClick={() => {
              if (filterOrImage === "image") {
                handleImgClick(itemName);
              } else if (filterOrImage === "filter") {
                // handleFilterClick(itemName);
              }
            }}
          />
        </div>
      );
    }
    return sections;
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center m-2">
        <div className="input-box-name">卷積圖片</div>

        {generateSection("section", 4, "image")}
      </div>
      <div className="m-2">
        <div className="input-box-name">濾波器</div>
        <select className="input-box" onChange={handleFilterOnchange}>
          <option value="median">median</option>
          <option value="laplacian">laplacian</option>
          {/* <option value="sobel">sobel</option> */}
        </select>
        {/* <div className='select-section-label'>濾波器</div> */}
      </div>
    </>
  );
}

/////////* Overview Section Of Convolution */////////

class SelectedImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverX: 0,
      hoverY: 0,
      clickX: 0,
      clickY: 0,
      imgSelected: "circle",
    };
  }

  handleHover = (event) => {
    const hoverX = Math.floor(event.nativeEvent.offsetX / 3.5) * 3.5;
    const hoverY = Math.floor(event.nativeEvent.offsetY / 3.5) * 3.5;

    if (
      hoverX >= 0 &&
      hoverX <= 77 * 3.5 &&
      hoverY >= 0 &&
      hoverY <= 77 * 3.5
    ) {
      this.props.getHoverXY(hoverX / 3.5, hoverY / 3.5);
      this.setState({
        hoverX: hoverX,
        hoverY: hoverY,
      });
    }
  };

  handleClick = (event, id) => {
    // recordAPIAction(
    //   "ConvolutionSimulationClick",
    //   id,
    //   localStorage.getItem("userUUID")
    // )
    recordAPI(
      "apiLink",
      "4-1",
      "Convolution",
      id,
      null,
      Date(),
      localStorage.getItem("userUUID")
    );
    const clickX = Math.floor(event.nativeEvent.offsetX / 3.5) * 3.5;
    const clickY = Math.floor(event.nativeEvent.offsetY / 3.5) * 3.5;
    // console.log(clickX, clickY)
    if (
      clickX >= 0 &&
      clickX <= 77 * 3.5 &&
      clickY >= 0 &&
      clickY <= 77 * 3.5
    ) {
      this.props.getClickedXY(clickX / 3.5, clickY / 3.5);
      this.setState({
        clickX: clickX,
        clickY: clickY,
      });
    }
  };

  render() {
    const { hoverX, hoverY, clickX, clickY } = this.state;

    // 計算 filter-selected-area 的位置
    const hoveredFilterAreaStyle = {
      top: `${hoverY}px`,
      left: `${hoverX}px`,
    };

    const selectedFilterAreaStyle = {
      top: `${clickY}px`,
      left: `${clickX}px`,
    };

    return (
      <div className="selected-image-section">
        <div className="container">
          {/* <div className='text-label'>輸入圖片(80, 80)</div> */}
          <div className="selected-image-container">
            <div
              className="selected-image"
              id="convolution-selected-image"
              onMouseMove={this.handleHover}
              onClick={(event) =>
                this.handleClick(event, event.currentTarget.id)
              }
            >
              <img
                className="ov-selected-image"
                src={`/images/${this.props.imgSelected}_original.png`}
                alt=""
              />
            </div>
            <div
              className="filter-hovered-area"
              style={hoveredFilterAreaStyle}
            ></div>
            <div
              className="filter-clicked-area"
              style={selectedFilterAreaStyle}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

function SelectedFilter(props) {
  return props.filterSelected !== "sobel" ? (
    props.filterSelected !== "median" ? (
      <div className="selected-filter-section">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="selected-filter-container">
            {renderGridWithChunks(filterData[props.filterSelected], 3)}
          </div>
          <div className="text-label">Laplacian Filter (3, 3)</div>
        </div>
      </div>
    ) : (
      <div className="selected-filter-section">Meidan(3, 3)</div>
    )
  ) : (
    <div className="selected-filter-section">
      <div className="selected-filter-container">
        <div className="text-label">Sobel Filter 1 (3,3)</div>
        {renderGridWithChunks(filterData[props.filterSelected + "_x"], 3)}
      </div>
      <div className="selected-filter-container">
        <div className="text-label">Sobel Filter 2 (3,3)</div>
        {renderGridWithChunks(filterData[props.filterSelected + "_y"], 3)}
      </div>
    </div>
  );
}

function ConvolResult(props) {
  const hoveredFilterAreaStyle = {
    top: `${props.hoverY * 3.5}px`,
    left: `${props.hoverX * 3.5}px`,
  };

  const selectedFilterAreaStyle = {
    top: `${props.clickY * 3.5}px`,
    left: `${props.clickX * 3.5}px`,
  };

  return props.filterSelected !== "sobel" ? (
    <div className="selected-result-section">
      <div className="covoled-img-container">
        {/* <div className='text-label'>輸出圖片(78, 78)</div> */}
        <div className="selected-result-container">
          <img
            className="ov-selected-result"
            src={`/images/${props.imgSelected}_${props.filterSelected}_Filter.png`}
            alt=""
          />
          <div
            className="result-filter-hovered-area"
            style={hoveredFilterAreaStyle}
          ></div>
          <div
            className="result-filter-clicked-area"
            style={selectedFilterAreaStyle}
          ></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="selected-result-section">
      <div className="sobel-temp-container">
        <div className="container">
          <div className="text-label">卷積後圖片(78, 78)</div>
          <div className="selected-result-container">
            <img
              className="ov-selected-result"
              src={`/images/${props.imgSelected}_${props.filterSelected}_X_Filter.png`}
              alt=""
            />
            <div
              className="result-filter-hovered-area"
              style={hoveredFilterAreaStyle}
            ></div>
            <div
              className="result-filter-clicked-area"
              style={selectedFilterAreaStyle}
            ></div>
          </div>
          <div className="selected-result-container">
            <img
              className="ov-selected-result"
              src={`/images/${props.imgSelected}_${props.filterSelected}_Y_Filter.png`}
              alt=""
            />
            <div
              className="result-filter-hovered-area"
              style={hoveredFilterAreaStyle}
            ></div>
            <div
              className="result-filter-clicked-area"
              style={selectedFilterAreaStyle}
            ></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-label">輸出圖片(78, 78)</div>
        <div className="selected-result-container">
          <img
            className="ov-selected-result"
            src={`/images/${props.imgSelected}_${props.filterSelected}_Combined_Filter.png`}
            alt=""
          />
          <div
            className="result-filter-hovered-area"
            style={hoveredFilterAreaStyle}
          ></div>
          <div
            className="result-filter-clicked-area"
            style={selectedFilterAreaStyle}
          ></div>
        </div>
      </div>
    </div>
  );
}

function LeftGraphicSection(props) {
  const [dataMatrix, setDataMatrix] = useState(
    jsonData["Original_circle_Image"]
  );

  useEffect(() => {
    let newDataMatrix;
    if (props.imgSelected === "circle") {
      newDataMatrix = jsonData["Original_circle_Image"];
    } else if (props.imgSelected === "noisy_circle") {
      newDataMatrix = jsonData["Original_noisy_circle_Image"];
    } else if (props.imgSelected === "square") {
      newDataMatrix = jsonData["Original_square_Image"];
    } else {
      newDataMatrix = jsonData["Original_lena_Image"];
    }

    setDataMatrix(newDataMatrix);
  }, [props.imgSelected]);

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const renderGrid = (gridData, gridSize, FilterOrNot) => {
    //color change
    return chunkArray(gridData, gridSize).map((row, rowIndex) => (
      <div className="p-graphic-row" key={`row-${rowIndex}`}>
        {row.map((item, index) => (
          <div
            className="p-graphic-cell"
            style={{
              backgroundColor: FilterOrNot
                ? gridData[rowIndex * gridSize + index] != 0
                  ? gridData[rowIndex * gridSize + index] > 0
                    ? "white"
                    : "black"
                  : "white"
                : `rgb(${gridData[rowIndex * gridSize + index]}, ${
                    gridData[rowIndex * gridSize + index]
                  }, ${gridData[rowIndex * gridSize + index]})`,
              color: FilterOrNot
                ? gridData[rowIndex * gridSize + index] != 0
                  ? gridData[rowIndex * gridSize + index] > 0
                    ? "black"
                    : "white  "
                  : "rgba(0, 0, 0, 0.25)"
                : gridData[rowIndex * gridSize + index] > 127.5
                ? "black"
                : "white",
              borderColor:
                // FilterOrNot ?
                gridData[rowIndex * gridSize + index] > 127.5
                  ? "black"
                  : "white",
              // : "white"
            }}
            key={`cell-${rowIndex}-${index}`}
          >
            {gridData[rowIndex * gridSize + index]}
          </div>
        ))}
      </div>
    ));
  };

  const extractedRegion = extract3x3Region(
    dataMatrix,
    props.clickX,
    props.clickY
  );

  const graphicFilterContainer = (filterSelected) => {
    // console.log(filterSelected)
    if (filterSelected === "sobel") {
      return (
        <>
          {
            <>
              <div className="text-label">濾波器(3, 3)</div>
              <div className="left-graphic-filter-container">
                {renderGrid(filterData["sobel_x"], 3, true)}
              </div>
              <div className="left-graphic-filter-container">
                {renderGrid(filterData["sobel_y"], 3, true)}
              </div>
            </>
          }
        </>
      );
    } else if (filterSelected === "laplacian") {
      return (
        <>
          {
            <div className="left-graphic-filter-container">
              {/* <div className='text-label'>濾波器(3, 3)</div> */}
              {renderGrid(filterData["laplacian"], 3, true)}
            </div>
          }
        </>
      );
    }

    return null;
  };

  return (
    <div className="p-graphic-left-setion w-100">
      <div className="graphic-container w-25">
        <div className="vis-simulation-title">選擇區域(3, 3)</div>
        <div className="p-graphic-image">
          {/* <div className='text-label'>選擇區域(3, 3)</div> */}
          {renderGrid(extractedRegion, 3, false)}
        </div>
      </div>
      <div className="graphic-container w-25">
        <div className="vis-simulation-title">濾波器(3, 3)</div>
        {props.filterSelected === "median" ? (
          <div className="median-text">中值濾波器</div>
        ) : (
          graphicFilterContainer(props.filterSelected)
        )}
      </div>
    </div>
  );
}

function MiddleMathSection(props) {
  const renderNumberGrid = (
    gridData,
    numberOfGrid,
    classN,
    FilterOrNot,
    bgcolor
  ) => {
    return (
      <>
        {
          <div className={classN}>
            {gridData.map((number, index) => (
              <div
                className="mathematical-grid"
                style={{
                  backgroundColor: bgcolor
                    ? "white"
                    : FilterOrNot
                    ? number != 0
                      ? number > 0
                        ? "white"
                        : "black"
                      : "white"
                    : `rgb(${number}, ${number}, ${number})`,
                  color: bgcolor
                    ? "black"
                    : FilterOrNot
                    ? number != 0
                      ? number > 0
                        ? "black"
                        : "white"
                      : "rgba(0, 0, 0, 0.25)"
                    : number > 127.5
                    ? "black"
                    : "white",
                  borderColor: bgcolor
                    ? "white"
                    : FilterOrNot
                    ? number === -1
                      ? "white"
                      : "black"
                    : number >= 127.5
                    ? "black"
                    : "white",
                  // `rgb(${number}, ${number}, ${number})`
                }}
              >
                {number}
              </div>
            ))}
          </div>
        }
      </>
    );
  };

  const renderMidMathSection = (filterName, x, y) => {
    let midMathSection;

    if (filterName === "sobel") {
      midMathSection = (
        <div className="p-math-mid-setion">
          <div className="vis-simulation-title w-100">卷積過程</div>

          <div className="p-math-mid-container">
            <div className="canvas-container">
              {filterName !== "median"
                ? renderNumberGrid(
                    ["┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐"],
                    8,
                    "p-math-mid-operator-container",
                    false,
                    false
                  )
                : null}
            </div>
            <div className="p-math-image">
              {renderNumberGrid(
                extract3x3Region(
                  jsonData["Original_" + props.imgSelected + "_Image"],
                  props.clickX,
                  props.clickY
                ),
                9,
                "p-math-mid-number-container",
                false,
                false
              )}
            </div>
            <div className="p-math-operator">
              {renderNumberGrid(
                ["×", "✚", "×", "×", "×", "×", "×", "×", "×", "×"],
                9,
                "p-math-mid-operator-container",
                false,
                true
              )}
            </div>
            <div className="p-math-filter">
              {renderNumberGrid(
                filterData[props.filterSelected + "_x"],
                9,
                "p-math-mid-filter-container",
                true,
                false
              )}
            </div>
          </div>
          <div className="p-math-mid-container">
            <div className="canvas-container">
              {renderNumberGrid(
                ["┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐", "┌✚┐"],
                8,
                "p-math-mid-operator-container",
                false,
                false
              )}
            </div>
            <div className="p-math-image">
              {renderNumberGrid(
                extract3x3Region(
                  jsonData["Original_" + props.imgSelected + "_Image"],
                  props.clickX,
                  props.clickY
                ),
                9,
                "p-math-mid-number-container",
                false,
                false
              )}
            </div>
            <div className="p-math-operator">
              {renderNumberGrid(
                ["×", "×", "×", "×", "×", "×", "×", "×", "×"],
                9,
                "p-math-mid-operator-container",
                false,
                true
              )}
            </div>
            <div className="p-math-filter">
              {renderNumberGrid(
                filterData[props.filterSelected + "_y"],
                9,
                "p-math-mid-filter-container",
                false,
                false
              )}
            </div>
          </div>
        </div>
      );
    } else if (filterName === "laplacian") {
      midMathSection = (
        <div className="p-math-mid-setion">
          <div className="vis-simulation-title w-100">卷積過程</div>
          <div className="p-math-mid-container">
            <div className="p-math-image">
              {renderNumberGrid(
                extract3x3Region(
                  jsonData["Original_" + props.imgSelected + "_Image"],
                  props.clickX,
                  props.clickY
                ),
                9,
                "p-math-mid-number-container",
                false
              )}
            </div>
            <div className="p-math-operator">
              {renderNumberGrid(
                [
                  "×",
                  "✚",
                  "×",
                  "✚",
                  "×",
                  "✚",
                  "×",
                  "✚",
                  "×",
                  "✚",
                  "×",
                  "✚",
                  "×",
                  "✚",
                  "×",
                  "✚",
                  "×",
                ],
                9,
                "p-math-mid-operator-container",
                false,
                true
              )}
            </div>
            <div className="p-math-filter">
              {renderNumberGrid(
                filterData[props.filterSelected],
                9,
                "p-math-mid-filter-container",
                true,
                false
              )}
            </div>
          </div>
        </div>
      );
    } else {
      midMathSection = (
        <div className="p-math-mid-setion">
          <div className="vis-simulation-title w-100">卷積過程(排序後)</div>
          <div className="p-math-mid-container">
            {/* <div className='canvas-container' style={{ height: '35px' }}>
          
            </div> */}
            {/* <div className='p-math-image'>
              {renderNumberGrid(extract3x3Region(jsonData["Original_" + props.imgSelected + "_Image"], props.clickX,  props.clickY), 9, 'p-math-mid-number-container', false)}
            </div> */}
            <div className="p-math-operator">
              {renderNumberGrid(
                extract3x3Region(
                  jsonData["Original_" + props.imgSelected + "_Image"],
                  props.clickX,
                  props.clickY
                ).sort((a, b) => a - b),
                9,
                "p-math-mid-number-container",
                false,
                false
              )}
            </div>
            <div className="p-math-filter"></div>
          </div>
        </div>
      );
    }
    return midMathSection;
  };

  return renderMidMathSection(props.filterSelected, props.clickX, props.clickY);
}

function RightAnswerSection(props) {
  return (
    <div className="p-ans-right-setion">
      {
        // [props.clickY][props.clickX]
        <>
          <div className="vis-simulation-title">卷積結果</div>
          <div className="ans-container">
            <div className="ans-grid mathematical-grid">
              {parseInt(
                jsonData[`${props.imgSelected}_${props.filterSelected}_Filter`][
                  props.clickY
                ][props.clickX]
              ) * -1}
              {/* {props.filterSelected === "sobel" ?
                parseInt(jsonData[`${props.imgSelected}_${props.filterSelected}_Combined_Filter`][props.clickY][props.clickX]) :
                parseInt(jsonData[`${props.imgSelected}_${props.filterSelected}_Filter`][props.clickY][props.clickX])} */}
            </div>
          </div>
        </>
      }
    </div>
  );
}

function Convolution() {
  const [inputSize, setInputSize] = useState(4);
  const [filterSize, setFilterSize] = useState(3);
  const [maxValue, setMaxValue] = useState(0);

  const [inputImage, setInputImage] = useState(
    Array.from({ length: inputSize }, () =>
      Array.from({ length: inputSize }, () => 0)
    )
  );
  const [filter, setFilter] = useState(
    Array.from({ length: filterSize }, () =>
      Array.from({ length: filterSize }, () => 0)
    )
  );
  const [convolutionResult, setConvolutionResult] = useState(
    Array.from({ length: inputSize - filterSize + 1 }, () =>
      Array.from({ length: inputSize - filterSize + 1 }, () => 0)
    )
  );

  useEffect(() => {
    const newFilter = [
      [0, -1, 0],
      [-1, 4, -1],
      [0, -1, 0],
    ];
    const newImage = [
      [0, 10, 20, 30],
      [30, 255, 23, 40],
      [100, 15, 255, 20],
      [14, 100, 115, 233],
    ];
    setFilter(newFilter);
    setInputImage(newImage);

    const initialConvolutionResult = [];
    let max = 0;
    for (let i = 0; i <= newImage.length - newFilter.length; i++) {
      const row = [];
      for (let j = 0; j <= newImage[i].length - newFilter[0].length; j++) {
        let sum = 0;
        for (let x = 0; x < newFilter.length; x++) {
          for (let y = 0; y < newFilter[x].length; y++) {
            sum += newImage[i + x][j + y] * newFilter[x][y];
          }
        }
        row.push(sum);
        if (Math.abs(sum) > max) {
          max = Math.abs(sum);
        }
      }
      initialConvolutionResult.push(row);
    }
    setConvolutionResult(initialConvolutionResult);
    setMaxValue(max);
  }, []);

  useEffect(() => {
    let max = 0;
    for (let i = 0; i < convolutionResult.length; i++) {
      for (let j = 0; j < convolutionResult[i].length; j++) {
        if (Math.abs(convolutionResult[i][j]) > max) {
          max = Math.abs(convolutionResult[i][j]);
        }
      }
    }
    setMaxValue(max);
  }, [convolutionResult]);

  const performConvolution = () => {
    const result = [];
    for (let i = 0; i <= inputSize - filterSize; i++) {
      const row = [];
      for (let j = 0; j <= inputSize - filterSize; j++) {
        let sum = 0;
        for (let x = 0; x < filterSize; x++) {
          for (let y = 0; y < filterSize; y++) {
            sum += inputImage[i + x][j + y] * filter[x][y];
          }
        }
        row.push(sum);
      }
      result.push(row);
    }
    setConvolutionResult(result);
  };
  useEffect(() => {
    performConvolution();
  }, [inputImage]);

  const handleInputSizeChange = (e) => {
    const newSize = parseInt(e.target.value) || 3;
    setInputSize(newSize);
    resetInputImage(newSize);
  };

  const handleFilterSizeChange = (e) => {
    const newSize = parseInt(e.target.value) || 2;
    setFilterSize(newSize);
    resetFilter(newSize);
  };

  const resetInputImage = (size) => {
    setInputImage(
      Array.from({ length: size }, () => Array.from({ length: size }, () => 0))
    );
    setConvolutionResult(
      Array.from({ length: size - filterSize + 1 }, () =>
        Array.from({ length: size - filterSize + 1 }, () => 0)
      )
    );
  };

  const resetFilter = (size) => {
    setFilter(
      Array.from({ length: size }, () => Array.from({ length: size }, () => 0))
    );
    setConvolutionResult(
      Array.from({ length: inputSize - size + 1 }, () =>
        Array.from({ length: inputSize - size + 1 }, () => 0)
      )
    );
  };

  const handleInputImageChange = (e, rowIndex, colIndex) => {
    console.log(e.target.value);
    const targetValue = e.target.value;
    let newValue;
    if (targetValue == "") {
      newValue = 0;
    } else if (targetValue <= 0) {
      newValue = 0;
    } else if (targetValue >= 255) {
      newValue = 255;
    } else {
      newValue = targetValue;
    }

    const updatedImage = inputImage.map((row, rIndex) => {
      if (rIndex === rowIndex) {
        return row.map((value, cIndex) =>
          cIndex === colIndex ? newValue : value
        );
      } else {
        return row;
      }
    });
    setInputImage(updatedImage);
    performConvolution();
    console.log(convolutionResult);
  };

  const handleFilterChange = (e, rowIndex, colIndex) => {
    const newValue = parseInt(e.target.value) || 0;
    const updatedFilter = filter.map((row, rIndex) => {
      if (rIndex === rowIndex) {
        return row.map((value, cIndex) =>
          cIndex === colIndex ? newValue : value
        );
      } else {
        return row;
      }
    });
    setFilter(updatedFilter);
    performConvolution();
  };

  return (
    <div className="m-2 d-flex">
      {/* Input Image Section */}
      <div className="m-3">
        <h3>
          輸入圖片 ({inputSize}x{inputSize})
        </h3>
        <div className="input-grid">
          {inputImage.map((row, rowIndex) => (
            <div key={rowIndex} className="input-row d-flex align-items-center">
              {row.map((pixel, colIndex) => (
                <input
                  className="convol-input"
                  key={`4-1_q2-img-input${rowIndex}-${colIndex}`}
                  id={`4-1_q2-img-input${rowIndex}-${colIndex}`}
                  type="number"
                  max={255}
                  min={0}
                  value={inputImage[rowIndex][colIndex]}
                  onChange={(e) =>
                    handleInputImageChange(e, rowIndex, colIndex)
                  }
                  onMouseDown={(event) =>
                    recordAPI(
                      "apiLink",
                      "4-1",
                      "Convolution",
                      event.target.id,
                      event.target.value,
                      Date(),
                      localStorage.getItem("userUUID")
                    )
                  }
                  style={{
                    backgroundColor: `rgb(${pixel}, ${pixel}, ${pixel})`,
                    color: pixel > 127.5 ? "black" : "white",
                    border:
                      rowIndex !== 1 || colIndex !== 1
                        ? "1px solid black"
                        : "2px solid red",
                  }}
                  disabled={rowIndex !== 1 || colIndex !== 1}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Filter Section */}
      <div className="m-3">
        <h3>
          濾波器 ({filterSize}x{filterSize})
        </h3>
        <div className="filter-grid">
          {filter.map((row, rowIndex) => (
            <div key={rowIndex} className="filter-row d-flex">
              {row.map((value, colIndex) => (
                <input
                  className="convol-input"
                  key={colIndex}
                  type="number"
                  value={filter[rowIndex][colIndex]}
                  onChange={(e) => handleFilterChange(e, rowIndex, colIndex)}
                  style={{
                    backgroundColor: value >= 0 ? "white" : "black",
                    color: value >= 0 ? "black" : "white",
                  }}
                  disabled={true}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Convolution Result Section */}
      <div className="m-3">
        <h3>
          卷積後的圖片 ({inputSize - filterSize + 1}x
          {inputSize - filterSize + 1})
        </h3>
        <div className="result-grid">
          {convolutionResult.map((row, rowIndex) => (
            <div key={rowIndex} className="result-row d-flex">
              {row.map((value, colIndex) => (
                <div
                  key={colIndex}
                  className="border convol-input"
                  style={{
                    backgroundColor: `rgb(${Math.round(
                      (255 * Math.abs(value)) / maxValue
                    )}, ${Math.round(
                      (255 * Math.abs(value)) / maxValue
                    )}, ${Math.round((255 * Math.abs(value)) / maxValue)})`,
                    color: value >= 100 ? "black" : "white",
                  }}
                >
                  {value}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function EasyEdgeDetection() {
  const [twoByTwoImages, setTwoByTwoImages] = useState({
    veritcalLeft: [
      [0, 255],
      [0, 255],
    ],
    verticalRight: [
      [255, 0],
      [255, 0],
    ],
    horizontalTop: [
      [0, 0],
      [255, 255],
    ],
    horizontalBottom: [
      [255, 255],
      [0, 0],
    ],
  });
  const [filters, setFilters] = useState({
    veritcal: [
      [-1, -1],
      [1, 1],
    ],
    horizontal: [
      [-1, 1],
      [-1, 1],
    ],
  });

  const [selectedimg, setSelectedImg] = useState("veritcalLeft");
  const [selectedFilter, setSelectedFilter] = useState("veritcal");
  const [result, setResult] = useState(0);

  useEffect(() => {
    setResult(convolute(twoByTwoImages[selectedimg], filters[selectedFilter]));
  }, [selectedimg, selectedFilter]);

  const updatedImage = (imgName) => {
    setSelectedImg(imgName);
    recordAPI(
      "apiLink",
      "4-1",
      "EasyEdgeDetectionImg",
      imgName,
      null,
      Date(),
      localStorage.getItem("userUUID")
    );
  };

  const updatedFilter = (filterName) => {
    setSelectedFilter(filterName);
    recordAPI(
      "apiLink",
      "4-1",
      "EasyEdgeDetectionFilter",
      filterName,
      null,
      Date(),
      localStorage.getItem("userUUID")
    );
  };

  const generateImg = (imgArray, filterName) => {
    return (
      <div className="easy-conv-img-container p-2">
        {imgArray.map((row, rowIndex) => (
          <div key={rowIndex} className="easy-conv-img-row d-flex">
            {row.map((pixel, colIndex) => (
              <div
                key={colIndex}
                className="easy-conv-img-pixel"
                onClick={() => updatedImage(filterName)}
                style={{
                  backgroundColor: `rgb(${pixel}, ${pixel}, ${pixel})`,
                  color: pixel > 127.5 ? "black" : "white",
                }}
              >
                {pixel}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const generateFilter = (filterArray, filterName) => {
    return (
      <div className="easy-conv-filter-container p-2">
        {filterArray.map((row, rowIndex) => (
          <div key={rowIndex} className="easy-conv-filter-row d-flex">
            {row.map((value, colIndex) => (
              <div
                key={colIndex}
                className="easy-conv-filter-pixel"
                onClick={() => updatedFilter(filterName)}
                style={{
                  backgroundColor: value >= 0 ? "white" : "black",
                  color: value >= 0 ? "black" : "white",
                }}
              >
                {value}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const convolute = (image, filter) => {
    let sum = 0;
    for (let i = 0; i < image.length; i++) {
      for (let j = 0; j < image[i].length; j++) {
        sum += image[i][j] * filter[i][j];
      }
    }
    return sum;
  };

  return (
    <div className="d-flex">
      <div className="easy-conv-selection d-flex">
        <div className="w-100 ec-label">小練習</div>
        <div>
          <div className="d-flex s-c-container ">
            <div className="ec-label">圖片</div>
            {Object.keys(twoByTwoImages).map((filter, index) => {
              return generateImg(twoByTwoImages[filter], filter);
            })}
          </div>
          <div className="d-flex s-c-container">
            <div className="ec-label">濾波器</div>
            {Object.keys(filters).map((filter, index) => {
              return generateFilter(filters[filter], filter);
            })}
          </div>
        </div>
        <div>
          <div className="selected-img d-flex">
            <div className="selected-img-title ec-label">卷積圖片</div>
            <div className="selected-img-container">
              {generateImg(twoByTwoImages[selectedimg], selectedimg)}
            </div>
          </div>
          <div className="selected-filter d-flex">
            <div className="selected-filter-title ec-label">濾波器</div>
            <div className="selected-filter-container">
              {generateFilter(filters[selectedFilter], selectedFilter)}
            </div>
          </div>
        </div>
        <div className="selected-result d-flex justify-content-center ">
          <div className="selected-result-title ec-label">卷積結果</div>
          <div
            className="selected-result-pixel"
            style={{
              backgroundColor: result === 0 ? "black" : "white",
              color: result === 0 ? "white" : "black",
            }}
          >
            {result}
          </div>
        </div>
      </div>
    </div>
  );
}

function XEdgeDetectPractice(props) {
  const [filter, setFilter] = useState([
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ]);
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);

  useEffect(() => {
    initInputImg();
    props.setQ3Ans(filter);
  }, []);

  useEffect(() => {
    applyConvolution();
    props.setQ3Ans(filter);
  }, [filter, image]);

  const applyConvolution = () => {
    if (!image) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0, image.width, image.height);

    const imageData = ctx.getImageData(0, 0, image.width, image.height);
    const data = imageData.data;

    const width = image.width;
    const height = image.height;

    convertToGrayscale(data);

    const newImageData = ctx.createImageData(width, height);
    let maxAbs = 0;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        let sum = calculateSum(data, width, height, x, y, filter);
        if (!isNaN(sum)) {
          // Check if sum is valid

          // updateImageData(newImageData, x, y, sum);
          maxAbs = Math.max(maxAbs, Math.abs(sum));
        }
      }
    }
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        let sum = calculateSum(data, width, height, x, y, filter);
        if (!isNaN(sum)) {
          const mapGrayValue = Math.round((255 * Math.abs(sum)) / maxAbs);

          updateImageData(newImageData, x, y, mapGrayValue);
        }
        // maxAbs = Math.max(maxAbs, Math.abs(sum));
      }
    }

    ctx.putImageData(newImageData, 0, 0);

    // Process edges to grayscale
    // mapEdgeDetectionToGrayscale(newImageData, maxAbs);

    setResult(canvas.toDataURL());
  };

  const convertToGrayscale = (data) => {
    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      data[i] = avg; // Red channel
      data[i + 1] = avg; // Green channel
      data[i + 2] = avg; // Blue channel
    }
  };

  const calculateSum = (data, width, height, x, y, filter) => {
    let sum = 0;
    for (let i = -1; i <= 1; i++) {
      for (let j = -1; j <= 1; j++) {
        const pixel = ((y + j) * width + (x + i)) * 4;
        sum += data[pixel] * filter[j + 1][i + 1];
      }
    }
    return sum;
  };

  const updateImageData = (newImageData, x, y, sum) => {
    const newPixel = (y * newImageData.width + x) * 4;
    newImageData.data[newPixel] = sum;
    newImageData.data[newPixel + 1] = sum;
    newImageData.data[newPixel + 2] = sum;
    newImageData.data[newPixel + 3] = 255; // Alpha
  };

  const mapEdgeDetectionToGrayscale = (newImageData, maxAbs) => {
    for (let i = 0; i < newImageData.data.length; i += 4) {
      const grayValue = Math.round(
        255 - (255 * Math.abs(newImageData.data[i])) / maxAbs
      );
      newImageData.data[i] = grayValue;
      newImageData.data[i + 1] = grayValue;
      newImageData.data[i + 2] = grayValue;
    }
    return newImageData; // 返回修改後的 newImageData
  };

  const initInputImg = async () => {
    const response = await fetch("/images/square_original.png");
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: "image/jpeg" });
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImage(img);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleFilterChange = (e, row, col) => {
    const newValue =
      e.target.value.trim() === "" ? "" : parseInt(e.target.value) || 0;
    const newFilter = [...filter];
    newFilter[row][col] = newValue;
    console.log(newFilter);
    setFilter(newFilter);
  };

  return (
    <div>
      {/* {image && <img src={image.src} alt="Original" />} */}
      <div className="d-flex">
        <div className="s-c-label">filter</div>
        <div>
          {filter.map((row, rowIndex) => (
            <div key={rowIndex} className="d-flex">
              {row.map((value, colIndex) => (
                <input
                  max={20}
                  min={-20}
                  key={`4-1_q3-filter-cell-${rowIndex}-${colIndex}`}
                  id={`4-1_q3-filter-cell-${rowIndex}-${colIndex}`}
                  className="convol-input"
                  type="number"
                  value={value}
                  onChange={(e) => handleFilterChange(e, rowIndex, colIndex)}
                  onMouseUp={(event) =>
                    recordAPI(
                      "apiLink",
                      "4-1",
                      "Convolution",
                      event.target.id,
                      event.target.value,
                      Date(),
                      localStorage.getItem("userUUID")
                    )
                  }
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex">
        <div className="s-c-label">卷積後的圖片</div>
        {result && <img className="s-c-img" src={result} alt="Result" />}
      </div>
    </div>
  );
}

function CNNPracticeSection() {
  const [resultSize, setResultSize] = useState(3); // Default size is 3x3
  const [q1convolutionResult, setQ1ConvolutionResult] = useState(
    Array.from({ length: resultSize }, () =>
      Array.from({ length: resultSize }, () => 0)
    )
  );
  const [q2Ans, setQ2Ans] = useState("");
  const [q3Ans, setQ3Ans] = useState("");

  const handleResultSizeChange = (event, id) => {
    const newSize =
      event.target.value.trim() === "" ? "" : parseInt(event.target.value);
    recordAPI(
      "apiLink",
      "4-1",
      "Convolution",
      `4-1_resize${id}`,
      newSize,
      Date(),
      localStorage.getItem("userUUID")
    );
    setResultSize(newSize);
    // Update convolution result matrix
    setQ1ConvolutionResult((prevResult) => {
      const newResult = Array.from({ length: newSize }, () =>
        Array.from({ length: newSize }, () => 0)
      );
      for (let i = 0; i < Math.min(prevResult.length, newSize); i++) {
        for (let j = 0; j < Math.min(prevResult[i].length, newSize); j++) {
          newResult[i][j] = prevResult[i][j];
        }
      }
      return newResult;
    });
  };

  const renderResultArea = () => {
    const resultRows = [];
    for (let i = 0; i < resultSize; i++) {
      const rowCells = [];
      for (let j = 0; j < resultSize; j++) {
        rowCells.push(
          <input
            key={j}
            id={`4-1_q1-ans-cell-${i}-${j}`}
            className="result-cell convol-input border"
            type="number"
            value={q1convolutionResult[i][j]}
            onChange={(event) => {
              const newValue =
                event.target.value.trim() === ""
                  ? ""
                  : parseInt(event.target.value) || 0;
              setQ1ConvolutionResult((prevResult) => {
                const updatedResult = [...prevResult];
                updatedResult[i][j] = newValue;
                return updatedResult;
              });
            }}
            onMouseUp={(event) =>
              recordAPI(
                "apiLink",
                "4-1",
                "Convolution",
                event.target.id,
                event.target.value,
                Date(),
                localStorage.getItem("userUUID")
              )
            }
          />
        );
      }
      resultRows.push(
        <div key={i} className="result-row d-flex">
          {rowCells}
        </div>
      );
    }
    return <div className="result-grid">{resultRows}</div>;
  };

  return (
    <div className="question-section">
      <div className="sub-bullet-point"> • 練習問題</div>
      <div className="question-container ">
        <div className="question-title">
          1. 請計算以下2*2的濾波器對3*3的圖片進成卷積的結果。
        </div>
        <button
          onClick={() =>
            SubmitAnswerAPI(
              "apiLink",
              "4-1",
              "Convolution",
              "1",
              q1convolutionResult,
              Date(),
              localStorage.getItem("userUUID")
            )
          }
        >
          提交
        </button>
        <div className="d-flex">
          <div className="d-flex flex-column align-items-center m-2">
            <table border="1">
              <tbody>
                <tr>
                  <td>1</td>
                  <td>1</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>0</td>
                  <td>1</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>1</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <div>input(3, 3)</div>
          </div>
          <div className="d-flex flex-column align-items-center m-2">
            <table border="1">
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>0</td>
                  <td>-1</td>
                </tr>
              </tbody>
            </table>
            <div>濾波器(2, 2)</div>
          </div>

          <div>
            <h4>請選取卷積結果的大小：</h4>
            <select
              id="q1-result-size"
              onChange={(event) =>
                handleResultSizeChange(event, event.currentTarget.value)
              }
              value={resultSize}
            >
              <option value="1">1x1</option>
              <option value="2">2x2</option>
              <option value="3">3x3</option>
              <option value="4">4x4</option>
              <option value="5">5x5</option>
            </select>
            {/* 这里显示所选卷积结果区域 */}

            {renderResultArea()}
          </div>
        </div>
      </div>

      <div className="question-container">
        <div className="question-title">
          2.
          小海狸想要利用卷積進行邊緣檢測，但是他不知道該如何調整目標格子的pixel數值，請幫幫他。(提示:
          可以把目標格子的pixel數值調整成最大值255或最小值0，你會觀寫到卷積的結果會出現不同的變化。)
          <br />
          回答格式： 最終調整後的pixel數值：[數值]，調整方式說明：[...]
        </div>
        <button
          onClick={() =>
            // SubmitAnswerAPI(localStorage.getItem("userUUID"), q2Ans)
            SubmitAnswerAPI(
              "apiLink",
              "4-1",
              "Convolution",
              "2",
              q2Ans,
              Date(),
              localStorage.getItem("userUUID")
            )
          }
        >
          提交
        </button>

        <textarea
          value={q2Ans}
          onChange={(e) => setQ2Ans(e.target.value)}
          className="w-100"
        ></textarea>

        <Convolution></Convolution>
      </div>

      <div className="question-container ">
        <div className="question-title">
          3.
          小海狸經過了上一個問題的練習後，他對於卷積的應用有了一定的了解，現在他想要進行更進一步的練習。請設計一個濾波器，只用作檢測垂直線條，並進行對其設計進行一個簡單的說明，下方有的原圖經過卷積(正方形)後，只剩下垂直的線條。
          如沒有頭緒，可以先進行下方簡單的練習觀看簡單的濾波器設計對卷積結果的影響。
        </div>
        <EasyEdgeDetection> </EasyEdgeDetection>
        <div>{"-".repeat(150)}</div>
        <div>
          現在你的目標是調整下方的濾波器把原圖經過卷積的圖變成目標圖片。
        </div>
        <button
          onClick={() =>
            // SubmitAnswerAPI(localStorage.getItem("userUUID"), q3Ans)
            SubmitAnswerAPI(
              "apiLink",
              "4-1",
              "Convolution",
              "3",
              q3Ans,
              Date(),
              localStorage.getItem("userUUID")
            )
          }
        >
          提交
        </button>
        <div className="d-flex">
          <div className="ori-img d-flex ">
            <div className="s-c-label">原圖</div>
            <img
              className="m-2 s-img"
              src="/images/square_original.png"
              alt=""
            />
          </div>
          <div className="tar-img d-flex">
            <div className="s-c-label">目標圖片</div>
            <img
              className="m-2 s-c-img target-img"
              src="/images/square_Sobel_X_Filter.png"
              alt=""
            />
          </div>
        </div>

        <XEdgeDetectPractice
          q3Ans={q3Ans}
          setQ3Ans={setQ3Ans}
        ></XEdgeDetectPractice>
      </div>

      <div></div>
    </div>
  );
}

function CH4_1ConvolPracticeSection() {
  const [state, setState] = useState({
    clickX: 0,
    clickY: 0,
    hoverX: 0,
    hoverY: 0,
    imgSelected: "circle",
    filterSelected: "median",
  });

  const getClickedXY = (X, Y) => {
    setState({
      ...state,
      clickX: X,
      clickY: Y,
    });
  };

  const getHoverXY = (X, Y) => {
    setState({
      ...state,
      hoverX: X,
      hoverY: Y,
    });
  };

  const sendOvImgSelected = (imgName) => {
    setState({
      ...state,
      imgSelected: imgName,
    });
  };

  const sendOvFilterSelected = (FilterName) => {
    setState({
      ...state,
      filterSelected: FilterName,
    });
  };

  return (
    <>
      <div className="title-container">
        <div className="title">卷積與濾波器</div>
        <div className="subtitle">Convolution And Filter</div>
      </div>
      <div className="bullet-point"> • 模擬說明</div>
      <div className="simulation-description">
        小海狸想要了解卷積的作用，他希望透過卷積來進行邊緣檢測，並且想要研究不同濾波器對卷積結果的影響。這裡提供了有關
        Median 和 Laplacian
        濾波器的練習，請點擊觀看卷積過程。(請注意值距離0越遠的像素越可能是邊緣，因為邊緣通常有較大的變化。例如以下方用Laplacian檢測為例，如果像素值經過卷積後是510或-510，都可能被視為邊界。相反地，卷積後的值為0的像素則不會是邊緣，因為變化很小。)
      </div>
      <section className="p-select-section d-flex justify-content-center">
        <SelectSection
          state={state}
          setState={setState}
          sendOvImgSelected={sendOvImgSelected}
          sendOvFilterSelected={sendOvFilterSelected}
        />
      </section>
      <div className="main-practice-section">
        <section className="p-convol-overview">
          <div>
            <div className="filter-container">
              <div className="convol-simulation-title">濾波器</div>
              <SelectedFilter filterSelected={state.filterSelected} />
            </div>
            <div className="d-flex">
              <div className="select-img-container">
                <div className="convol-simulation-title">輸入圖片(80, 80)</div>
                <SelectedImage
                  getClickedXY={getClickedXY}
                  getHoverXY={getHoverXY}
                  imgSelected={state.imgSelected}
                />
              </div>
              <div className="covoled-img-container">
                <div className="convol-simulation-title">
                  卷積後圖片(78, 78)
                </div>
                <ConvolResult
                  clickX={state.clickX}
                  clickY={state.clickY}
                  hoverX={state.hoverX}
                  hoverY={state.hoverY}
                  imgSelected={state.imgSelected}
                  filterSelected={state.filterSelected}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="p-convol-section">
          <div className="d-flex w-100">
            <LeftGraphicSection
              clickX={state.clickX}
              clickY={state.clickY}
              imgSelected={state.imgSelected}
              filterSelected={state.filterSelected}
            />
          </div>
          <div className="d-flex">
            <MiddleMathSection
              clickX={state.clickX}
              clickY={state.clickY}
              imgSelected={state.imgSelected}
              filterSelected={state.filterSelected}
            />
            <RightAnswerSection
              clickX={state.clickX + 1}
              clickY={state.clickY + 1}
              imgSelected={state.imgSelected}
              filterSelected={state.filterSelected}
            />
          </div>
        </section>
      </div>

      <CNNPracticeSection></CNNPracticeSection>
    </>
  );
}

export default CH4_1ConvolPracticeSection;
