import React, { useRef } from 'react'
import { useState, useEffect } from 'react'

import './GradientDescent.css'
import './optimizer.css'
import * as d3 from 'd3'
import { index } from 'd3'

import { scaleSequential } from 'd3'
import { interpolateViridis } from 'd3-scale-chromatic'
import * as math from 'mathjs'
import { debounce } from 'lodash'
import { recordAPIAction, SubmitAnswerAPI, recordAPI } from '../../utils/recordSubmitAPI'

// 然后可以直接调用这些函数
// async function exampleFunction() {
//   await recordAPIAction('actionName', 'id', 'uuid');
//   await SubmitAnswerAPI('uuid', 'answer');
//   const response = await sendRequest('id', 'uuid');
//   console.log(response);
// }

// exampleFunction();



// async function recordAPIAction (actionName, id, uuid) {
//   try {
//     console.log(actionName, id)
//     console.log("localStorage.getItem('userUUID')", uuid)

//     const response = await sendRequest(id, uuid)
//     console.log("Response:", response)
//   } catch (error) {
//     console.error("Error:", error)
//   }
// }

// async function SubmitAnswerAPI (uuid, answer) {
//   try {
//     console.log("SubmitAnswerAPI", uuid, answer)
//     const response = await sendRequest(uuid, answer)
//     console.log("Response:", response)
//   } catch (error) {
//     console.error("Error:", error)
//   }
// }


// async function sendRequest (id, uuid) {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve({ message: "Request successful" })
//     }, 1000)
//   })
// }

function parseFormula (formula) {
  try {
    const parsedFunction = new Function("x", `return ${formula}`)
    return parsedFunction
  } catch (error) {
    console.error('Formula parsing error:', error)
    return (x) => 0
  }
}
function sigmoid (x, a, b, c) {
  return `${c} * (1 / (1 + exp(-(${a} * ${x} + ${b}))))`
}


function parseFormulaForD (formula) {
  const math = require('mathjs')
  try {
    const parsedFunction = math.compile(formula)
    return parsedFunction
  } catch (error) {
    console.error('Formula parsing error:', error)
    return (x) => 0
  }
}
function ControlPanel (props) {
  const [xPoint, setXpoint] = useState(1)
  // const [epochs, setEpochs] = useState(50)
  const [learningRate, setLearningRate] = useState(0.1)
  const [optimizer, setOptimizer] = useState("0")
  const [momentum, setMomentum] = useState(0.5)
  const [decayValue, setDecayValue] = useState(0.9)


  const generateInputBox = (name, index, element_id) => {
    // 設定參數變更的事件
    const handleOnChange = (event) => {
      // recordAPIAction("SelectionOnChangeRecord", element_id, localStorage.getItem('userUUID'))
      recordAPI("apiLink", "3-3", "RMSprop", element_id, event.target.value, Date(), localStorage.getItem('userUUID'))

      const newValue = event.target.value === "" ? 0.1 : event.target.value
      // props.setPrevXpoint([1])

      switch (index) {
        case 0:
          setXpoint(newValue)
          props.setXpoint(newValue)
          break
        case 1:
          props.setEpochs(newValue)
          break
        case 2:
          setLearningRate(parseFloat(newValue))
          break
        case 3:
          setOptimizer(newValue)
          break
        case 4:
          setMomentum(newValue)
          break
        case 5:
          setDecayValue(newValue)
          break
        case 6:
          if (newValue === "0") {

            props.setFormula("x/5)^4 + x/20 + 3")
          }
          else if (newValue === "1") {
            props.setFormula(`${sigmoid("3x", 0.3, 3, 2)} - ${sigmoid("x", 1, 5, 10)} + ${sigmoid("x", 0.45, -10, 25)} + 10`)
          }
          else {
            props.setFormula(`(${sigmoid("x", 0.4, -9.5, 30)}  - ${sigmoid("x", 0.3, -5, 13)} + ${sigmoid("x", 0.8, -8.5, 30)} - ${sigmoid("x", 0.25, 5, 25)} + 28) / 1.2`)
          }
          //  - ${sigmoid("x", 1.1, 10, 10)} - ${sigmoid("x", 1, -6, 10)}
          // console.log(props.formula)
          break
        default:
          break
      }
    }

    const getValue = () => {
      switch (index) {
        case 0:
          return xPoint
        case 1:
          return props.epochs
        case 2:
          return learningRate
        case 3:
          return optimizer
        case 4:
          return momentum
        case 5:
          return decayValue
        default:
          break
      }
    }

    // 根据 index 选择输入框类型
    const inputBox =
      index === 3 ? (
        // 选择框
        <select
          className="input-box"
          onChange={handleOnChange}
          value={getValue()}
        >
          <option value="0">Gradient Descent</option>
          {/* <option value="1">Momentum</option> */}
          <option value="2">RMSprops</option>
          {/* <option value="3">Adam</option> */}
          {/* 添加更多选项 */}
        </select>
      ) : index === 6 ? (

        <select
          className="input-box"
          onChange={handleOnChange}
          value={getValue()}
        >
          <option value="0">任務1</option>
          <option value="1">任務2</option>
          <option value="2">任務3</option>
          <option value="3">任務4</option>

        </select>
      ) : (
        // 数字输入框
        <input
          className="input-box"
          type="number"
          step={0.1}
          max={1}
          min={0}
          value={getValue()}
          onChange={handleOnChange}
          disabled={true}

        />

      )

    return (
      <div className="input-container" key={index} style={{ display: optimizer === "0" && index === 5 ? "none" : "block" }}>
        <div className="input-box-name" >{name}</div>
        {inputBox}
      </div>
    )
  }




  return (
    <div className="control-pannel-container">
      {/* {generateInputBox("任務", 6)} */}
      {generateInputBox("優化器", 3, "OptimizerId")}

      {/* {generateInputBox("初始值", 0)} */}
      {/* {generateInputBox("Epochs", 1)} */}
      {generateInputBox("學習率", 2, "LearningRateId")}
      {/* {generateInputBox("動量值:", 4)} */}
      {generateInputBox("衰減率", 5, "DecayValueId")}
      <button className="apply-button bg-black btn-dark text-white rounded-pill h-100"
        onClick={() => props.updateAllParameter(props.xPoint, props.epochs, learningRate, optimizer, 13, momentum, decayValue)}
      >套用
      </button>


    </div>
  )
}

function DescriptionArea () {
  return (
    <div className='descritpion-container'>
      機器人一號想透過訓練模型預測人的體脂率，為了提升模型的準確性，試著調整不同的權重參數，觀察這些變化對於模型損失值的影響。
    </div>
  )
}


function ProgressControlPanel (props) {
  const [forwardEpochs, setForwardEpochs] = useState(1)

  // const [formula, setFormula] = useState("x * x + x + 1")
  // const [prevClickTime, setPrevClickTime] = useState(0)

  // const [xPoint, setXPoint] = useState(13)
  // const [prevXpoint, setPrevXpoint] = useState([20])



  const handleForward = () => {
    // recordAPIAction("progressForwardRecord", "progressForwardId", localStorage.getItem('userUUID'))
    recordAPI("apiLink", "3-3", "Momentum", "progressForwardRecord", null, Date(), localStorage.getItem('userUUID'))



    const math = require('mathjs')
    const learningRate = (props.learningRate)



    const node = math.parse(props.formula)
    const derivativeNode = math.derivative(node, 'x')

    const derivativeCompiled = derivativeNode.compile()


    let newXpoint = props.xPoint


    let pointList = []

    // Momentum
    let beta1 = props.momentum
    let prevV = props.prevMoementum[props.prevMoementum.length - 1]
    let MList = []

    // RMSProps
    let RMSList = []
    let prevSquaredGradient = props.prevSquaredGradient[props.prevSquaredGradient.length - 1]

    // Adam
    const beta2 = props.decayValue // RMSprop 的衰减率
    const epsilon = 1e-8


    for (let i = 0; i < forwardEpochs; i++) {
      const derivativeResult = derivativeCompiled.evaluate({ x: newXpoint })
      if (parseInt(props.optimizer) === 0) {

        newXpoint = newXpoint - derivativeResult * learningRate
        console.log(newXpoint)
      } else if (parseInt(props.optimizer) === 1) {

        const m = beta1 * prevV + derivativeResult * learningRate

        newXpoint = newXpoint - m

        MList = [...MList, m]
        prevV = m
        // props.setPrevMoementum((prev) => [...prev, v]);
      } else if (props.optimizer === "2") {
        // RMSprop

        const epsilon = 1e-8
        const decayRate = props.decayValue

        const gradient = derivativeResult
        const squaredGradient = gradient * gradient


        const movingAvgSquaredGradient = decayRate * prevSquaredGradient + (1 - decayRate) * squaredGradient
        console.log("movingAvgSquaredGradient", movingAvgSquaredGradient)


        newXpoint = newXpoint - learningRate * gradient / Math.sqrt(movingAvgSquaredGradient + epsilon)

        RMSList = [...RMSList, movingAvgSquaredGradient]
        prevSquaredGradient = movingAvgSquaredGradient

        // props.setPrevSquaredGradient(((prev) => [...prev, movingAvgSquaredGradient]));
      } else if (parseInt(props.optimizer) === 3) {
        // Adam

        // const beta1 = props.momentum; // momentum 的衰减率
        // const beta2 = props.decayValue; // RMSprop 的衰减率
        // const epsilon = 1e-8;


        const gradient = derivativeResult
        const squaredGradient = gradient * gradient

        const prevMomentum = prevV
        const momentum = beta1 * prevMomentum + (1 - beta1) * gradient
        MList = [...MList, momentum]
        prevV = momentum
        // props.setPrevMoementum((prev) => [...prev, momentum]);


        const movingAvgSquaredGradient = beta2 * prevSquaredGradient + (1 - beta2) * squaredGradient
        RMSList = [...RMSList, movingAvgSquaredGradient]
        prevSquaredGradient = movingAvgSquaredGradient
        // props.setPrevSquaredGradient((prev) => [...prev, movingAvgSquaredGradient]);

        const correctedMomentum = momentum / (1 - Math.pow(beta1, props.prevMoementum.length + MList.length - 1))
        const correctedMovingAvgSquaredGradient = movingAvgSquaredGradient / (1 - Math.pow(beta2, props.prevSquaredGradient.length + RMSList.length - 1))

        newXpoint = newXpoint - learningRate * correctedMomentum / (Math.sqrt(correctedMovingAvgSquaredGradient) + epsilon)
      } else {

        console.error("Unknown optimizer value:", props.optimizer)
      }
      pointList = [...pointList, newXpoint]
    }

    if (parseInt(props.optimizer) === 1) {
      props.setPrevMoementum((prev) => [...prev, ...MList])
    }
    else if (parseInt(props.optimizer) === 2) {
      props.setPrevSquaredGradient(((prev) => [...prev, ...RMSList]))
    }
    else if (parseInt(props.optimizer) === 3) {
      props.setPrevMoementum((prev) => [...prev, ...MList])
      props.setPrevSquaredGradient(((prev) => [...prev, ...RMSList]))
    }

    props.setXPoint(newXpoint)
    props.setPrevXpoint((prev) => [...prev, ...pointList])
    // props.setXPoint(newXpoint);
    // props.setPrevXpoint((prev) => [...prev, newXpoint]);
    // setXPoint(newXpoint);
  }

  const handleBackward = () => {
    if (props.prevXpoint.length > 1) {
      // props.setXPoint(prevXpoint[prevXpoint.length - 2]); // get the second to last element
      props.setXPoint(props.prevXpoint[props.prevXpoint.length - 2])
      props.setPrevXpoint((prev) => prev.slice(0, -1)) // remove the last element
      //Handle Momentum Backward
      if (props.optimizer === "1" && props.prevMoementum.length > 1) {
        props.setPrevMoementum((prev) => prev.slice(0, -1))
      }
    } else {
      console.log("Cannot go backward, not enough elements in prevXpoint")
    }

  }

  return (
    <div className='progress-control-container'>
      {<button className='progress-button' onClick={handleForward}>➡️</button>}
      {/* <button className='progress-button' onClick={handleForward}>⬅️</button>
        <button className='progress-button' onClick={handleBackward}>➡️</button> */}
      <div className='epoch-count w-150'>epoch: {props.prevXpoint.length}
      </div>

      <div className='progress-label'>向前步數:</div>
      <input className='progress-input' value={forwardEpochs} type="number" onChange={(event) => { setForwardEpochs(event.target.value) }} />
      <button
        className='m-2'
        onClick={() => {
          if (window.confirm("確定要提交嗎？")) {
            SubmitAnswerAPI(
              "3-3",
              "RMSprop",
              "1",
              null,
              Date(),
              localStorage.getItem('userUUID'))
          }
        }}

        disabled={props.xPoint < -1.9 && props.xPoint > -2.1 ? false : true}
      >
        提交
      </button>
    </div>
  )
}



function generateNumbersWithSum (targetSum, count) {
  if (count <= 0 || targetSum <= 0) {
    console.error("輸入參數應為正數且數量應大於零")
    return []
  }

  const numbers = []

  for (let i = 0; i < count - 1; i++) {
    const randomNumber = Math.random() + Math.random() * 0.05 // 加入一些誤差
    numbers.push(randomNumber)
  }

  const total = numbers.reduce((acc, num) => acc + num, 0)
  const normalizedNumbers = numbers.map((num) => num / total)
  const scaledNumbers = normalizedNumbers.map((num) => num * targetSum)
  const GTList = normalizedNumbers.map(() => Math.random() * 10)
  const PredictList = GTList.map((num, index) => num + scaledNumbers[index])

  return { scaledNumbers, GTList, PredictList }
}

const GradientDivs = (props) => {
  const [loss, setLoss] = useState("-")
  const [predictValue, setPredictValue] = useState("-")
  const [groundTrue, setGroundTrue] = useState("-")
  const [selectedElement, setSelectedElement] = useState(null)

  const [divValues, setDivValues] = useState([])
  const [predictList, setPredictList] = useState([])
  const [GTList, setGTList] = useState([])

  useEffect(() => {
    const formula = math.parse(props.formula)
    const compiledFormula = formula.compile()
    const targetSum = compiledFormula.evaluate({ x: props.xPoint })
    const count = 100
    const { scaledNumbers, GTList, PredictList } = generateNumbersWithSum(
      targetSum,
      count
    )

    setDivValues(scaledNumbers)
    setGTList(GTList)
    setPredictList(PredictList)

  }, [props.prevXpoint])

  const lossOnClick = (index) => {
    setLoss(divValues[index].toFixed(3))
    setPredictValue(predictList[index].toFixed(3))
    setGroundTrue(GTList[index].toFixed(3))
    setSelectedElement(index)
  }

  return (
    <>
      <div className="data-container">
        <div>以下每個欄位代表對資料庫中的每一筆數據進行預測的正確率。試著觀察在不同的權重下，預測的體脂率有什麼樣的變化。</div>
        <div className="loss-detail-container">
          <div className="loss-container">損失值: {loss}</div>
          <div className="predict-container">預測值: {predictValue}</div>
          <div className="ground-container">正確值: {groundTrue}</div>
        </div>
        {divValues.map((value, index) => {
          const opacity = 1 - value / 0.5
          const greenColor = `rgba(0, 255, 0, ${opacity.toFixed(3)})`

          return (
            <div
              key={index}
              className={`gradient-div ${selectedElement === index ? 'selected' : ''}`}
              style={{
                backgroundColor: greenColor,
              }}
              onClick={() => lossOnClick(index)}
            >
              {value.toFixed(3)}
            </div>
          )
        })}
        <div className="color-map">
          <div className="color-map-label">顏色映射:</div>
          <div className="color-map-gradient">
            {Array.from({ length: 11 }).map((_, index) => {
              const value = (index + 1) * 0.05
              const opacity = 1 - value / 0.5
              const greenColor = `rgba(0, 255, 0, ${opacity.toFixed(3)})`

              return (
                <div
                  key={index}
                  className="color-map-bar"
                  style={{
                    backgroundColor: greenColor,
                  }}
                >
                  {(index) % 5 === 0 ? value.toFixed(2) : ""}
                </div>
              )
            })}
          </div>
        </div>
      </div>



    </>
  )
}


function GDVisulization (props) {
  const svgRef = useRef(null)
  const debouncedRecordAPIActionRef = useRef(null)

  // Initialize zoom state and set it as a state variable
  const [zoomState, setZoomState] = useState(d3.zoomIdentity)
  // useEffect(() => {
  //   const debouncedRecordAPIAction = debounce(() => {

  //   }, 1000);
  //   debouncedRecordAPIActionRef.current = debouncedRecordAPIAction;
  // }, []);




  useEffect(() => {
    const math = require('mathjs')

    const svg = d3.select(svgRef.current)
    const width = +svg.attr('width')
    const height = +svg.attr('height')
    const formula = math.parse(props.formula)
    const compiledFormula = formula.compile()
    const debouncedRecordAPIAction = debouncedRecordAPIActionRef.current

    const loss = compiledFormula.evaluate({ x: props.xPoint })

    props.setLoss(loss)


    svg.selectAll('*').remove()



    const x = d3.scaleLinear()
      .domain([-15, 15])
      .range([-1, width + 1])

    const y = d3.scaleLinear()
      .domain([60, 0])
      .range([-1, height + 1])


    const xAxis = d3.axisBottom(x)
      .ticks(((width + 2) / (height + 2)) * 10)
      .tickSize(height)
      .tickPadding(-12)

    const yAxis = d3.axisRight(y)
      .ticks(10)
      .tickSize(width)
      .tickPadding(8 - width)

    const lineGenerator = d3
      .line()
      .x(d => x(d.x))
      .y(d => y(d.y))

    const gX = svg.append("g")
      .attr("class", "axis axis--x")
      .call(xAxis)


    const point = svg.append("circle")
      .attr('cx', x(props.xPoint))
      .attr('cy', y(loss))
      .attr('r', 10)



    gX.selectAll("line")
      .style("opacity", 0.2)

    const gY = svg.append("g")
      .attr("class", "axis axis--y")
      .call(yAxis)


    gY.selectAll("line")
      .style("opacity", 0.2)


    const pathF = svg.append("g")
      .append('path')
      .datum(d3.range(-450, 400, 0.1).map((x) => ({ x, y: compiledFormula.evaluate({ x: x }) })))
      .attr('fill', 'none')
      .attr('stroke', 'blue')
      .attr('stroke-width', 2)
      .attr('d', lineGenerator)

    const zoom = d3.zoom()
      .scaleExtent([1, 5])
      .translateExtent([[-20, -100], [width + 20, height + 20]])
      .filter(filter)
    // .on("zoom", zoomed)

    // Restore the saved zoom state
    svg.call(zoom.transform, zoomState)

    function zoomed (event) {
      const { transform } = event
      gX.call(xAxis.scale(transform.rescaleX(x)))
      gY.call(yAxis.scale(transform.rescaleY(y)))

      gX.selectAll("line")
        .style("opacity", 0.2)
      gY.selectAll("line")
        .style("opacity", 0.2)

      pathF.attr("stroke-width", 2 / transform.k)
      pathF.attr("transform", transform)
      point.attr("r", 5 / transform.k)
      point.attr("transform", transform)

      debouncedRecordAPIAction()
      // Save the current zoom state
      setZoomState(transform)
    }

    function reset () {
      svg.transition()
        .duration(750)
        .call(zoom.transform, d3.zoomIdentity)
    }

    function filter (event) {
      event.preventDefault()
      return (!event.ctrlKey || event.type === 'wheel') && !event.button
    }

    svg.call(zoom)

  }, [props.xPoint, props.formula])

  return (
    <div>
      <div className='gd-lable-container'>
        <div className='gd-x-label'>損失值</div>
        <svg
          className='gradient-descent-network-graph'
          ref={svgRef}
          width={1000}
          height={700}
          style={{ border: '1px solid gray' }}
        ></svg>
      </div>
      <div className='gd-y-label'>權重W</div>
    </div>
  )
}



function CH3_3RMSpropSection () {
  // const [formula, setFormula] = useState("x*x + x + 1")

  const [formula, setFormula] = useState(`(x/5)^4 + x/20 + 3-10exp(-10*(x+2)^2) + 10`)


  const [xPoint, setXpoint] = useState(13)
  const [epochs, setEpochs] = useState(50)
  const [learningRate, setLearningRate] = useState(0.1)
  const [optimizer, setOptimizer] = useState("0")
  const [prevXpoint, setPrevXpoint] = useState([1])
  const [loss, setLoss] = useState(math.parse(formula).compile().evaluate({ x: xPoint }))

  const [momentum, setMomentum] = useState(0.5)
  const [decayValue, setDecayValue] = useState(0.9)

  //Optimizer
  const [prevMoementum, setPrevMoementum] = useState([0])
  const [prevSquaredGradient, setPrevSquaredGradient] = useState([0])

  const updateAllParameter = (point, epochs, lr, s_optimizer, oriXpoint, s_momentum, s_decay) => {
    setXpoint(parseInt(oriXpoint))
    setEpochs(epochs)
    setLearningRate(lr)
    setOptimizer(s_optimizer)
    setPrevXpoint([oriXpoint])
    setMomentum(s_momentum)
    setPrevMoementum([0])
    setDecayValue(s_decay)
    setPrevSquaredGradient([0])
  }

  return (
    <>
      {/* <DescriptionArea></DescriptionArea> */}
      <div className='title-container'>
        <div className='title'>梯度下降</div>
        <div className='subtitle'>RMSprop</div>
      </div>
      <div className="bullet-point"> • 模擬說明</div>
      <div className='simulation-description'>
        小海狸發現不同的優化器對於模型的訓練會有一定的影響。例如，RMSprop是一種優化演算法，主要作用在於改進梯度下降，使得模型的訓練更加穩定。它通過根據梯度平方值的遞減平均，動態調整每個參數的學習速率，有助於應對不同參數的梯度變化，提高模型的訓練效果。
        小海狸建議嘗試使用gradient descent和RMSprop在不同任務上，觀察不同的優化器在面對不同狀況時的效果。<br />
        補充說明: 衰減率一般會設定有0.8~0.999，目前會先固定在0.9</div>

      <div className='gd-control-panel-container'>
        <ControlPanel
          formula={formula}
          setFormula={setFormula}
          updateAllParameter={updateAllParameter}
          xPoint={xPoint}
          setXpoint={setXpoint}
          prevXpoint={prevXpoint}
          setPrevXpoint={setPrevXpoint}
          epochs={epochs}
          setEpochs={setEpochs}
          learningRate={learningRate}
          setLearningRate={setLearningRate}
          optimizer={optimizer}
          setOptimizer={setOptimizer}
          momentum={momentum}
          setMomentum={setMomentum}
          decayValue={decayValue}
          setDecayValue={setDecayValue}
        >
        </ControlPanel>


      </div>
      <div className='main-vis-container d-flex justify-content-center align-items-center flex-column'>
        <div className="practice-bullet-point text-center">
          {optimizer === "0" ? "Gradient Descent" : "RMSprop"} <br />
          lr: {learningRate}, Decay: {optimizer === "0" ? "-" : decayValue}
        </div>
        <ProgressControlPanel
          formula={formula}
          setFormula={setFormula}
          xPoint={xPoint}
          setXPoint={setXpoint}
          prevXpoint={prevXpoint}
          setPrevXpoint={setPrevXpoint}
          epochs={epochs}
          setEpochs={setEpochs}
          learningRate={learningRate}
          setLearningRate={setLearningRate}
          optimizer={optimizer}
          setOptimizer={setOptimizer}
          momentum={momentum}
          setMomentum={setMomentum}
          decayValue={decayValue}
          setDecayValue={setDecayValue}
          prevMoementum={prevMoementum}
          setPrevMoementum={setPrevMoementum}
          prevSquaredGradient={prevSquaredGradient}
          setPrevSquaredGradient={setPrevSquaredGradient}
        ></ProgressControlPanel>

        <div className='d-flex'>
          <table className='optimizer-info-table' border="1">
            <tr>
              <td>優化器</td>
              <td>{optimizer === "0" ? "Gradient Descent" : "RMSprop"}</td>
            </tr>
            <tr>
              <td>學習率</td>
              <td>{learningRate}</td>
            </tr>
            <tr>
              <td>衰減率</td>
              <td>{optimizer === "0" ? "-" : decayValue}</td>
            </tr>
            <tr>
              <td>Epoch</td>
              <td>{prevXpoint.length}</td>
            </tr>
            <tr>
              <td>權重</td>
              <td>{xPoint.toFixed(4)}</td>
            </tr>
            <tr>
              <td>損失值</td>
              <td>{(loss).toFixed(4)}</td>
            </tr>
          </table>

          <GDVisulization
            formula={formula}
            xPoint={xPoint}
            loss={loss}
            setLoss={setLoss}

          ></GDVisulization>
        </div>

      </div>
    </>
  )

}


export default CH3_3RMSpropSection