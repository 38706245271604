import React, { useState } from 'react'

function LoginForm(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(true)
  const [uuid, setUUID] = useState('')


  // const simulateLogin = (apiUrl ,email, password) => {
  //   const fakeUUID = '123456789'
  //   return {
  //     success: true,
  //     uuid: fakeUUID
  //   }
  // }

  const simulateLogin = async (apiUrl, email, password) => {
    const requestData = {
      account: email,
      pwd: password
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return responseData;
    } catch (error) {
      console.error('API error:', error);
      alert('Wrong email or password. Please try again.');
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    const loginResult = simulateLogin("https://mlapi.teliclab.info/resource/api/v1/getUser", email, password)
      .then(responseData => {                                                                
        const success = responseData.uuid ? true : false;  
        if (success) {
          const uuid = responseData.uuid;
          setUUID(uuid)
          props.setIsLoggedIn(true)
          localStorage.setItem('userUUID', uuid)
          console.log('Logged in successfully. UUID:', localStorage.getItem('userUUID'))
        } else {
          console.log('Login failed. Please check your credentials.')
        }
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Email input */}
      <div className="form-outline mb-4">
        <input
          type="email"
          id="form2Example1"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label className="form-label" htmlFor="form2Example1">
          Email address
        </label>
      </div>

      {/* Password input */}
      <div className="form-outline mb-4">
        <input
          type="password"
          id="form2Example2"
          className="form-control"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label className="form-label" htmlFor="form2Example2">
          Password
        </label>
      </div>

      {/* Remember me checkbox */}
      <div className="form-check d-flex justify-content-between mb-4">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="form2Example3"
          checked={rememberMe}
          onChange={() => setRememberMe(!rememberMe)}
        />
        <label className="form-check-label" htmlFor="form2Example3">
          Remember me
        </label>
      </div>

      {/* Submit button */}
      <button type="submit" className="btn btn-primary btn-block mb-4">
        Sign in
      </button>

      {/* Display UUID if available */}
      {props.isLoggedIn && ( // 只有在用户已登录时才显示 UUID
        <div className="alert alert-success" role="alert">
          Logged in successfully. UUID: {uuid}
        </div>
      )}
    </form>
  )
}

export default LoginForm
