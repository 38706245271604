import React, { useRef, useEffect } from "react"
import { useState } from "react"
import { v4 as uuid } from "uuid"
import "./Pooling.css"
import {
  recordAPIAction,
  SubmitAnswerAPI,
  sendRequest,
  recordAPI,
} from "../../utils/recordSubmitAPI"

const transferNum2Color = (num) => {
  let mainColor
  let alpha = 1

  if (num >= 129 && num <= 255) {
    // Blue range
    mainColor = "blue"
    alpha = (num - 129) / (255 - 129) // Transparency increases as value approaches 255
  } else {
    // Green range
    mainColor = "green"
    alpha = 1 - num / 127 // Transparency increases as value approaches 0
  }

  return mainColor === "blue"
    ? `rgba(0, 176, 255 , ${alpha})`
    : `rgba(1, 237, 1, ${alpha})`
}

const drawColorMap = (ctx, scale) => {
  const colorMap = []
  for (let i = 0; i <= 255; i++) {
    let mainColor
    let alpha = 1

    if (i >= 129 && i <= 255) {
      // Blue range
      mainColor = "blue"
      alpha = (i - 129) / (255 - 129) // Transparency increases as value approaches 255
    } else {
      // Green range
      mainColor = "green"
      alpha = 1 - i / 127 // Transparency increases as value approaches 0
    }
    if (mainColor === "blue") {
      colorMap.push(`rgba(0, 176, 255 , ${alpha})`)
    } else if (mainColor === "green") {
      colorMap.push(`rgba(1, 237, 1, ${alpha})`)
    }
  }

  colorMap.forEach((color, index) => {
    ctx.fillStyle = color
    ctx.fillRect(index * scale, 0, scale, scale)
  })
}

function ControlPanel (props) {
  const generateSelectBox = (name, options, index) => {
    const handleSelectChange = (event) => {
      const selection = event.target.value

      if (selection === "Max Pooling") {
        props.setPoolingMethod("max")
      } else if (selection === "Average Pooling") {
        props.setPoolingMethod("avg")
      }
    }
    const selectBox = (
      // 下拉框
      <select className="select-box w-100" onChange={handleSelectChange}>
        {options.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
    )

    return (
      <div className="select-container" key={index}>
        <select className="select-box w-100" onChange={handleSelectChange}>
          {options.map((option, optionIndex) => (
            <option key={optionIndex} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    )
  }

  const handleInputFileChange = async (event, imagePath, index) => {
    try {
      // recordAPIAction(
      //   "Pooling-img-select",
      //   `Image-${index}`,
      //   localStorage.getItem("userUUID")
      // );
      recordAPI("apiLink", "4-2", "Pooling", `4-2_Image-${index}`, null, Date(), localStorage.getItem('userUUID'))
      // 使用fetch获取图像文件的Blob数据
      const response = await fetch(imagePath)
      const blob = await response.blob()

      // 创建File对象
      const file = new File([blob], "image.jpg", { type: "image/jpeg" })

      // 将文件设置为输入文件
      props.setInputFile(file)
    } catch (error) {
      console.error("Error fetching image:", error)
    }
  }

  const poolingMethods = ["Max Pooling", "Average Pooling"]
  const poolingImgPath = [
    "/images/Ch4/4-2 Pooling/4-2 grayscaleCat.jpg",
    "/images/Ch4/4-2 Pooling/4-2 grayscaleDinosaur.jpg",
    "/images/Ch4/4-2 Pooling/4-2 grayscaleDog.jpg",
    "/images/Ch4/4-2 Pooling/4-2 grayscalePixelDog.jpeg",
  ]
  // const paddingOptions = ['Valid Padding', 'Same Padding'];

  return (
    <div className="pooling-control-panel-container">
      <div className="pooling-simulation-title">池化方法</div>
      <div className="all-select-container">
        {generateSelectBox("池化方法", poolingMethods, 0)}
      </div>
      <div className="pooling-simulation-title">{props.title}</div>
      <div className="all-select-container">
        {/* {generateSelectBox('池化方法', poolingMethods, 0)} */}
        <div
          className="select-image-container"
          onClick={(event) =>
            handleInputFileChange(event, poolingImgPath[0], 0)
          }
        >
          <img className="p-image-option" src={poolingImgPath[0]} alt="" />
        </div>
        <div
          className="select-image-container"
          onClick={(event) =>
            handleInputFileChange(event, poolingImgPath[1], 1)
          }
        >
          <img className="p-image-option" src={poolingImgPath[1]} alt="" />
        </div>
        <div
          className="select-image-container"
          onClick={(event) =>
            handleInputFileChange(event, poolingImgPath[2], 2)
          }
        >
          <img className="p-image-option" src={poolingImgPath[2]} alt="" />
        </div>
        <div
          className="select-image-container"
          onClick={(event) =>
            handleInputFileChange(event, poolingImgPath[3], 3)
          }
        >
          <img className="p-image-option" src={poolingImgPath[3]} alt="" />
        </div>

        {/* {props.input === true ?
          <div className='select-container'>
            <div className="select-box-name">圖片上傳</div>
            <input onChange={(event) => handleInputFileChange(event)} type='file' accept='image/*' />
          </div>
          :
          <></>} */}
      </div>
    </div>
  )
}
const generateMatrixFromImage = (image, newSize) => {
  const canvas = document.createElement("canvas")
  canvas.width = newSize
  canvas.height = newSize
  const ctx = canvas.getContext("2d")
  ctx.drawImage(image, 0, 0, newSize, newSize)

  const matrix = []
  for (let y = 0; y < newSize; y++) {
    const row = []
    for (let x = 0; x < newSize; x++) {
      const pixelData = ctx.getImageData(x, y, 1, 1).data
      const grayscaleValue = (pixelData[0] + pixelData[1] + pixelData[2]) / 3
      row.push(grayscaleValue)
    }
    matrix.push(row)
  }
  return matrix
}

function PoolingInputImageVisualization (props) {
  const canvasOriginalRef = useRef()
  const canvasPooledRef = useRef()
  const hoveredFilterAreaStyle = {
    top: `${props.hoverY * 10}px`,
    left: `${props.hoverX * 10}px`,
  }

  const hoveredResultFilterAreaStyle = {
    top: `${(props.hoverY * 10) / 2}px`,
    left: `${(props.hoverX * 10) / 2}px`,
  }

  useEffect(() => {
    const drawMatrix = (matrix, ctx, scale) => {
      const rectSize = 5 // Size of each rectangle in the drawn matrix
      ctx.clearRect(0, 0, matrix[0].length * scale, matrix.length * scale)

      matrix.forEach((row, rowIndex) => {
        row.forEach((value, colIndex) => {
          ctx.fillStyle = `rgb(${value}, ${value}, ${value})`
          ctx.fillRect(
            colIndex * scale,
            rowIndex * scale,
            rectSize * scale,
            rectSize * scale
          )
        })
      })
    }

    const pooling = (matrix, poolingMethod = "max") => {
      const pooledMatrix = []
      const stride = 2

      for (let i = 0; i < matrix.length; i += stride) {
        const row = []
        for (let j = 0; j < matrix[i].length; j += stride) {
          const patch = [
            matrix[i][j],
            matrix[i][j + 1],
            matrix[i + 1][j],
            matrix[i + 1][j + 1],
          ]

          let pooledValue
          console.log(poolingMethod)
          if (poolingMethod === "max") {
            // Max pooling
            pooledValue = Math.max(...patch)
          } else if (poolingMethod === "avg") {
            // Average pooling
            const sum = patch.reduce((acc, val) => acc + val, 0)
            pooledValue = sum / patch.length
          } else {
            throw new Error('Invalid pooling method. Use "max" or "avg".')
          }

          row.push(pooledValue)
        }
        pooledMatrix.push(row)
      }

      return pooledMatrix
    }

    const handleImageUpload = (inputFile, poolingMethod) => {
      const file = inputFile
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const image = new Image()
          image.src = e.target.result
          image.onload = () => {
            const newSize = 30 // Set your desired size
            const resizedMatrix = generateMatrixFromImage(image, newSize)

            const canvasOriginal = canvasOriginalRef.current
            const ctxOriginal = canvasOriginal.getContext("2d")
            const scale = 10 // Scale factor for enlarging the matrix
            drawMatrix(resizedMatrix, ctxOriginal, scale)

            const pooledMatrix = pooling(resizedMatrix, poolingMethod)
            const canvasPooled = canvasPooledRef.current
            const ctxPooled = canvasPooled.getContext("2d")
            const pooledScale = 10 // Scale factor for enlarging the pooled matrix
            drawMatrix(pooledMatrix, ctxPooled, pooledScale)
          }
        }
        reader.readAsDataURL(file)
      }
    }

    handleImageUpload(props.inputFile, props.poolingMethod)
  }, [props.poolingMethod, props.inputFile])

  const handleHover = (event) => {
    const poolSize = 2 // 池化的尺寸，假设为2x2
    const poolX = Math.floor(event.nativeEvent.offsetX / (poolSize * 10))
    const poolY = Math.floor(event.nativeEvent.offsetY / (poolSize * 10))

    // 池化后的位置即为池化区域的左上角顶点
    const poolHoverX = poolX * poolSize
    const poolHoverY = poolY * poolSize

    if (
      poolHoverX >= 0 &&
      poolHoverX <= 28 &&
      poolHoverY >= 0 &&
      poolHoverY <= 28
    ) {
      props.setHoverX(poolHoverX)
      props.setHoverY(poolHoverY)
    }
  }

  return (
    <div className="pooling-result-container">
      {/* <input ref={inputRef} type='file' accept='image/*' /> */}
      <div className="position-relative" onMouseMove={handleHover}>
        <canvas
          className="input-pooling-image"
          ref={canvasOriginalRef}
          width={30 * 10}
          height={30 * 10}
        />
        <div
          className="p-filter-hovered-area d-flex flex-wrap"
          style={hoveredFilterAreaStyle}
        >
          <div className="p-filter-hovered-grid"></div>
          <div className="p-filter-hovered-grid"></div>
          <div className="p-filter-hovered-grid"></div>
          <div className="p-filter-hovered-grid"></div>
        </div>
      </div>
      <div> - - - - - -Pooling- - - - - - </div>
      <div className="position-relative">
        <canvas
          className="input-pooled-image"
          ref={canvasPooledRef}
          width={15 * 10}
          height={15 * 10}
        />
        <div
          className="p-result-filter-hovered-area"
          style={hoveredResultFilterAreaStyle}
        ></div>
      </div>
    </div>
  )
}
function PoolingSimulationVisualization (props) {
  const canvasOriginalRef = useRef()
  const canvasPooledRef = useRef()
  const canvasColorMapRef = useRef()

  const [showPopup, setShowPopup] = useState(false)
  const [sliderValue, setSliderValue] = useState(50)
  const [currBoxNum, setCurrBoxNum] = useState(1)

  const [box1Color, setBox1Color] = useState(255)
  const [box2Color, setBox2Color] = useState(255)
  const [box3Color, setBox3Color] = useState(255)
  const [box4Color, setBox4Color] = useState(255)

  useEffect(() => {
    const generateRandomMatrix = () => {
      const matrix = []
      for (let i = 0; i < 4; i++) {
        const row = []
        for (let j = 0; j < 4; j++) {
          row.push(Math.floor(Math.random() * 256)) // Generate a random grayscale value (0-255)
        }
        matrix.push(row)
      }
      return matrix
    }

    const drawMatrix = (matrix, ctx, scale) => {
      const rectSize = 5 // Size of each rectangle in the drawn matrix
      ctx.clearRect(0, 0, matrix[0].length * scale, matrix.length * scale)

      matrix.forEach((row, rowIndex) => {
        row.forEach((value, colIndex) => {
          let blue, green, alpha
          let mainColor

          if (value >= 129 && value <= 255) {
            // Blue range
            blue = value
            mainColor = "blue"
            alpha = (value - 129) / (255 - 129) // Transparency increases as value approaches 255
          } else {
            // Green range
            green = value
            mainColor = "green"
            alpha = 1 - value / 127 // Transparency increases as value approaches 0
          }
          let color
          if (mainColor === "blue") {
            color = `rgba(0, 176, 255 , ${alpha})`
          } else if (mainColor === "green") {
            color = `rgba(1, 237, 1, ${alpha})`
          }
          // const color = `rgba(0, ${green || 0}, ${blue || 0}, ${alpha || 0})`;
          ctx.fillStyle = color
          ctx.fillRect(
            colIndex * scale,
            rowIndex * scale,
            rectSize * scale,
            rectSize * scale
          )
        })
      })
    }

    const drawColorMap = (ctx, scale) => {
      const colorMap = []
      for (let i = 0; i <= 255; i++) {
        let mainColor
        let alpha = 1

        if (i >= 129 && i <= 255) {
          // Blue range
          mainColor = "blue"
          alpha = (i - 129) / (255 - 129) // Transparency increases as value approaches 255
        } else {
          // Green range
          mainColor = "green"
          alpha = 1 - i / 127 // Transparency increases as value approaches 0
        }
        if (mainColor === "blue") {
          colorMap.push(`rgba(0, 176, 255 , ${alpha})`)
        } else if (mainColor === "green") {
          colorMap.push(`rgba(1, 237, 1, ${alpha})`)
        }
      }

      colorMap.forEach((color, index) => {
        ctx.fillStyle = color
        ctx.fillRect((index * scale) / 2, 0, scale, 5 * scale)
      })
    }

    const pooling = (matrix, poolingMethod = "max") => {
      const pooledMatrix = []
      const stride = 2

      for (let i = 0; i < matrix.length; i += stride) {
        const row = []
        for (let j = 0; j < matrix[i].length; j += stride) {
          const patch = [
            matrix[i][j],
            matrix[i][j + 1],
            matrix[i + 1][j],
            matrix[i + 1][j + 1],
          ]

          let pooledValue
          if (poolingMethod === "max") {
            // Max pooling
            pooledValue = Math.max(...patch)
          } else if (poolingMethod === "avg") {
            // Average pooling
            const sum = patch.reduce((acc, val) => acc + val, 0)
            pooledValue = sum / patch.length
          } else {
            throw new Error('Invalid pooling method. Use "max" or "avg".')
          }

          row.push(pooledValue)
        }
        pooledMatrix.push(row)
      }

      return pooledMatrix
    }

    const randomMatrix = generateRandomMatrix()

    const canvasOriginal = canvasOriginalRef.current
    const ctxOriginal = canvasOriginal.getContext("2d")
    const scale = 5 * 5 // Scale factor for enlarging the matrix
    drawMatrix(randomMatrix, ctxOriginal, scale)

    if (props.practice === false) {
      const pooledMatrix = pooling(randomMatrix, props.poolingMethod)
      const canvasPooled = canvasPooledRef.current
      const ctxPooled = canvasPooled.getContext("2d")
      const pooledScale = 5 * 5 // Scale factor for enlarging the pooled matrix
      drawMatrix(pooledMatrix, ctxPooled, pooledScale)
    }

    const canvasColorMap = canvasColorMapRef.current
    const ctxColorMap = canvasColorMap.getContext("2d")
    const colorMapScale = 2 // Scale factor for enlarging the color map
    drawColorMap(ctxColorMap, colorMapScale)
  }, [props.poolingMethod, props.practice])

  const handleSelectionOnClick = (num) => {
    setCurrBoxNum(num)
  }

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value)
    if (currBoxNum === 1) {
      setBox1Color(event.target.value)
    } else if (currBoxNum === 2) {
      setBox2Color(event.target.value)
    } else if (currBoxNum === 3) {
      setBox3Color(event.target.value)
    } else if (currBoxNum === 4) {
      setBox4Color(event.target.value)
    }
  }

  const transferNum2Color = (num) => {
    let mainColor
    let alpha = 1

    if (num >= 129 && num <= 255) {
      // Blue range
      mainColor = "blue"
      alpha = (num - 129) / (255 - 129) // Transparency increases as value approaches 255
    } else {
      // Green range
      mainColor = "green"
      alpha = 1 - num / 127 // Transparency increases as value approaches 0
    }

    return mainColor === "blue"
      ? `rgba(0, 176, 255 , ${alpha})`
      : `rgba(1, 237, 1, ${alpha})`
  }

  const practiceResultSelection = () => {
    return (
      <>
        <div className="practice-result-selection-container">
          <div className="practice-result-selection-row">
            <div
              className={`practice-result-box box-1 ${currBoxNum === 1 ? "selected-box" : ""
                }`}
              style={{ backgroundColor: transferNum2Color(box1Color) }}
              onClick={() => handleSelectionOnClick(1)}
            >
              1
            </div>
            <div
              className={`practice-result-box box-2 ${currBoxNum === 2 ? "selected-box" : ""
                }`}
              style={{ backgroundColor: transferNum2Color(box2Color) }}
              onClick={() => handleSelectionOnClick(2)}
            >
              2
            </div>
          </div>
          <div className="practice-result-selection-row">
            <div
              className={`practice-result-box box-3 ${currBoxNum === 3 ? "selected-box" : ""
                }`}
              style={{ backgroundColor: transferNum2Color(box3Color) }}
              onClick={() => handleSelectionOnClick(3)}
            >
              3
            </div>
            <div
              className={`practice-result-box box-4 ${currBoxNum === 4 ? "selected-box" : ""
                }`}
              style={{ backgroundColor: transferNum2Color(box4Color) }}
              onClick={() => handleSelectionOnClick(4)}
            >
              4
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="s-pooling-result-container">
      <div className="s-pooling-result-vis-container">
        <canvas
          className="s-pooling-image"
          ref={canvasOriginalRef}
          width={4 * 5 * 5}
          height={4 * 5 * 5}
        />
        <div>- - - - Pooling - - - - </div>

        {props.practice === false ? (
          <canvas
            className="s-pooled-image"
            ref={canvasPooledRef}
            width={2 * 5 * 5}
            height={2 * 5 * 5}
          />
        ) : (
          practiceResultSelection()
        )}
      </div>
      {props.practice === true ? (
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="255"
            value={sliderValue}
            onChange={handleSliderChange}
            className="practice-result-slider"
          />
        </div>
      ) : (
        <></>
      )}
      <canvas className="s-color-map" ref={canvasColorMapRef} />
    </div>
  )
}

function PoolingAvgPractice (props) {
  const [avgProcessArray, setAvgProcessArray] = useState({
    ProcessAns1: [],
    ProcessAns2: [],
    ProcessAns3: [],
    ProcessAns4: [],
  })

  const [result1, setresult1] = useState("bad")
  const [result2, setresult2] = useState("bad")
  const [result3, setresult3] = useState("bad")
  const [result4, setresult4] = useState("bad")

  const canvasColorMapRef = useRef()

  useEffect(() => {
    const canvasColorMap = canvasColorMapRef.current
    const ctxColorMap = canvasColorMap.getContext("2d")
    const colorMapScale = 2 // Scale factor for enlarging the color map
    drawColorMap(ctxColorMap, colorMapScale)
  }, [])

  useEffect(() => {
    props.setq3Ans([result1, result2, result3, result4])
  }, [result1, result2, result3, result4])

  const colorArray = [
    143, 58, 208, 192, 96, 223, 11, 173, 189, 70, 97, 134, 2, 174, 221, 45,
  ]



  // Drag and Drop function
  function dragStart (e) {
    // console.log("dragStart");
    e.dataTransfer.setData("text/plain", e.target.id)
  }

  function dropped (e) {
    cancelDefault(e)

    // recordAPIAction(
    //   "q2-Pooling-avg-dropped",
    //   e.target.id,
    //   localStorage.getItem("userUUID")
    // );
    recordAPI("apiLink", "4-2", "Pooling", "4-2_q2-Pooling-avg-dropped", e.target.id, Date(), localStorage.getItem('userUUID'))
    let id = e.dataTransfer.getData("text/plain")
    let draggedElement = document.querySelector("#" + id)
    let targetContainer = e.target

    // 获取目标容器的索引
    let targetContainerIndex = targetContainer.id.substring(16)

    // 查找拖拽元素对应的容器索引
    let draggedElementIndex = draggedElement.id.substring(16)

    // 如果目标容器的索引和拖拽元素的索引相同，或者目标容器是 avg-pooling-process-pixels-container
    // 则将拖拽元素放置在目标容器中
    if (
      targetContainerIndex === draggedElementIndex ||
      targetContainer.className.includes("avg-pooling-process-pixels-container")
    ) {
      if (!targetContainer.contains(draggedElement)) {
        targetContainer.appendChild(draggedElement)
        setresult1(checkAns(0))
        setresult2(checkAns(1))
        setresult3(checkAns(2))
        setresult4(checkAns(3))
      }
    } else {
      // 查找对应索引的容器
      let originalContainer = document.getElementById(
        `avgPoolingCIndex${draggedElementIndex}`
      )
      if (originalContainer) {
        if (!originalContainer.contains(draggedElement)) {
          originalContainer.appendChild(draggedElement)
          setresult1(checkAns(0))
          setresult2(checkAns(1))
          setresult3(checkAns(2))
          setresult4(checkAns(3))
        }
      } else {
        console.log("Cannot find original container.")
      }
    }
  }

  function cancelDefault (e) {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const buildAvgPoolingImg = (ImgWidth, ImgHeight) => {
    let img = []
    let imgRow = []
    let index = 0
    for (let i = 0; i < ImgHeight; i++) {
      imgRow = []
      for (let j = 0; j < ImgWidth; j++) {
        index = i * ImgHeight + j
        const uniqueId = `avgPoolingPIndex${index}` // Use a consistent ID for the same element
        imgRow.push(
          <div
            className="avg-pooling-pixels-container"
            key={uniqueId}
            id={`avgPoolingCIndex${index}`}
            onDrop={dropped}
            onDragEnter={cancelDefault}
            onDragOver={cancelDefault}
            draggable="false"
          >
            <div
              id={uniqueId}
              className="avg-pooling-pixels"
              style={{ backgroundColor: transferNum2Color(colorArray[index]) }}
              draggable="true"
              onDragStart={dragStart}
            >
              {/* {index} */}
            </div>
          </div>
        )
      }
      img.push(
        <div
          key={`avgPooing-img-row-container-${i}`}
          className="avgPooing-img-row-container"
        >
          {imgRow}
        </div>
      )
    }
    return img
  }

  const buildAvgPoolingProcess = (poolingSize, PoolingRows) => {
    let processArea = []
    for (let i = 0; i < PoolingRows; i++) {
      let processRow = []
      for (let j = 0; j < poolingSize; j++) {
        const index = i * PoolingRows + j
        const uniqueId = `avgPoolingProcessIndex${index}` // Use a consistent ID for the same element
        processRow.push(
          <div className="d-flex  align-items-center">
            <div
              className="avg-pooling-process-pixels-container"
              key={uniqueId}
              id={uniqueId}
              onDrop={dropped}
              onDragEnter={cancelDefault}
              onDragOver={cancelDefault}
            />
            {j !== poolingSize - 1 ? <div>+</div> : null}
          </div>
        )
      }
      processArea.push(
        <div
          key={`avgPooing-process-row-container-${i}`}
          className="d-flex flex-column align-items-center justify-content-center p-2"
        >
          <div className={`avgPooing-process-row-container process-${i}`}>
            {processRow}
          </div>
          <img
            className="division-vis"
            src="/images/Ch4/4-2 Pooling/Average.png"
            alt=""
          />
        </div>
      )
    }
    return processArea
  }

  const checkAns = (index) => {
    let subelementIds = []
    let ans = "bad"
    let prefix = "avgPoolingPIndex"
    let parentContainerName = `process-${index}`
    let parentContainerElement = document.querySelector(
      `.${parentContainerName}`
    ) // Use querySelector with a class selector
    // console.log(parentContainerElement)
    if (parentContainerElement) {
      let subelements = parentContainerElement.querySelectorAll("*")

      subelements.forEach((element) => {
        let ssubElement = element.querySelector("div")
        if (ssubElement && ssubElement.id) {
          subelementIds.push(ssubElement.id)
        }
      })
    }
    if (index === 0) {
      // Check if all the subelementIds include "0", "1", "4", and "5"
      if (
        subelementIds.includes(`${prefix}0`) &&
        subelementIds.includes(`${prefix}1`) &&
        subelementIds.includes(`${prefix}4`) &&
        subelementIds.includes(`${prefix}5`)
      ) {
        ans = "good"
      }
    } else if (index === 1) {
      if (
        subelementIds.includes(`${prefix}2`) &&
        subelementIds.includes(`${prefix}3`) &&
        subelementIds.includes(`${prefix}6`) &&
        subelementIds.includes(`${prefix}7`)
      ) {
        ans = "good"
      }
    } else if (index === 2) {
      if (
        subelementIds.includes(`${prefix}8`) &&
        subelementIds.includes(`${prefix}9`) &&
        subelementIds.includes(`${prefix}12`) &&
        subelementIds.includes(`${prefix}13`)
      ) {
        ans = "good"
      }
    } else if (index === 3) {
      if (
        subelementIds.includes(`${prefix}10`) &&
        subelementIds.includes(`${prefix}11`) &&
        subelementIds.includes(`${prefix}14`) &&
        subelementIds.includes(`${prefix}15`)
      ) {
        ans = "good"
      }
    }

    return ans // Move the return statement inside the checkAns function
  }

  const buildPooledResult = () => {
    return (
      <div className="pooled-result-container">
        <div className="d-flex">
          <div
            className={
              result1 === "good"
                ? "avg-pooled-r-ans p-valid-ans"
                : "avg-pooled-r-ans p-invalid-ans"
            }
            style={{
              backgroundColor:
                result1 === "good" // Check Pooling Position Correct Or Not
                  ? transferNum2Color(
                    (colorArray[0] +
                      colorArray[1] +
                      colorArray[5] +
                      colorArray[6]) /
                    4
                  ) //AvgPooling
                  : "white", // Answer Not correct
            }}
          >
            {/* {result1} */}
          </div>
          <div
            className={
              result2 === "good"
                ? "avg-pooled-r-ans p-valid-ans"
                : "avg-pooled-r-ans p-invalid-ans"
            }
            style={{
              backgroundColor:
                result2 === "good"
                  ? transferNum2Color(
                    colorArray[2] +
                    colorArray[3] +
                    colorArray[6] +
                    colorArray[7]
                  )
                  : "white",
            }}
          >
            {/* {result2} */}
          </div>
        </div>
        <div className="d-flex">
          <div
            className={
              result3 === "good"
                ? "avg-pooled-r-ans p-valid-ans"
                : "avg-pooled-r-ans p-invalid-ans"
            }
            style={{
              backgroundColor:
                result3 === "good"
                  ? transferNum2Color(
                    colorArray[8] +
                    colorArray[9] +
                    colorArray[12] +
                    colorArray[13]
                  )
                  : "white",
            }}
          >
            {/* {result3} */}
          </div>
          <div
            className={
              result4 === "good"
                ? "avg-pooled-r-ans p-valid-ans"
                : "avg-pooled-r-ans p-invalid-ans"
            }
            style={{
              backgroundColor:
                result4 === "good"
                  ? transferNum2Color(
                    colorArray[10] +
                    colorArray[11] +
                    colorArray[14] +
                    colorArray[15]
                  )
                  : "white",
            }}
          >
            {/* {result4} */}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="pooling-practice-container ">
      <div>
        <div>
          <canvas
            className="s-color-map"
            ref={canvasColorMapRef}
            width={127.5 * 4}
            height={2}
          />
          <div className="d-flex justify-content-between">
            <div>0</div>
            <div>127.5</div>
            <div>255</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="avgPooling-img m-3 d-flex justify-content-center flex-column align-items-center">
            {buildAvgPoolingImg(4, 4)}
            <div className="w-100">被池化圖片</div>
          </div>
          <div className="avgPooling-process-area m-3 d-flex justify-content-center flex-wrap align-items-center">
            {buildAvgPoolingProcess(4, 4)}
          </div>
        </div>
      </div>
      <div className="pooled-result m-3 d-flex justify-content-center flex-column align-items-center">
        {buildPooledResult()}
        <div className="w-100">池化結果</div>
      </div>
    </div>
  )
}

function MaxPoolingPractice (props) {
  const colorArray = [
    [120, 200, 45, 90],
    [35, 170, 200, 15],
    [255, 60, 110, 175],
    [10, 230, 80, 190],
  ]
  const correctAnswer = [200, 200, 255, 230]

  const [MaxPooledResult1, setMaxPooledResult1] = useState("-")
  const [MaxPooledResult2, setMaxPooledResult2] = useState("-")
  const [MaxPooledResult3, setMaxPooledResult3] = useState("-")
  const [MaxPooledResult4, setMaxPooledResult4] = useState("-")

  const canvasColorMapRef = useRef()

  useEffect(() => {
    const canvasColorMap = canvasColorMapRef.current
    const ctxColorMap = canvasColorMap.getContext("2d")
    const colorMapScale = 2 // Scale factor for enlarging the color map
    drawColorMap(ctxColorMap, colorMapScale)
  }, [])

  useEffect(() => {
    props.setq2Ans([
      MaxPooledResult1,
      MaxPooledResult2,
      MaxPooledResult3,
      MaxPooledResult4,
    ])
  }, [MaxPooledResult1, MaxPooledResult2, MaxPooledResult3, MaxPooledResult4])

  const buildMaxPoolingImg = (poolingSize, ImgWidth, ImgHeight) => {
    const handleSelectionOnClick = (poolingAreaIndex, value) => {
      // console.log(poolingAreaIndex, value);
      // recordAPIAction(
      //   "q1-MaxPoolingPractice",
      //   `${poolingAreaIndex}-${value}`,
      //   localStorage.getItem("userUUID")
      // );
      recordAPI("apiLink", "4-2", "Pooling", "4-2_q1-MaxPoolingPractice", `${poolingAreaIndex}-${value}`, Date(), localStorage.getItem('userUUID'))
      if (poolingAreaIndex === 0) setMaxPooledResult1(value)
      else if (poolingAreaIndex === 1) setMaxPooledResult2(value)
      else if (poolingAreaIndex === 2) setMaxPooledResult3(value)
      else if (poolingAreaIndex === 3) setMaxPooledResult4(value)
    }

    const constructPoolingArea = (poolingSize, poolingAreaIndex) => {
      let poolingArea = []
      let poolingRow = []

      for (let i = 0; i < poolingSize; i++) {
        poolingRow = []
        for (let j = 0; j < poolingSize; j++) {
          let index = i * poolingSize + j

          poolingRow.push(
            <div
              className="max-pooling-pixels"
              style={{
                backgroundColor: transferNum2Color(
                  colorArray[poolingAreaIndex][index]
                ),
              }}
              onClick={() =>
                handleSelectionOnClick(
                  poolingAreaIndex,
                  colorArray[poolingAreaIndex][index]
                )
              }
            >
              { }
            </div>
          )
        }
        poolingArea.push(
          <div className="max-pooling-area-row-container">{poolingRow}</div>
        )
      }
      return <div className="max-pooling-area-container">{poolingArea}</div>
    }

    let pooledWidth = ImgWidth / poolingSize
    let pooledHeight = ImgHeight / poolingSize
    let img = []
    let imgRow = []
    let index = 0

    for (let i = 0; i < pooledHeight; i++) {
      imgRow = []
      for (let j = 0; j < pooledWidth; j++) {
        index = i * pooledHeight + j
        imgRow.push(constructPoolingArea(poolingSize, index))
      }
      img.push(<div className="max-pooling-outer-row-container">{imgRow}</div>)
    }

    return img
  }
  const buildPooledResult = () => {
    return (
      <div className="max-pooled-result-container">
        <div className="d-flex">
          <div
            className={
              MaxPooledResult1 === correctAnswer[0]
                ? "max-pooled-r-ans p-valid-ans"
                : "max-pooled-r-ans p-invalid-ans"
            }
            style={{ backgroundColor: transferNum2Color(MaxPooledResult1) }}
          >
            { }
          </div>
          <div
            className={
              MaxPooledResult2 === correctAnswer[1]
                ? "max-pooled-r-ans p-valid-ans"
                : "max-pooled-r-ans p-invalid-ans"
            }
            style={{ backgroundColor: transferNum2Color(MaxPooledResult2) }}
          >
            { }
          </div>
        </div>
        <div className="d-flex">
          <div
            className={
              MaxPooledResult3 === correctAnswer[2]
                ? "max-pooled-r-ans p-valid-ans"
                : "max-pooled-r-ans p-invalid-ans"
            }
            style={{ backgroundColor: transferNum2Color(MaxPooledResult3) }}
          >
            { }
          </div>
          <div
            className={
              MaxPooledResult4 === correctAnswer[3]
                ? "max-pooled-r-ans p-valid-ans"
                : "max-pooled-r-ans p-invalid-ans"
            }
            style={{ backgroundColor: transferNum2Color(MaxPooledResult4) }}
          >
            { }
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="pooling-practice-container">
      <div>
        <div>
          <canvas
            className="s-color-map"
            ref={canvasColorMapRef}
            width={127.5 * 4}
            height={2}
          />
          <div className="d-flex justify-content-between">
            <div>0</div>
            <div>127.5</div>
            <div>255</div>
          </div>
        </div>

        <div className="d-flex">
          <div className="maxPooling-img m-3">
            {buildMaxPoolingImg(2, 4, 4)}
            <div className="w-100">被池化圖片</div>
          </div>

          <div className="pooled-result m-3 d-flex justify-content-center flex-column align-items-center">
            {buildPooledResult()}
            <div className="w-100">池化結果</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CH4_2PoolingSection () {
  const [poolingMethod, setPoolingMethod] = useState("max")
  const [spoolingMethod, setSpoolingMethod] = useState("max")
  const [sPpoolingMethod, setsPpoolingMethod] = useState("max")
  const [inputFile, setInputFile] = useState()

  const [q1Ans, setQ1Ans] = useState("")
  const [q2Ans, setq2Ans] = useState([null, null, null, null])
  const [q3Ans, setq3Ans] = useState([null, null, null, null])

  const [hoverX, setHoverX] = useState(0)
  const [hoverY, setHoverY] = useState(0)

  return (
    <>
      <div className="title-container">
        <div className="title">池化層</div>
        <div className="subtitle">Pooling Layer</div>
      </div>
      <div className="bullet-point"> • 模擬說明</div>
      <div className="simulation-description">
        池化層在卷積神經網絡（CNN）中扮演著重要的角色。它能夠對輸入的特徵圖進行壓縮，減少參數量，同時提取出特徵圖中最重要的特徵。下方的模擬操作展示了經過池化後的結果，請操作並觀察後回答以下的問題。
      </div>

      <div className="p-simulation-container">
        <ControlPanel
          input={true}
          title={"池化圖片"}
          poolingMethod={poolingMethod}
          setPoolingMethod={setPoolingMethod}
          inputFile={inputFile}
          setInputFile={setInputFile}
        ></ControlPanel>
        <div className="pooling-section">
          <PoolingInputImageVisualization
            hoverX={hoverX}
            hoverY={hoverY}
            setHoverX={setHoverX}
            setHoverY={setHoverY}
            inputFile={inputFile}
            poolingMethod={poolingMethod}
          ></PoolingInputImageVisualization>
        </div>
      </div>
      <div className="question-section">
        <div className="sub-bullet-point"> • 練習問題</div>
        <div className="question-container">
          <div className="question-title">
            <div>1.在上方池化中，你認為有那張圖片是不適合進行Max Pooling?試解釋原因。(1.貓咪 2.小恐龍 3.狗狗 4.像素小狗)

            </div>
            <button
              // onClick={(event) =>
              //   SubmitAnswerAPI(
              //     "q1-MaxPoolingPractice",
              //     q2Ans,
              //     localStorage.getItem("userUUID")
              //   )
              // }
              onClick={() => SubmitAnswerAPI(
                "apiLink",
                "4-2",
                "Pooling",
                "1",
                q1Ans,
                Date(),
                localStorage.getItem('userUUID'))}
            >
              提交
            </button>
            <textarea
              className='input-ans-area'
              value={q1Ans}
              onChange={(event) => setQ1Ans(event.target.value)}
            />
          </div>

          <div className="question-title">
            1.
            下方的圖片需要進行最大池化操作，請根據最大池化的原理，點擊原圖片的像素格子，填寫池化後的結果。
            <br />
            (數值的大小是根據顏色，你可以觀看下方的顏色圖來了解不同顏色對應的數值大小。)
            <button
              // onClick={(event) =>
              //   SubmitAnswerAPI(
              //     "q1-MaxPoolingPractice",
              //     q2Ans,
              //     localStorage.getItem("userUUID")
              //   )
              // }
              onClick={() => SubmitAnswerAPI(
                "apiLink",
                "4-2",
                "Pooling",
                "2",
                q2Ans,
                Date(),
                localStorage.getItem('userUUID'))}
            >
              提交
            </button>
          </div>

          <MaxPoolingPractice
            q2Ans={q2Ans}
            setq2Ans={setq2Ans}
          ></MaxPoolingPractice>
        </div>
        <div className="question-container">
          <div className="question-title">
            2.
            下方的圖片需要進行平均池化操作，請根據平均池化的原理，拖拉原圖片的像素格子到正確的位置，以進行正確的池化操作。
            <br />
            (數值的大小是根據顏色，你可以觀看下方的顏色圖來了解不同顏色對應的數值大小。)
            <button
              // onClick={(event) =>
              //   SubmitAnswerAPI(
              //     "q2-Pooling-avg-dropped",
              //     q3Ans,
              //     localStorage.getItem("userUUID")
              //   )
              // }
              onClick={() => SubmitAnswerAPI(
                "apiLink",
                "4-2",
                "Pooling",
                "3",
                q3Ans,
                Date(),
                localStorage.getItem('userUUID'))}
            >
              提交
            </button>
          </div>
          <PoolingAvgPractice
            q3Ans={q3Ans}
            setq3Ans={setq3Ans}
          ></PoolingAvgPractice>
        </div>
      </div>

      {/* <div className='p-simulation-container'>
        <ControlPanel
          input={false}
          title={"模擬池化"}
          poolingMethod={spoolingMethod}
          setPoolingMethod={setSpoolingMethod}
        ></ControlPanel>
        <PoolingSimulationVisualization
          practice={false}
          poolingMethod={spoolingMethod}
        ></PoolingSimulationVisualization>

      </div> */}
      {/* <div className='p-simulation-container'>
        <ControlPanel
          input={false}
          title={"池化練習題"}
          poolingMethod={sPpoolingMethod}
          setPoolingMethod={setsPpoolingMethod}
        ></ControlPanel>
        <PoolingSimulationVisualization
          practice={true}
          poolingMethod={sPpoolingMethod}
        ></PoolingSimulationVisualization>
      </div>
      <div className='p-simulation-container'>
        <PoolingAvgPractice></PoolingAvgPractice>
      </div> */}
      {/* <div className='p-simulation-container'>
        
        <MaxPoolingPractice></MaxPoolingPractice>
      </div> */}
    </>
  )
}

export default CH4_2PoolingSection
